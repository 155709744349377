import React, { Component } from 'react'
import { PropTypes } from 'prop-types';
import { ADD_USER, EDIT_USER } from 'constants/routes-constants';
import moment from 'moment';
import ReactTooltip from 'react-tooltip'
import { getUsersCancelToken, deleteUserCancelToken } from 'api/user-api'
// Icons
import { Edit2, Trash2 } from 'react-feather';
// Styles
import styles from 'styles/scenes/admin.module.scss'
import t from 'styles/components/text.module.scss'
// constants
import { D } from 'constants/dictionary';
import { SUPER } from 'constants/admin-constants'
import { tableFormat } from 'constants/date-formats';
// Components
import { FAB } from '../../../components/Fab';
import { TableRow, TableHead, TableCell, TableButton } from '../../../components/Table';
import { Loader } from 'components/Loader';
import { AlertModal } from 'scenes/admin/components/AlertModal';
import ServerErrors from 'scenes/admin/components/ServerErrors';
// Redux Settings
import { connect } from 'react-redux';
import { getUsers, deleteUser } from 'actions/user-actions';


class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAlertModal: false,
      gettingUsers: true,
      selectedUser: '',
      error: ''
    }
  }
  componentDidMount() {
    this.props.getUsers()
  }

  componentDidUpdate(prevProps) {
    const loaded = prevProps.gettingUsers && !this.props.gettingUsers;
    if (!loaded) {
      return;
    }
    const {errorGettingUsers: error} = this.props;
    if (error) {
      if (error.response) {
        this.setState({ error: D.errors.serverError });
      } else {
        this.setState({ error: D.errors.noConnection });
      }
    }
  }

  componentWillUnmount(){
    getUsersCancelToken && getUsersCancelToken.cancel(D.errors.requestCanceled);
    deleteUserCancelToken && deleteUserCancelToken.cancel(D.errors.requestCanceled);
  }

  deleteUserAction = (user) => {
    this.setState({
      showAlertModal: true,
      selectedUser: user,
    });
  }

  deleteUserWithId = (id) => {
    this.props.deleteUser(id);
    this.resetState();
  }

  hideAlertModal = () => {
    this.resetState();
  }

  resetState = () => {
    this.setState({
      showAlertModal: false,
      selectedUser: {},
    });
  }
  render() {
    const { users, gettingUsers, history } = this.props
    return (
      <>
        {gettingUsers ? <Loader /> :
          <section className={`${styles.lgt_admin} __wrapper`}>
            {this.state.showAlertModal && (
              <AlertModal
                title={D.general.alert}
                onContinue={() => this.deleteUserWithId(this.state.selectedUser._id)}
                onCancel={this.hideAlertModal}
                close={this.hideAlertModal}
              >
                <>
                  <p>{D.users.deleteTitle}<strong>{this.state.selectedUser.name + " " +this.state.selectedUser.lastName }</strong>?</p>
                </>
              </AlertModal>
            )}
            <section className={`${styles.lgt_head} __row between_xs`}>
              <h1>{D.users.title}</h1>
              <FAB label={D.users.create} goTo={ADD_USER} />
            </section>
            {this.state.error && <ServerErrors errors={[this.state.error]} cols={12} />}
            <TableRow>
              <TableHead col="__col_xs_1 center_xs"><p className={t.__center}><strong>#</strong></p></TableHead>
              <TableHead col="__col_xs_2"><p>{D.users.save.email}</p></TableHead>
              <TableHead col="__col_xs_2"><p>{D.users.save.name}</p></TableHead>
              <TableHead col="__col_xs_1"><p>{D.users.save.role}</p></TableHead>
              <TableHead col="__col_xs_2"><p>{D.users.save.lastAccess}</p></TableHead>
              <TableHead col="__col_xs_2"><p>{D.users.save.org}</p></TableHead>
              <TableHead col="__col_xs_2 center_xs"><p>{D.general.actions}</p></TableHead>
            </TableRow>
            {
              users.length ? (users.map((user, i) => {
                const lastConnection = user.lastConnection ? moment(user.lastConnection).format(tableFormat) : D.users.noConnection
                const role = user.role === SUPER ? D.admin.admin : D.admin.contentAdmin
                return (
                  <TableRow key={user._id}>
                    <TableCell col="__col_xs_1 center_xs"><p data-tip={i + 1}>{i + 1}</p></TableCell>
                    <TableCell col="__col_xs_2"><p data-tip={user.email}>{user.email}</p></TableCell>
                    <TableCell col="__col_xs_2"><p data-tip={`${user.name} ${user.lastName}`}>{`${user.name} ${user.lastName}`}</p></TableCell>
                    <TableCell col="__col_xs_1"><p data-tip={role}>{role}</p></TableCell>
                    <TableCell col="__col_xs_2"><p data-tip={lastConnection}>{lastConnection}</p></TableCell>
                    <TableCell col="__col_xs_2"><p data-tip={user.organization}>{user.organization}</p></TableCell>
                    <TableCell col="__col_xs_2 center_xs">
                      <TableButton click={() => {
                        history.push(`${EDIT_USER}${user._id}`);
                      }}><Edit2 data-tip={D.general.edit} color="#007AFF" /></TableButton>
                      { this.props.currentUser._id !== user._id ?
                        <TableButton click={() => this.deleteUserAction(user)}><Trash2 data-tip={D.general.delete} color="#F31C1C" /></TableButton>
                        :
                        <TableButton disabled={true} ><Trash2 color="#F31C1C" /></TableButton>
                      }
                    </TableCell>
                    <ReactTooltip />
                  </TableRow>
                )
              }))
                : (<span>{D.users.noUsers}</span>)}
          </section>
        }
      </>)
  }
}

Users.propTypes = {
  getUsers: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  errorGettingUsers: PropTypes.any,
  users: PropTypes.any,
  gettingUsers: PropTypes.bool,
  history: PropTypes.object,
  deleteUser: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    gettingUsers: state.userReducer.gettingUsers,
    users: state.userReducer.users,
    currentUser: state.authReducer.user,
    errorGettingUsers: state.userReducer.errorGettingUsers
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    deleteUser: (_id) => dispatch(deleteUser(_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
