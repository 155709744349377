import * as React from 'react'
import styles from 'styles/scenes/login/loginAlerts.module.scss'
import PropTypes from 'prop-types'

const LoginError = (props) => {
  const message = props.label
  return (
    <section className={styles.__error}>
      <p>{message}</p>
    </section>
  )
}

LoginError.propTypes = {
  label: PropTypes.string.isRequired,
}
export default LoginError;
