import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

// helpers
import * as Validate from 'helpers/validations';

// components
import { Input } from 'scenes/admin/components/TextInput';
import Button from 'components/Button';

const Sources = ({ fields }) => {

  return (
    <>
      {fields.map((source, index) => {
        return (
          <section key={index} className="__row">
            <section className="__col_xs_10">
              <Field
                name={`sources[${index}]`}
                label={D.indicators.save.source}
                advice={D.indicators.save.sourceAdv}
                component={Input}
                validate={[Validate.maxLength255]}
              />
            </section>
            <section className="__col_xs_2">
              {index > 0 && (
                <Button
                  click={() => fields.remove(index)}
                  label={D.general.remove}
                  bgStyle="danger"
                />
              )}
            </section>
          </section>
        )
      })}
      <section className="__row">
        <section className="__col_xs_2">
          <Button
            click={() => fields.push('')}
            label={D.indicators.save.addSource}
          />
        </section>
      </section>
    </>
  );
}

Sources.propTypes = {
  fields: PropTypes.object,
}

export default Sources;
