import React from 'react';
import { CheckCircle, XCircle, Info, X, AlertCircle } from 'react-feather';
import PropTypes from 'prop-types';

// constants
import * as ALERT_TYPES from 'constants/alert-types';
import * as COLORS from 'constants/alert-colors';

// styles
import styles from 'styles/components/alert.module.scss';

const AlertTemplate = ({ style, options, message, close }) => {

  const getColor = (type) => {
    switch (type) {
      case ALERT_TYPES.INFO:
        return {
          backgroundColor: COLORS.INFO_BG_COLOR,
          color: COLORS.INFO_COLOR
        }

      case ALERT_TYPES.SUCCESS:
        return {
          backgroundColor: COLORS.SUCCESS_BG_COLOR,
          color: COLORS.SUCCESS_COLOR,
        }

      case ALERT_TYPES.DANGER:
        return {
          backgroundColor: COLORS.DANGER_BG_COLOR,
          color: COLORS.DANGER_COLOR
        }

      case ALERT_TYPES.WARNING:
        return {
          backgroundColor: COLORS.WARNING_BG_COLOR,
          color: COLORS.WARNING_COLOR
        }

      default:
        return {
          backgroundColor: '#000',
          color: '#FFF'
        };
    }
  }

  const color = getColor(options.type);

  return (
    <div className={styles.__alert} style={{ ...style, ...color }}>
      {options.type === ALERT_TYPES.INFO && <Info color={color.color} />}
      {options.type === ALERT_TYPES.SUCCESS && <CheckCircle color={color.color} />}
      {options.type === ALERT_TYPES.DANGER && <XCircle color={color.color} />}
      {options.type === ALERT_TYPES.WARNING && <AlertCircle color={color.color} />}
      <span style={{ flex: 1 }}>{message}</span>
      <button onClick={close} className={styles.__btn} style={{ color: color.color }}>
        <X />
      </button>
    </div>
  );
}

AlertTemplate.propTypes = {
  close: PropTypes.func,
  message: PropTypes.string,
  options: PropTypes.any,
  style: PropTypes.object,
}

export default AlertTemplate;
