import React from 'react';
import { X } from 'react-feather';
import PropTypes from 'prop-types';

// components
import { AlertModal } from 'scenes/admin/components/AlertModal';
import { DropzoneComp } from 'scenes/admin/components/Dropzone';
import { Loader } from 'components/Loader';
import Button from 'components/Button';
import ServerErrors from 'scenes/admin/components/ServerErrors';

// constants
import { D } from 'constants/dictionary';

// styles
import styles from 'styles/scenes/admin.module.scss';

const EXCEL_ICON = require('assets/excel-file.png');

const IndicatorData = ({
  cols,
  creatingTable,
  deleteRecords,
  displayModal,
  fileError,
  fileHandler,
  fileLoaded,
  files,
  hasRecords,
  hideChangesModal,
  hideModal,
  isUpdating,
  loading,
  loadingRecords,
  name,
  onAcceptChanges,
  onCancelChanges,
  onSave,
  onShowOriginal,
  recordsToAdd,
  rows,
  serverErrors,
  showChangesModal,
  showModal,
  updateRecordsAfterConfirm,
  validating,
}) => {

  const renderCancelChangesAlert = () => (
    <AlertModal
      title={D.general.alert}
      close={hideChangesModal}
      onCancel={hideChangesModal}
      onContinue={onAcceptChanges}
    >
      <p>{D.general.cancelQ}</p>
      <p>{D.indicators.data.changeBody}</p>
    </AlertModal>
  )

  const renderWarningAlert = () => (
    <AlertModal
      title={D.general.alert}
      onCancel={hideModal}
      close={hideModal}
      onContinue={() => {
        if (isUpdating) {
          updateRecordsAfterConfirm(recordsToAdd);
        } else {
          deleteRecords();
        }
      }}
    >
      <>
        <p>{D.general.continueQ}</p>
        {isUpdating ? (
          <p>{D.indicators.data.saveUpdate}</p>
        ) : (
          <p>{D.indicators.data.saveDelete}</p>
        )}
      </>
    </AlertModal>
  )

  const renderCurrentRecords = () => (
    <section className="__row center_xs">
      <section className="__col_xs_2">
        <section className={styles.__icon_wrapper}>
          <section>
            <button className={styles.__remove} onClick={() => displayModal(false)}>
              <X color="#FFF" />
            </button>
            <img src={EXCEL_ICON} alt="Excel Icon from the upload file" />
            <small>{D.indicators.data.current}</small>
            <Button click={onShowOriginal} label={D.indicators.data.reset} />
          </section>
        </section>
      </section>
    </section>
  )

  return (
    <section className={`${styles.lgt_admin} ${styles.no_bottom} __wrapper`}>
      {showChangesModal && renderCancelChangesAlert()}
      {showModal && renderWarningAlert()}
      {(loading || validating) && <Loader message={validating ? D.indicators.data.validating : ''} />}
      <section className={`${styles.lgt_head} __row between_xs`}>
        <h1 className="__col_xs_12">{D.indicators.data.title}</h1>
      </section>
      <section className={`${styles.lgt_head} __row between_xs`}>
        <h2 className="__col_xs_12">{name}</h2>
      </section>
      <DropzoneComp
        files={files}
        onChange={fileHandler}
      />
      {hasRecords && renderCurrentRecords()}
      {fileError && <ServerErrors cols={12} errors={[fileError]} />}
      {serverErrors && <ServerErrors cols={12} errors={serverErrors} />}
      <section className="__row end_xs">
        {!!rows.length && !!cols.length && (
          <>
          <section className="__col_xs_2">
            <Button
              disabled={validating || loading}
              click={onSave}
              label={D.general.save}
              bgStyle="success"
            />
          </section>
          </>
        )}
        {!fileLoaded && (creatingTable || loadingRecords) && (
          <section className="__col_xs_4 __message">
            <span>{loadingRecords ? D.indicators.data.loadingRecords : D.indicators.data.creatingTable}</span>
          </section>
        )}
        <section className="__col_xs_2">
          <Button
            disabled={validating || loading}
            click={onCancelChanges}
            label={fileLoaded ? D.general.cancel : D.general.skip}
            bgStyle="danger"
          />
        </section>
      </section>
    </section>
  );
}

IndicatorData.propTypes = {
  cols: PropTypes.array.isRequired,
  creatingTable: PropTypes.bool,
  deleteRecords: PropTypes.func.isRequired,
  displayModal: PropTypes.func.isRequired,
  fileError: PropTypes.string,
  fileHandler: PropTypes.func.isRequired,
  fileLoaded: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.string),
  hasRecords: PropTypes.bool,
  hideChangesModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  loading: PropTypes.bool,
  loadingRecords: PropTypes.bool,
  name: PropTypes.string,
  onAcceptChanges: PropTypes.func.isRequired,
  onCancelChanges: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onShowOriginal: PropTypes.func.isRequired,
  recordsToAdd: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.string),
  showChangesModal: PropTypes.bool.isRequired,
  showModal: PropTypes.bool,
  updateRecordsAfterConfirm: PropTypes.func.isRequired,
  validating: PropTypes.bool,
}

export default IndicatorData;
