import React from 'react'
import { PropTypes } from "prop-types"

// Styles
import styles from './styles.module.scss'


export const TableButton = (props) => {
  return (
    <button onClick={props.click} className={[`${styles.table__button} ${props.disabled && styles.disabled}`].join(' ')}>{props.children}</button>
  )
}

export const TableRow = (props) => {
  return (
    <section className={`${styles.table__row} __row start_xs`}>{props.children}</section>
  )
}

export const TableHead = (props) => {
  return (
    <section className={[`${styles.table__head} ${props.col}`].join(' ')}>{props.children}</section>
  )
}

export const TableCell = (props) => {
  return (
    <section className={[`${styles.table__cell} ${props.col}`].join(' ')}>{props.children}</section>
  )
}


TableButton.propTypes = {
  children: PropTypes.any,
  click: PropTypes.func,
  disabled: PropTypes.bool
}


TableCell.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  col: PropTypes.string
}

TableHead.propTypes = {
  children: PropTypes.any,
  size: PropTypes.string,
  col: PropTypes.string
}

TableRow.propTypes = {
  children: PropTypes.any
}
