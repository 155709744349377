import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

// Create context
export const ItemContext = createContext();

// Create provider
export const ItemProvider = ({ children }) => {

  // Declare values of this provider
  const [items, setItems] = useState([]);
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [loadingItem, setLoadingItem] = useState(false);
  const [errorItem, setErrorItem] = useState('');
  const [strFetchItem, setStrFetchItem] = useState('');

  const value = {
    items,
    setItems,
    item,
    setItem,
    loading,
    setLoading,
    error,
    setError,
    loadingItem,
    setLoadingItem,
    errorItem,
    setErrorItem,
    strFetchItem,
    setStrFetchItem,
  }

  return (
    <ItemContext.Provider value={value}>
      { children }
    </ItemContext.Provider>
  );
}

ItemProvider.propTypes = {
  children: PropTypes.any,
}
