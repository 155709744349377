import moment from 'moment';
import 'moment/locale/es';

// constants
import { D } from 'constants/dictionary';

/**
 * Function that takes an array with data of each state
 * and returns an object with data formatted to export as csv.
 *
 * @param {Array} states Array with data from api
 */
export const getCSVData = (states) => {
  const datesArray = [];
  const allHeaders = [D.info.year, D.info.month];
  let statesHeaders = [];

  // Find the min and max date that can be found among all states
  let minDate;
  let maxDate;
  for (let i = 0; i < states.length; i++) {
    const state = states[i];

    // Add the state name as header for the csv
    statesHeaders = [...statesHeaders, state.state.name];

    // Store the first dates
    if (i === 0) {
      minDate = moment(state.breakdown[0].date);
      maxDate = moment(state.breakdown[state.breakdown.length - 1].date);
      continue;
    }

    // Since the dates are in ascending order compare the first and last date of the
    // array to get the min and max dates
    const breakdownMinDate = moment(state.breakdown[0].date);
    const breakdownMaxDate = moment(state.breakdown[state.breakdown.length - 1].date);
    if (breakdownMaxDate.isAfter(maxDate)) {
      maxDate = breakdownMaxDate;
    }
    if (breakdownMinDate.isBefore(minDate)) {
      minDate = breakdownMinDate;
    }
  }

  // Create array with dates between min and max
  // These will be the rows of the csv
  while (minDate <= maxDate) {
    datesArray.push(moment(minDate).format('YYYY-MM'));
    minDate = moment(minDate).add(1, 'month');
  }

  const total = [];

  // Fill the rows and columns with the amount for each state
  // iterating through the datesArray that was previously filled
  const values = datesArray.reduce((res, date) => {
    const m = moment(date);

    let timeValues = [];

    for (let i = 0; i < states.length; i++) {
      const state = states[i];

      if (total.length < states.length) {
        total.push(state.totalAmount);
      }

      // Flag to know if this state has a value on the current date from datesArray
      let valueExists = false;
      for (let j = 0; j < state.breakdown.length; j++) {
        const currentBreakdown = state.breakdown[j];
        const breakdownDate = moment(currentBreakdown.date);

        // If the current date from breakdown matches the current date from datesArray
        // save the amount value.
        // If the current date from breakdown is after the date from datesArray, it means
        // that this state has no value for that date, so skip.
        if (m.isSame(breakdownDate)) {
          timeValues = [...timeValues, currentBreakdown.amount];
          valueExists = true;
          break;
        } else if (breakdownDate.isAfter(m)) {
          break;
        }
      }
      if (!valueExists) {
        timeValues = [...timeValues, null];
      }
    }

    // Add the year and month for each row
    const year = m.year();
    const month = m.format('MMMM');
    return [
      ...res,
      [year, month, ...timeValues]
    ]
  }, []);

  return {
    data: [...values, [null, D.info.totalXls, ...total]],
    headers: [...allHeaders, ...statesHeaders]
  }
}
