import moment from 'moment';

// constants
import { D } from 'constants/dictionary';
import { recordFormat } from 'constants/date-formats';
import { stateCodes } from 'constants/state-codes';

// helpers
import { getMonthNumber } from './get-month-number';

// headers
const ID_NAME = 'ID';
const ENT_NAME = 'ENT';
const CODE_NAME = 'CVE';
const YEAR_NAME = 'YR';
const MONTH_NAME = 'MO';
const DATA = 'indicador';

// value object name
const VALUE_NAME = 'VAL';

// Ordered headers
export const HEADERS = [ID_NAME, ENT_NAME, CODE_NAME, YEAR_NAME, MONTH_NAME, DATA];

// Max value allowed
const MAX_ALLOWED_VALUE = 100000000;
// Max year allowed
const MAX_ALLOWED_YEAR = 3000;

// Min year allowed
const MIN_ALLOWED_YEAR = 2000;

// This variable will be used to check for duplicate entries
let entries;

export const transformExcelData = (data, cols) => {

  return new Promise((resolve) => {

    if (!data.length) {
      return resolve({ errors: [D.indicators.data.noData] });
    }

    // Initialize object to store the keys of the objects created
    entries = {};

    let errors = [];
    const result = [];
    const headers = data[0].map((c) => c.value && typeof c.value === 'string' && c.value.toUpperCase());

    // If month is present or not
    const hasMonth = headers.includes(MONTH_NAME);
    const lastIdx = data[0].length - 1;

    // Get the position where data will be fetched for each row
    const indexes = getIndexes(headers, lastIdx, hasMonth);

    // If there is something wrong with the indexes, don't continue
    if (indexes.errors.length) {
      return resolve({
        errors: indexes.errors,
        indexes: indexes.indexes
      });
    }

    iterateRowNonBlocking(data, cols, indexes, hasMonth, (obj, done) => {
      // If finished checking all rows, resolve the promise
      if (done) {
        return resolve({ errors, result });
      }

      if (obj && obj.errors.length) {
        errors = [...errors, ...obj.errors];
      } else if (obj && !obj.ignore) {
        const value = obj.responseObject;
        // Generate key with the place and date
        const uniqueKey = `${value.place}-${value.date}`;

        // Duplicate records are not allowed, add error
        if (entries[uniqueKey]) {
          errors.push(`${D.indicators.data.recordExists} ${obj.rowNumber}`);
        } else {
          entries[uniqueKey] = uniqueKey;
          result.push(value);
        }
      }
    });
  });
}

/**
 *
 * @param {Array} data Array with all rows
 * @param {Array} cols Array with headers as letters (A B C D E...)
 * @param {object} indexes Get the indexes to get data from row
 * @param {boolean} hasMonth Value used to know if the month should be validated
 * @param {Function} callback The callback to be executed after each iteration
 */
const iterateRowNonBlocking = (data, cols, indexes, hasMonth, callback) => {
  const chunk = 100;
  let i = 0;

  const loop = () => {
    let count = chunk;
    while (count-- && i < data.length) {
      if (i === 0) {
        callback(undefined, false);
      } else {
        // Try to generate the object for this row
        const obj = generateObject(data[i], cols, indexes.indexes, i + 1, hasMonth);
        obj.rowNumber = i;
        callback(obj, false);
      }
      i++;
    }

    // if there is still data to process
    if (i < data.length) {
      setTimeout(loop, 0);
    }
    else {
      callback(undefined, true);
    }
  }

  loop();
}

/**
 * Function to generate the object that will be used to
 * generate a new indicator record.
 *
 * @param {Array} row Array with data (state code, year, month, amount)
 * @param {Array} cols Array with headers as letters (A B C D E...)
 * @param {object} indexes Get the indexes to get data from row
 * @param {number} rowNumber Value used to indicate there is an error in this row
 * @param {boolean} hasMonth Value used to know if the month should be validated
 *
 * @returns {object} Object with array of errors, an object with the values
 *   that will be used to create a new record and a flag to know if the
 *   record should be added or not.
 */
const generateObject = (row, cols, indexes, rowNumber, hasMonth) => {
  let errors = [];
  const responseObject = {};
  let ignore = false;

  // Get the indexes
  const {
    [CODE_NAME]: codeIdx,
    [YEAR_NAME]: yearIdx,
    [MONTH_NAME]: monthIdx,
    [VALUE_NAME]: valueIdx,
  } = indexes;

  // This will be the amount of the record
  const value = row[valueIdx] && row[valueIdx].value;
  responseObject.amount = value;

  // check for errors
  const validatedValue = validateValue(value, rowNumber, cols, valueIdx);
  ignore = validatedValue.ignore;
  if (validatedValue.error) {
    errors.push(validatedValue.error);
  }

  const place = row[codeIdx] && row[codeIdx].value;
  // check for errors and get place in uppercase
  const validatedPlace = getPlace(place, rowNumber, cols, codeIdx);
  responseObject.place = validatedPlace.place;
  if (validatedPlace.error) {
    errors.push(validatedPlace.error);
  }

  const year = row[yearIdx] && row[yearIdx].value;

  let month = undefined;
  if (hasMonth) {
    month = row[monthIdx] && row[monthIdx].value;
  }

  // Validate year and month (if present) and get correct date (if there are no errors)
  const validatedDate = getDate(year, rowNumber, cols, yearIdx, month, monthIdx, hasMonth);
  responseObject.date = validatedDate.date;
  if (validatedDate.errors.length) {
    errors = [
      ...errors,
      ...validatedDate.errors,
    ];
  }

  return { errors, responseObject, ignore };
}

/**
 * Function to validate and generate the correct date
 * for the records.
 *
 * @param {any} year Value to validate
 * @param {number} rowNumber Value used to indicate there is an error in this row
 * @param {Array} cols Array with headers as letters (A B C D E...)
 * @param {number} yearIdx Index of year
 * @param {any} month Value to validate
 * @param {number} monthIdx Index of month
 * @param {boolean} hasMonth Flag to know if month should be validated
 *
 * @returns {object} Object with array of errors and date for the
 *   new record.
 */
const getDate = (year, rowNumber, cols, yearIdx, month, monthIdx, hasMonth) => {
  const errors = [];
  let date = undefined;

  const errorMessage = validate([isNumber, isInteger, isValidYear], year);

  if (errorMessage) {
    errors.push(getErrorMessage(rowNumber, cols[yearIdx].name, errorMessage));
  }

  let monthNumber = 0;
  if (hasMonth) {
    if (typeof month !== 'string') {
      errors.push(getErrorMessage(rowNumber, cols[monthIdx].name, D.indicators.data.invalidMonthName));
    } else {
      monthNumber = getMonthNumber(month.toLowerCase());

      if (monthNumber === undefined) {
        errors.push(getErrorMessage(rowNumber, cols[monthIdx].name, D.indicators.data.invalidMonthName));
      }
    }
  }

  if (errors.length) {
    return { errors, date };
  }

  const momentDate = moment().utcOffset(0);
  // Ignore time and set 1st day of the month
  momentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).set('date', 1);
  date = momentDate.year(year).month(monthNumber).format(recordFormat);

  return { errors, date };
}

/**
 * If the value is not a number, ignore this row if
 * it's an empty string or undefined but don't mark as
 * an error. Else add error.
 *
 * @param {any} value Value to validate
 * @param {number} rowNumber Value used to indicate there is an error in this row
 * @param {Array} cols Array with headers as letters (A B C D E...)
 * @param {number} valueIdx Index of values
 *
 * @returns {object} Object with error or flag to ignore
 *   this record.
 */
const validateValue = (value, rowNumber, cols, valueIdx) => {
  let error = '';
  let ignore = false;

  // If cell is empty, ignore it
  if (value === undefined || (typeof value === 'string' && value.trim() === '')) {
    ignore = true;
  } else {
    const errorMessage = validate([isNumber, isValidValue], value);

    if (errorMessage) {
      error = getErrorMessage(rowNumber, cols[valueIdx].name, errorMessage);
    }
  }

  return { error, ignore };
}

/**
 * Function to validate that the place is a correct code.
 *
 * @param {any} place Value to validate
 * @param {number} rowNumber Value used to indicate there is an error in this row
 * @param {Array} cols Array with headers as letters (A B C D E...)
 * @param {number} codeIdx Index of places codes
 *
 * @returns {object} Object with error or the place in uppercase
 */
const getPlace = (place, rowNumber, cols, codeIdx) => {
  let error = '';

  // If not a string, that's an error
  if (typeof place !== 'string') {
    error = getErrorMessage(rowNumber, cols[codeIdx].name, D.indicators.data.invalidPlaceCode);
    return { error, place: undefined };
  }

  const newPlace = place.toUpperCase();

  // Add error if it's not a valid code
  if (!stateCodes[newPlace]) {
    error = getErrorMessage(rowNumber, cols[codeIdx].name, D.indicators.data.invalidPlaceCode);
  }

  return { error, place: newPlace };
}

/**
 * Function to return the index of the columns required
 * to generate the objects with data.
 *
 * @param {Array} headers Array of strings.
 * @param {number} lastIdx The last header, should be the one with data.
 * @param {boolean} hasMonth The headers include the month column.
 *
 * @returns {object} Object with array of errors and object with
 *   the indexes.
 */
const getIndexes = (headers, lastIdx, hasMonth) => {

  const validateIdx = (idx, required, name) => {
    let errorFound = false;
    if (idx < 0 && required) {
      errors.push(`${name} ${D.indicators.data.required}`);
      errorFound = true;
    }

    if (idx >= lastIdx) {
      errors.push(`${D.indicators.data.error} ${name}: ${D.indicators.data.shouldBeLast}`);
      errorFound = true;
    }

    if (indexesValues.includes(idx) || idx !== headers.indexOf(name)) {
      errors.push(`${name} ${D.indicators.data.duplicated}`)
      errorFound = true;
    }

    return errorFound;
  }

  const errors = [];
  const indexes = {};
  const indexesValues = [];

  const codeIdx = headers.lastIndexOf(CODE_NAME);
  if (!validateIdx(codeIdx, true, CODE_NAME)) {
    indexes[CODE_NAME] = codeIdx;
  }

  const yearIdx = headers.lastIndexOf(YEAR_NAME);
  if (!validateIdx(yearIdx, true, YEAR_NAME)) {
    indexes[YEAR_NAME] = yearIdx;
  }

  if (hasMonth) {
    const monthIdx = headers.lastIndexOf(MONTH_NAME);
    if (!validateIdx(monthIdx, false, MONTH_NAME)) {
      indexes[MONTH_NAME] = monthIdx;
    }
  }

  indexes[VALUE_NAME] = lastIdx;

  return { errors, indexes };
}

/**
 * Function tu return an error message.
 *
 * @param {number} row
 * @param {string} col
 */
const getErrorMessage = (row, col, expected) => {
  return `${D.indicators.data.invalid} ${col}${row}: ${expected}`;
}

/** Validations for row values */

/**
 * Function that returns the first error found.
 *
 * @param {Array} validators Array of functions used to validate
 * @param {any} value Value the validate
 *
 * @returns The error message.
 */
const validate = (validators, value) => {
  for (let i = 0; i < validators.length; i++) {
    const error = validators[i](value);
    if (error) {
      return error;
    }
  }

  return undefined;
}

/**
 * Check that the value is a number or can
 * be parsed to one.
 *
 * @param {any} n Value to validate
 */
const isNumber = (n) => {
  let error = '';

  if (typeof n !== 'number') {
    if (typeof n === 'string') {
      if (isNaN(parseFloat(n))) {
        error = D.indicators.data.mustBeNumeric;
      }
    } else {
      error = D.indicators.data.mustBeNumeric;
    }
  }

  return error;
}

/**
 * If not an integer, return error.
 *
 * @param {any} n Value to validate.
 */
const isInteger = (n) => Number.isInteger(parseFloat(n)) ? '' : D.indicators.data.mustBeInteger;

/**
 * If the value is bigger that the max allowed,
 * return error.
 *
 * @param {number} n Number to validate
 */
const isValidValue = (n) => Math.abs(n) <= MAX_ALLOWED_VALUE ? '' : D.indicators.data.valueTooBig;

/**
 * If the value is not between the allowed years,
 * return error.
 *
 * @param {number} d Number to validate
 */
const isValidYear = (d) => d >= MIN_ALLOWED_YEAR && d <= MAX_ALLOWED_YEAR ? '' : D.indicators.data.invalidYear;
