import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const persistConfig = {
  key: 'lgt-app',
  storage,
  blacklist: [
    'itemReducer',
    'adminReducer',
    'form',
    'indicatorReducer',
    'userReducer',
  ],
  persist: [],
}

let middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middleware = [...middleware, logger];
}

const pReducer = persistReducer(persistConfig, reducers);
export const store = createStore(
  pReducer,
  applyMiddleware(...middleware)
);
export const persist = persistStore(store);
