import React from 'react';
import { Move, Edit2, Trash2 } from 'react-feather';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip'

// constants
import { D } from 'constants/dictionary';
import { EDIT_ITEM } from 'constants/routes-constants';
import { tableFormat } from 'constants/date-formats';

// components
import { TableRow, TableCell, TableButton } from 'scenes/admin/components/Table';

// Styles
import styles from 'scenes/admin/components/styles.module.scss';


const SortableRow = ({
  deleteItem,
  history,
  itemId,
  itemsByHash,
}) => {

  const item = itemsByHash[itemId];
  const updatedAt = moment.utc(item.updatedAt).local().format(tableFormat);
  const updatedBy = (item.user && item.user.name) ? `${item.user.name} ${item.user.lastName}` : '';
  return (
    <li className={styles.sortable__row}>
      <TableRow key={item._id}>
        <TableCell col="__col_xs_1 center_xs"><p data-tip={item.number}>{item.number}</p></TableCell>
        <TableCell col="__col_xs_2"><p data-tip={item.shortName}>{item.shortName}</p></TableCell>
        <TableCell col="__col_xs_2">
          {item.hasIndicators ? (
            <p data-tip={D.general.yes}>{D.general.yes}</p>
          ) : (
            <p data-tip={D.general.no}>{D.general.no}</p>
          )}
        </TableCell>
        <TableCell col="__col_xs_2"><p data-tip={updatedAt}>{updatedAt}</p></TableCell>
        <TableCell col="__col_xs_3">
          <p data-tip={updatedBy}>
            {updatedBy}
          </p>
        </TableCell>
        <TableCell col="center_xs">
          <TableButton><Move data-tip={D.general.move} color="#007AFF" /></TableButton>
          <TableButton click={() => {
            history.push(`${EDIT_ITEM}${item._id}`);
          }}><Edit2 data-tip={D.general.edit} color="#007AFF" /></TableButton>
          <TableButton click={() => deleteItem(item._id)}><Trash2 data-tip={D.general.delete} color="#F31C1C" /></TableButton>
        </TableCell>
        <ReactTooltip />
      </TableRow>
    </li>
  )
}

SortableRow.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  itemsByHash: PropTypes.object.isRequired,
  itemId: PropTypes.string.isRequired,
}

export default SortableRow;
