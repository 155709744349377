export const ADMIN_PANEL = '/admin-panel';
export const LGT_ADMIN = '/lgt-admin';
export const STATISTICS = '/estadisticas';
export const MAP = `${STATISTICS}/mapa`;
export const SHARE = '/s';
export const RECOVER_PASSWORD = '/recover-password';
export const REACT_WP_ADMIN = `${ADMIN_PANEL}/wp-admin`;
export const REACT_APP_WP_ADMIN_URL = process.env.REACT_APP_WP_ADMIN_URL;
export const REACT_APP_WP_PAGE = process.env.REACT_APP_WP_PAGE;
export const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;
export const REACT_APP_ANALYTICS_ADMIN_URL = process.env.REACT_APP_ANALYTICS_ADMIN_URL;

// Indicator Routes
export const INDICATORS = `${ADMIN_PANEL}/indicators`;
export const ADD_INDICATOR = `${INDICATORS}/add`;
export const EDIT_INDICATOR = `${INDICATORS}/edit/`;

// Item Routes
export const ITEMS = `${ADMIN_PANEL}/items`;
export const ADD_ITEM = `${ITEMS}/add`;
export const EDIT_ITEM = `${ITEMS}/edit/`;


//User Routes
export const USERS = `${ADMIN_PANEL}/users`;
export const ADD_USER = `${USERS}/add/`;
export const EDIT_USER = `${USERS}/edit/`;
