import React, { useEffect, useContext } from 'react';
import * as queryString from 'query-string';
import PropTypes from 'prop-types';
import axios from 'axios';

// context
import { IndicatorContext } from 'providers/IndicatorProvider';
import { ItemContext } from 'providers/ItemProvider';
import { SearchContext } from 'providers/SearchProvider';

// constants
import { API_URL, NO_INDICATOR_SELECTED, NO_INDICATOR_SELECTED_LABEL } from 'constants/general-constants';
import { D } from 'constants/dictionary';
import * as QUERY from 'constants/search-query-parameters';

// components
import Header from './Header/Header';
import DataContainer from './Data/DataContainer';

const IndicatorContainer = ({
  history,
}) => {

  const { item } = useContext(ItemContext);
  const {
    setError,
    setIndicator,
    setIndicators,
    setLoading,
    strFetchIndicators,
  } = useContext(IndicatorContext);
  const {
    firstURL,
    search,
    setSearch,
  } = useContext(SearchContext);

  // Fetch indicators
  useEffect(() => {
    if (!item._id) {
      return;
    }

    // When a new item with no indicators is selected
    // clear the old values
    if (!item.hasIndicators) {
      history.replace({ search });
      setIndicator({});
      setIndicators([]);
      return;
    }

    let didCancel = false;
    const source = axios.CancelToken.source();
    setError('');
    setLoading(true);
    // clear the old indicators in case there is an error
    setIndicator({});
    setIndicators([]);
    axios.get(`${API_URL}items/${item._id}/indicators`, { cancelToken: source.token })
      .then((res) => {
        if (!didCancel) {
          const data = res.data;
          if (data.success && data.data) {

            setIndicators(data.data);
            if (data.data.length) {
              const { [QUERY.INDICATOR_ID]: indicatorId } = queryString.parse(firstURL)
              if (indicatorId) { // If item is set in url
                const _indicator = data.data.find((i) => i._id === indicatorId);
                if (_indicator) { // If the item was found
                  setIndicator(_indicator);
                } else {
                  setIndicators([
                    { _id: NO_INDICATOR_SELECTED, shortName: NO_INDICATOR_SELECTED_LABEL },
                    ...data.data
                  ]);
                }
              } else { // If no itemId in url, set the first one
                const newSearch = queryString.stringify({
                  [QUERY.ITEM_ID]: item._id,
                  [QUERY.INDICATOR_ID]: data.data[0]._id
                });
                history.replace({ search: newSearch });
                setSearch(newSearch);
                setIndicator(data.data[0]);
              }
            }
          } else {
            history.replace({ search });
            setIndicators([]);
            setIndicator({});
            setError(D.errors.noData);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!didCancel) {
          if (!err.response) {
            setError(D.errors.noConnection);
          } else {
            setError(D.errors.serverError);
          }
          setLoading(false);
        }
      });

    return(() => {
      didCancel = true;
      source.cancel(D.errors.requestCanceled);
    });

    /**
     * Disable deps for firstURL and setSearch, since this effect
     * should run only when the item changes.
     *
     * Remove the next line when adding functionality to this effect
     * and add it later.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item._id, strFetchIndicators]);
  return (
    <>
      <Header
        history={history}
      />
      <DataContainer
        history={history}
      />
    </>
  )
}

IndicatorContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default IndicatorContainer;
