import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import * as Validate from '../../helpers/validations'
import PropTypes from 'prop-types'
import { changePasswordCancelToken } from 'api/user-api'

// constants
import { D } from 'constants/dictionary';
import { ADMIN_PANEL, LGT_ADMIN } from 'constants/routes-constants'

//Components
import Textfield from '../login/components/Textfield'
import Button from 'components/Button'
import LoginError from '../login/components/LoginError'
import MailSendSuccess from '../login/components/MailSendSuccess'
import { Loader } from 'components/Loader'


//Styles
import login_styles from 'styles/scenes/login/login.module.scss'
import Logo from 'assets/logo.png'

//Redux
import { changePassword, clearState } from 'actions/user-actions';

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordVisible: false,
    }
  }

  componentWillUnmount() {
    changePasswordCancelToken && changePasswordCancelToken.cancel(D.errors.requestCanceled);
  }

  componentDidMount() {
    this.props.clearState()
  }

  componentDidUpdate() {
    if (this.props.location.state) {
      if (this.props.location.state.defaultPass && this.props.passwordSuccess && this.props.loginSuccess) {
        this.props.history.push(ADMIN_PANEL)
      }
    }
  }


  onSubmit = (value) => {
    let defaultPass = false;
    let password = {
      ...value
    }

    if (this.props.location.state) {
      defaultPass = this.props.location.state.defaultPass;
    }

    if (defaultPass) {
      let { mail } = this.props.location.state
      let data = { email: mail, password: password.password }
      this.props.changePassword(data, this.props.token, defaultPass)
    }
    if (!defaultPass) {
      this.props.changePassword(password, this.props.match.params.token)
    }

  }

  viewPassword = () =>
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })

  render() {
    const { isPasswordVisible } = this.state
    const passwordType = isPasswordVisible ? 'text' : 'password'
    const { handleSubmit } = this.props;

    return (
      <main className={login_styles.__wrapper}>
        {this.props.loading && <Loader />}
        <section className="__wrapper">
          <section className="__row center_xs middle_xs">
            <section className="__col_xs_12 center-xs">
              <figure className={login_styles.__logo}>
                <img src={Logo} alt='logo' />
              </figure>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_12 center-xs">
              <h1>
                {D.login.change}
              </h1>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_12 center-xs">
              <p>
                {D.login.newPassword}
              </p>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_8 center-xs ">
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  name="password"
                  props={{
                    withIcon: true,
                    icon: 'lock',
                    viewPassword: true,
                    input_type: passwordType,
                    input_placeholder: D.login.password,
                    viewPassword_press: this.viewPassword,
                    viewPassword_icon: this.state.isPasswordVisible ? 'eyeoff' : 'eye'
                  }}
                  component={Textfield}
                  validate={[Validate.required]} />
                <Button login type='submit' label={D.login.saveNewPassword} />
              </form>
              {this.props.passwordError && (this.props.errorStatus === 404 || this.props.errorStatus === 400) ?
                <LoginError label={D.login.saveError} /> : (this.props.passwordSuccess && !this.props.location.state) ?
                  <MailSendSuccess label={D.login.saveSuccess} /> : ''
              }
              <section className={login_styles.__middle}>
                <Link to={LGT_ADMIN}>{D.login.backLogin}</Link>
              </section>
            </section>
          </section>
        </section>
      </main>
    )
  }
}

const form = reduxForm({
  form: 'changePassword'
});

ChangePassword.propTypes = {
  errorStatus: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object,
  changePassword: PropTypes.func.isRequired,
  passwordSuccess: PropTypes.bool.isRequired,
  location: PropTypes.object,
  loginSuccess: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  passwordError: PropTypes.bool,
  userMail: PropTypes.string,
  match: PropTypes.object,
  clearState: PropTypes.func,
  token: PropTypes.string,
  defaultPass: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    passwordError: state.authReducer.passwordError,
    token: state.authReducer.token,
    errorStatus: state.authReducer.errorStatus,
    errorMessage: state.authReducer.errorMessage,
    passwordSuccess: state.authReducer.passwordSuccess,
    loading: state.authReducer.loading,
    loginSuccess: state.authReducer.loginSuccess,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (data, token, defaultPass) => dispatch(changePassword(data, token, defaultPass)),
    clearState: () => dispatch(clearState())
  }
}

export default compose(
  form,
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(ChangePassword)
