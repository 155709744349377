import React, { useEffect, useState, useContext, useRef } from 'react';
import { Info, AlignLeft } from 'react-feather';
// eslint-disable-next-line no-unused-vars
import ChartDataLabels from 'chartjs-plugin-datalabels'; // import package to display labels next to each bar
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from 'body-scroll-lock';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

// context
import { DatesContext } from 'providers/DatesProvider';
import { IndicatorContext } from 'providers/IndicatorProvider';

// constants
import { D } from 'constants/dictionary';
import { stateCodes } from 'constants/state-codes';

// helpers
import { getFormattedValue } from 'helpers/get-formatted-value';
import { getPeriod } from 'helpers/get-period';

// styles
import s from 'styles/components/chart.module.scss';
import HorizontalBarChart from './HorizontalBarChart';
import StateLegends from 'components/StateLegends';

const BAR_WIDTH = 25;
const TITLE_HEIGHT = 20;
const TOP_STATES = 10;

const ChartsContainer = ({
  legendColors,
  legendValues,
  name,
  statesByHash,
  statesById,
  totalState,
  totalESE,
  asPercentage,
  asCoin,
  totalCountry,
  missingStates
}) => {
  const [hBarData, setHBarData] = useState({});
  const [chartHeight, setChartHeight] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [stepSize, setStepSize] = useState(6);
  const [period, setPeriod] = useState('');
  const [showModal, setShowModal] = useState(false);

  const { indicator } = useContext(IndicatorContext);

  const { monthOrQuarter, minMaxYears, year } = useContext(DatesContext);

  const bodyRef = useRef();

  const showAbrev = () => {
    setShowModal(true);
    disableBodyScroll(bodyRef);
  };

  const hideAbrev = () => {
    setShowModal(false);
    enableBodyScroll(bodyRef);
  };

  useEffect(() => {
    setPeriod(getPeriod(year, monthOrQuarter, minMaxYears));
  }, [year, minMaxYears, monthOrQuarter]);

  useEffect(() => {
    bodyRef.current = document.querySelector('body');
  }, []);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  useEffect(() => {
    const statesByHashLength = Object.keys(statesByHash).length;

    const clear = () => {
      setHBarData({});
      setMin(0);
      setMax(0);
    };

    // There is no data or colors and values haven't been generated
    if (!statesByHashLength || !legendColors.length || !legendValues.length) {
      clear();
      return;
    }

    // Check if country data is present
    const hasCountry = !!statesByHash[stateCodes.FED.code];

    // If hash object only has data about the country, don't create chart
    if (hasCountry && statesByHashLength < 2) {
      clear();
      return;
    }

    const numberOfStates = hasCountry
      ? statesByHashLength - 1
      : statesByHashLength;

    const labels = [];
    const polarLabels = [];
    const polarDataset = [];
    const polarColors = [];

    // Don't use index from reduce, since it is possible to skip the country
    // and that could lead to fill dataArray in the wrong way.
    let index = -1;
    let min = 0,
      max = 0;
    let top = 0;
    const datasets = statesById.reduce((ds, id) => {
      const stateData = statesByHash[id];
      const {
        totalAmount,
        state: { type, code }
      } = stateData;
      if (type !== 'state') {
        return ds;
      }
      ++index;

      if (totalAmount < min) {
        min = totalAmount;
      }

      if (totalAmount > max) {
        max = totalAmount;
      }

      // Split every two words
      labels.push(code);
      const dataArray = new Array(numberOfStates);
      dataArray.fill(undefined, 0);
      // Round the number to two decimals if necessary
      const formattedAmount = Math.round(totalAmount * 100) / 100;
      dataArray[index] = formattedAmount;

      // Get the color of this state
      let color = '0, 0, 0';
      if (legendValues.length && legendColors.length) {
        for (let i = 0; i < legendValues.length - 1; i++) {
          if (
            legendValues[i] <= totalAmount &&
            legendValues[i + 1] >= totalAmount
          ) {
            color = legendColors[i];
            break;
          }
        }
      }

      const bgColor = `rgba(${color}, 0.8)`;

      if (top < TOP_STATES && formattedAmount > 0) {
        polarLabels.push(code);
        polarDataset.push(formattedAmount);
        polarColors.push(bgColor);
      }
      top++;

      return [
        ...ds,
        {
          label: code,
          backgroundColor: bgColor,
          borderColor: `rgba(${color}, 1)`,
          borderWidth: 1,
          hoverBackgroundColor: `rgba(${color}, 1)`,
          hoverBorderColor: `rgba(${color}, 1)`,
          data: dataArray
        }
      ];
    }, []);

    const dataObject = { labels, datasets };

    const barWidth = numberOfStates < 5 ? BAR_WIDTH * 2 : BAR_WIDTH;
    const height = numberOfStates * barWidth * 1.5 + TITLE_HEIGHT;
    let stepSize = Math.floor((max - min) / 10);

    if (!stepSize) {
      stepSize = 1;
    }

    setMax(max === min ? min + 1 : max);
    setMin(min);
    setStepSize(stepSize);
    setChartHeight(height);
    setHBarData(dataObject);
  }, [statesById, statesByHash, legendColors, legendValues]);

  if (!Object.keys(hBarData).length) {
    return null;
  }

  return (
    <section className={[s.__g_chart, '__wrapper'].join(' ')}>
      {showModal && (
        <StateLegends title={D.info.abreviations} onClose={hideAbrev} />
      )}
      <section className={[s.__charts, '__row'].join(' ')}>
        <HorizontalBarChart
          period={period}
          asPercentage={asPercentage}
          asCoin={asCoin}
          totalState={totalState}
          totalESE={totalESE}
          totalCountry={totalCountry}
          federalAmount={statesByHash.FED ? statesByHash.FED.totalAmount : 0}
          separator
          chartHeight={chartHeight}
          data={hBarData}
          max={max}
          min={min}
          name={name}
          stepSize={stepSize}
          options={{
            legend: {
              display: false
            },
            title: {
              display: false,
              text: name
            },
            plugins: {
              datalabels: {
                color: '#000',
                clamp: true,
                offset: 10,
                align: 'right',
                formatter: value => {
                  return getFormattedValue(
                    value || 0,
                    2,
                    indicator.recordsAsPercentage,
                    indicator.recordsAsCoin
                  );
                }
              }
            },
            tooltips: {
              enabled: false
            },
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              yAxes: [
                {
                  stacked: true,
                  gridLines: {
                    display: true
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    maxTicksLimit: 6,
                    stepSize: stepSize,
                    max: max,
                    min: min
                  },
                  gridLines: {
                    display: true
                  }
                }
              ]
            }
          }}
        />
        {Object.keys(missingStates).length ? (
          <section className={s.no_data_states}>
            <p className={s.no_data_states__head}>
              <AlignLeft />
              <strong>{D.general.noDataStates}</strong>
            </p>
            <ul className={s.no_data_states__list}>
              {Object.keys(missingStates).map(key => {
                return (
                  <li key={key} className={s.no_data_states__badge}>
                    <small>{missingStates[key].state.name}</small>
                  </li>
                );
              })}
            </ul>
          </section>
        ) : null}
        <section className={['__row end_xs', s.__abreviations].join(' ')}>
          <section className="__col_sm_4 __col_xs_12">
            <button
              aria-label={D.info.seeAbreviations}
              data-tip={D.info.seeAbreviations}
              className={s.__abreviations_btn}
              onClick={showAbrev}
            >
              <Info color="#FFF" />
            </button>
          </section>
        </section>
        <ReactTooltip />
      </section>
    </section>
  );
};

ChartsContainer.propTypes = {
  legendColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  legendValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  missingStates: PropTypes.object,
  name: PropTypes.string.isRequired,
  statesById: PropTypes.arrayOf(PropTypes.string).isRequired,
  statesByHash: PropTypes.object.isRequired,
  asCoin: PropTypes.bool,
  asPercentage: PropTypes.bool,
  totalState: PropTypes.any,
  totalESE: PropTypes.any,
  totalCountry: PropTypes.any
};

export default ChartsContainer;
