import * as IndicatorActions from 'constants/indicator-constants';

const initialState = {
  // get indicators
  errorGettingIndicators: undefined,
  gettingIndicators: false,
  indicatorsById: [],
  indicatorsByHash: {},

  // save/edit indicator
  errorSaving: undefined,
  saving: 0,

  // get one indicator
  errorLoadingOne: undefined,
  loadingOne: 0,
  selected: {},

  // records
  errorLoadingRecords: undefined,
  loadingRecords: 0,
  records: [],

  // fetch indicators of this item
  selectedIndicatorsItemId: undefined,
}

export const indicatorReducer = (state = initialState, action) => {
  switch (action.type) {

    case IndicatorActions.GET_INDICATORS_BEGIN:
      return {
        ...state,
        errorGettingIndicators: undefined,
        gettingIndicators: true,
      }

    case IndicatorActions.GET_INDICATORS_SUCCESS: {
      const indicators = action.payload;
      return {
        ...state,
        gettingIndicators: false,
        indicatorsById: indicators.map((i) => i._id),
        indicatorsByHash: indicators.reduce((obj, i) => {
          return { ...obj, [i._id]: i };
        }, {}),
      }
    }

    case IndicatorActions.GET_INDICATORS_ERROR:
      return {
        ...state,
        errorGettingIndicators: action.payload,
        gettingIndicators: false,
      }

    case IndicatorActions.SAVE_INDICATOR_BEGIN:
      return {
        ...state,
        errorSaving: undefined,
        saving: 1,
      }

    case IndicatorActions.SAVE_INDICATOR_ERROR:
      return {
        ...state,
        errorSaving: action.payload,
        saving: 0,
      }

    case IndicatorActions.CREATE_INDICATOR_SUCCESS: {
      const indicator = action.payload;
      return {
        ...state,
        saving: 0,
        selected: indicator,
        indicatorsById: [ ...state.indicatorsById, indicator._id ],
        indicatorsByHash: {
          ...state.indicatorsByHash,
          [indicator._id]: indicator
        }
      }
    }

    case IndicatorActions.CLEAR_LOADING_INDICATOR: {
      const { payload } = action;
      return {
        ...state,
        saving: payload.saving,
        loading: payload.loading,
        loadingRecords: payload.loadingRecords,
      }
    }

    case IndicatorActions.FIND_INDICATOR_BEGIN:
      return {
        ...state,
        errorLoadingOne: undefined,
        loadingOne: 1,
        selected: {}
      }

    case IndicatorActions.FIND_INDICATOR_SUCCESS:
      return {
        ...state,
        loadingOne: 0,
        selected: action.payload
      }

    case IndicatorActions.FIND_INDICATOR_ERROR:
      return {
        ...state,
        loadingOne: 0,
        errorLoadingOne: action.payload
      }

    case IndicatorActions.SAVE_INDICATOR_SUCCESS: {
      const indicator = action.payload;
      return {
        ...state,
        saving: 0,
        selected: indicator,
        indicatorsByHash: {
          ...state.indicatorsByHash,
          [indicator._id]: indicator
        }
      }
    }

    case IndicatorActions.DELETE_INDICATOR_SUCCESS: {
      const _id = action.payload;
      // eslint-disable-next-line no-unused-vars
      const { [_id]: _delete, ...newIndicatorsByHash } = state.indicatorsByHash;
      return {
        ...state,
        indicatorsById: state.indicatorsById.filter((id) => id !== _id),
        indicatorsByHash: newIndicatorsByHash,
      }
    }

    case IndicatorActions.UPDATE_RECORDS_SUCCESS:
      return {
        ...state,
        saving: 0,
      }

    case IndicatorActions.FIND_RECORDS_BEGIN:
      return {
        ...state,
        errorLoadingRecords: undefined,
        loadingRecords: 1,
        records: [],
      }

    case IndicatorActions.FIND_RECORDS_SUCCESS:
      return {
        ...state,
        loadingRecords: 0,
        records: action.payload,
      }

    case IndicatorActions.FIND_RECORDS_ERROR:
      return {
        ...state,
        loadingRecords: 0,
        errorLoadingRecords: action.payload
      }

    case IndicatorActions.SET_INDICATORS_ITEM_ID:
      return {
        ...state,
        selectedIndicatorsItemId: action.payload
      }

    default:
      return state;
  }
}
