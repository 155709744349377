import React from 'react';

// styles
import s from 'styles/components/separator.module.scss';

const Separator = () => (
  <hr className={s.__main} />
)

export default Separator;
