/**
 * Function to wrap a promise in another promise to be
 * able to programmatically cancel it.
 *
 * @param {Promise} promise Promise to handle
 *
 * @returns {object} Object with a new promise and a
 * function to reject the promise
 */
export const cancelablePromise = promise => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      value => (hasCanceled ? reject({ isCanceled: true, value }) : resolve(value)),
      error => reject({ isCanceled: hasCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (hasCanceled = true),
  };
};
