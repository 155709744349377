import * as React from 'react'
import styles from 'styles/scenes/login/loginAlerts.module.scss'
import type from 'styles/default.scss'
import PropTypes from 'prop-types'

// constants

const MailSendSuccess = (props) => {
  const message = props.label
  return (
    <section className={styles.__ok}>
      <p className={type.sub}>{message}</p>
    </section>
  )
}


MailSendSuccess.propTypes = {
  label: PropTypes.string.isRequired,
}
export default MailSendSuccess;
