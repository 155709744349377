import React from 'react'
import PropTypes from 'prop-types';

// assets
import excel from 'assets/plantilla.xlsx';

// constants
import { D } from 'constants/dictionary';

// styles
import styles from './styles.module.scss'

const DOWNLOAD_ICON = require('assets/download.png')
const EXCEL_ICON = require('assets/excel-file.png')

export const DropzoneComp = ({
  files,
  onChange,
}) => {

  const renderFile = (file) => (
    <section key={file}>
      <img src={EXCEL_ICON} alt="Excel Icon from the upload file" />
      <small>{file}</small>
    </section>
  );

  return (
    <>
      <section className={styles.lgt_dropdown}>
        <header className={styles.lgt_dropdown__header}>
          <a className={[styles.__no_link, styles.__download].join(' ')} href={excel} download="plantilla.xlsx">
            <small>{D.indicators.data.download}</small>
            <img src={DOWNLOAD_ICON} alt="Download Icon"/>
          </a>
        </header>
        <section className={styles.lgt_dropzone}>
          <div>
            <label className={styles.lgt_dropzone__input} htmlFor="excel">
              <input
                type="file"
                id="excel"
                accept=".xlsx, .xls"
                onClick={(e) => e.target.value = ''} // allow upload of same file
                onChange={onChange} />
            </label>
            <small className={styles.lgt_dropzone__label}>{D.indicators.data.dropzone}</small>
          </div>
          {files.length === 0 ? null : <aside className={styles.lgt_dropzone__summary}>
            <small className={styles.lgt_dropzone__label}>{D.indicators.data.excel}</small>
            <section className={styles.lgt_dropzone__ul}>
              {files.map((file) => renderFile(file))}
            </section>
          </aside>}
        </section>
        <small className={styles.lgt_textarea__advice}>{D.indicators.data.instruction}</small>
      </section>
    </>
  );
}

DropzoneComp.propTypes = {
  files: PropTypes.any,
  onChange: PropTypes.func.isRequired,
}
