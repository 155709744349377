import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// api
import { getItemsCT } from 'api/item-api';
import * as Tokens from 'api/indicator-api';

// constants
import { D } from 'constants/dictionary';

// Redux Actions
import { deleteIndicator, getIndicators, setSelectedIndicatorsItemId } from 'actions/indicator-actions';
import { getItems } from 'actions/item-actions';

// Components
import ShowIndicators from './ShowIndicators';

class ShowIndicatorsContainer extends Component {

  constructor() {
    super();

    this.state = {
      selected: {},
      error: '',
      showAlertModal: false,
    }
  }

  // Get items to set the dropdown
  componentDidMount() {
    this.props.getItems();
  }

  componentDidUpdate(prevProps) {
    this.didUpdateOnLoadItems(prevProps);
    this.didUpdateOnLoad(prevProps);
  }

  componentWillUnmount() {
    const { requestCanceled: rc } = D.errors;
    // cancel pending requests
    Tokens.getIndicatorsCT && Tokens.getIndicatorsCT.cancel(rc);
    Tokens.deleteIndicatorCT && Tokens.deleteIndicatorCT.cancel(rc);
    getItemsCT && getItemsCT.cancel(rc);
  }

  // Get indicators after loading the items
  didUpdateOnLoadItems = (prevProps) => {
    const loaded = prevProps.loadingItems && !this.props.loadingItems;

    if (!loaded) {
      return;
    }

    const { errorLoadingItems: error, itemsByHash, itemsById, selectedIndicatorsItemId } = this.props;
    if (!error) {
      let itemId = '';
      // If there are items, check if the currently selected itemId is valid
      if (itemsById.length) {
        const item = itemsByHash[selectedIndicatorsItemId];
        if ((item && item.hasIndicators) || selectedIndicatorsItemId === '') {
          itemId = selectedIndicatorsItemId;
        } else {
          // Find the first item that could have indicators
          itemId = itemsById.find((i) => {
            const itemData = itemsByHash[i];
            return itemData && itemData.hasIndicators;
          }) || ''; // if no item has indicators, reset the value
        }
      }

      // Save the new selected item id
      if (itemId !== selectedIndicatorsItemId) {
        this.props.setSelectedIndicatorsItemId(itemId);
      }
      this.props.getIndicators(itemId);
    } else {
      this.setError(error);
    }
  }

  didUpdateOnLoad = (prevProps) => {
    const loaded = prevProps.gettingIndicators && !this.props.gettingIndicators;

    if (!loaded) {
      return;
    }

    const { errorGettingIndicators: error } = this.props;

    if (error) {
      this.setError(error);
    }
  }

  setError = (error) => {
    if (error.response) {
      this.setState({ error: D.errors.serverError });
    } else {
      this.setState({ error: D.errors.noConnection });
    }
  }

  /**
   * Set the new selected item id and fetch its indicators.
   */
  onItemIdChange = (e) => {
    const itemId = e.target.value;
    this.setState({ error: '' });
    this.props.setSelectedIndicatorsItemId(itemId);
    this.props.getIndicators(itemId);
  }

  /**
   * Display alert before calling action
   * to delete the indicator.
   */
  displayModal = (id) => {
    const indicator = this.props.indicatorsByHash[id];
    this.setState({
      selected: indicator,
      showAlertModal: true,
    });
  }

  /**
   * Action used after confirming the alert.
   */
  deleteIndicatorAction = (id) => {
    this.props.deleteIndicator(id);
    this.resetState();
  }

  hideModal = () => {
    this.resetState();
  }

  resetState = () => {
    this.setState({
      showAlertModal: false,
      selectedItem: {},
    });
  }

  render() {
    const {
      error,
      selected,
      showAlertModal,
    } = this.state;
    const {
      gettingIndicators,
      history,
      indicatorsByHash,
      indicatorsById,
      itemsByHash,
      itemsById,
      loadingItems,
      selectedIndicatorsItemId,
    } = this.props
    return (
      <>
        <ShowIndicators
          deleteIndicatorAction={this.deleteIndicatorAction}
          displayModal={this.displayModal}
          error={error}
          hideModal={this.hideModal}
          history={history}
          loading={gettingIndicators || loadingItems}
          indicatorsByHash={indicatorsByHash}
          indicatorsById={indicatorsById}
          indicator={selected}
          itemsByHash={itemsByHash}
          itemsById={itemsById}
          onItemChange={this.onItemIdChange}
          selectedItemId={selectedIndicatorsItemId || ''}
          showAlertModal={showAlertModal}
        />
      </>
    )
  }
}

ShowIndicatorsContainer.propTypes = {
  deleteIndicator: PropTypes.func.isRequired,
  errorGettingIndicators: PropTypes.any,
  errorLoadingItems: PropTypes.any,
  getIndicators: PropTypes.func.isRequired,
  getItems: PropTypes.func.isRequired,
  gettingIndicators: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  indicatorsByHash: PropTypes.object.isRequired,
  indicatorsById: PropTypes.arrayOf(PropTypes.string).isRequired,
  itemsByHash: PropTypes.object.isRequired,
  itemsById: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadingItems: PropTypes.bool.isRequired,
  selectedIndicatorsItemId: PropTypes.any,
  setSelectedIndicatorsItemId: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    errorGettingIndicators: state.indicatorReducer.errorGettingIndicators,
    errorLoadingItems: state.itemReducer.errorLoadingItems,
    gettingIndicators: state.indicatorReducer.gettingIndicators,
    indicatorsByHash: state.indicatorReducer.indicatorsByHash,
    indicatorsById: state.indicatorReducer.indicatorsById,
    itemsByHash: state.itemReducer.itemsByHash,
    itemsById: state.itemReducer.itemsById,
    loadingItems: state.itemReducer.loadingItems,
    selectedIndicatorsItemId: state.indicatorReducer.selectedIndicatorsItemId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteIndicator: (id) => dispatch(deleteIndicator(id)),
    getIndicators: (itemId) => dispatch(getIndicators(itemId)),
    getItems: () => dispatch(getItems()),
    setSelectedIndicatorsItemId: (itemId) => dispatch(setSelectedIndicatorsItemId(itemId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowIndicatorsContainer);
