export const GET_INDICATORS_BEGIN = 'GET_INDICATORS_BEGIN';
export const GET_INDICATORS_SUCCESS = 'GET_INDICATORS_SUCCESS';
export const GET_INDICATORS_ERROR = 'GET_INDICATORS_ERROR';
export const SAVE_INDICATOR_BEGIN = 'SAVE_INDICATOR_BEGIN';
export const SAVE_INDICATOR_ERROR = 'SAVE_INDICATOR_ERROR';
export const SAVE_INDICATOR_SUCCESS = 'SAVE_INDICATOR_SUCCESS';
export const CREATE_INDICATOR_SUCCESS = 'CREATE_INDICATOR_SUCCESS';
export const CLEAR_LOADING_INDICATOR = 'CLEAR_LOADING_INDICATOR';
export const FIND_INDICATOR_BEGIN = 'FIND_INDICATOR_BEGIN';
export const FIND_INDICATOR_SUCCESS = 'FIND_INDICATOR_SUCCESS';
export const FIND_INDICATOR_ERROR = 'FIND_INDICATOR_ERROR';
export const DELETE_INDICATOR_SUCCESS = 'DELETE_INDICATOR_SUCCESS';
export const UPDATE_RECORDS_SUCCESS = 'UPDATE_RECORDS_SUCCESS';
export const DELETE_RECORDS_SUCCESS = 'DELETE_RECORDS_SUCCESS';
export const FIND_RECORDS_BEGIN = 'FIND_RECORDS_BEGIN';
export const FIND_RECORDS_SUCCESS = 'FIND_RECORDS_SUCCESS';
export const FIND_RECORDS_ERROR = 'FIND_RECORDS_ERROR';
export const SET_INDICATORS_ITEM_ID = 'SET_INDICATORS_ITEM_ID';
