import React, { useState, useEffect } from 'react';
import c from 'styles/components/chart.module.scss';
import s from 'styles/components/table.module.scss';
import t from 'styles/components/text.module.scss';
import PropTypes from 'prop-types';
import { TableFooter } from './Footer';

// constants
import { D } from 'constants/dictionary';
import { stateCodes } from 'constants/state-codes';

// helpers
import { getFormattedValue } from 'helpers/get-formatted-value';
import HeadAmount from './../../../../../components/HeadAmount';
import { AlignLeft } from 'react-feather';
import { getSpecialTags } from 'helpers/get-special-tags';

const MIN_VALUE = 10;
let rank = 0;

export const TableBody = props => {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(MIN_VALUE);
  const [maxCount, setMaxCount] = useState(0);
  const [defaultCount, setDefaultCount] = useState(0);

  let totalCountryTag = '';
  let totalStateTag = '';
  let totalESETag = '';
  if (props.hasSpecialTags && props.tags) {
    let tagMap = getSpecialTags(props.tags)

    totalCountryTag = tagMap.get(props.totalCountry) || D.info.noTag;
    totalStateTag = tagMap.get(props.totalState) || D.info.noTag;
    totalESETag = tagMap.get(props.totalESE) || D.info.noTag;
  }

  // Set data array to state
  useEffect(() => {
    setData(props.dataId); // Array
    // Validate country is present in data
    const mCount = props.dataStates[stateCodes.FED.code]
      ? props.dataId.length - 1
      : props.dataId.length;
    setMaxCount(mCount);
    setDefaultCount(maxCount - count); // i.e (32 - 10) we use the result as a restriction
  }, [
    props.dataId,
    maxCount,
    count,
    props.dataStates,
    props.hasSpecialTags,
    props.tags
  ]);
  // Set state to 32
  const expandList = () => {
    let counter = defaultCount;
    counter = counter + count;
    if (counter <= maxCount) {
      return setCount(counter);
    }
  };
  // Return to collapsed by setting state to 10
  const collapseList = () => {
    setCount(MIN_VALUE);
  };
  // Row component to show ranking lists
  const showData = (item, i) => {
    let DATA_MAP = props.dataStates[item];
    if (i === 0) {
      rank = 0;
    }
    if (DATA_MAP && DATA_MAP.state.type === 'state') {
      rank++;
      let res = {};
      if (props.hasSpecialTags) {
        res = props.tags.find(tag => {
          return parseInt(tag.index) === DATA_MAP.totalAmount;
        });
        DATA_MAP = { ...DATA_MAP, totalAmount: res ? res.name : D.info.noTag };
      }
      return i <= count ? (
        <div key={i} className={s.__table_row}>
          <div className={[s.__row_cell, s.__num].join(' ')}>
            <small className={[t.__black__light].join(' ')}>{rank}</small>
          </div>
          <div className={[s.__row_cell, s.__txt].join(' ')}>
            <small className={[t.__black__light].join(' ')}>
              {DATA_MAP.state.name}
            </small>
          </div>
          <div className={[s.__row_cell, s.__txt].join(' ')}>
            <small className={[t.__blackt, t.__right].join(' ')}>
              {props.hasSpecialTags ? DATA_MAP.totalAmount : getFormattedValue(DATA_MAP.totalAmount, 2, props.asPercentage, props.asCoin)}
            </small>
          </div>
        </div>
      ) : (
        ''
      );
    }
  };

  const ShowMore = data => {
    if (data.length > 0) {
      if (count === maxCount) {
        return <TableFooter label={D.info.seeLess} onClick={collapseList} />;
      } else {
        return <TableFooter label={D.info.seeMore} onClick={expandList} />;
      }
    } else {
      return null;
    }
  };

  // If maxCount is 0, it means there is no data about the states
  return (
    <>
      {maxCount > 0 && (
        <>
          <div className={s.__table_head}>
            <div className={[s.__head_cell, s.__num].join(' ')}>
              <small className={[t.__uppercase, t.__gray].join(' ')}>N°</small>
            </div>
            <div className={[s.__head_cell, s.__txt].join(' ')}>
              <small className={[t.__uppercase, t.__gray].join(' ')}>
                {D.info.state}
              </small>
            </div>
            <div className={[s.__head_cell, s.__txt].join(' ')}>
              <small className={[t.__uppercase, t.__gray, t.__right].join(' ')}>
                {props.totalName}
              </small>
            </div>
          </div>
          {!props.dataId.length ? (
            <div className={[s.__row_cell].join(' ')}>
              <small className={[t.__black, t.__center].join(' ')}>
                {D.errors.noData}
              </small>
            </div>
          ) : null}
          <section className="__col_xs_12 __row" style={{ padding: 0,     borderBottom: '1px solid #eaeaea' }}>
            {props.totalCountry !== undefined && (<section className="__col_xs_12 __col_md_4">
              <HeadAmount
                labelAmount={D.info.total}
                amount={
                  props.hasSpecialTags ? totalCountryTag : getFormattedValue(props.totalCountry, 2, props.asPercentage, props.asCoin)
                }
              /></section>
            )}
            {props.totalState !== undefined && (<section className="__col_xs_12 __col_md_4">
              <HeadAmount
                labelAmount={D.info.totalState}
                amount={props.hasSpecialTags ? totalStateTag : getFormattedValue(props.totalState, 2, props.asPercentage, props.asCoin)}
              /></section>
            )}
            {props.totalESE !== undefined && (<section className="__col_xs_12 __col_md_4">
              <HeadAmount
                labelAmount={D.info.totalESE}
                amount={props.hasSpecialTags ? totalESETag : getFormattedValue(props.totalESE, 2, props.asPercentage, props.asCoin)}
              /></section>
            )}
          </section>
          {data.map(showData)}
          {maxCount > MIN_VALUE && ShowMore(data)}


          {Object.keys(props.missingStates).length ? (
            <section className={c.no_data_states_table}>
              <p className={c.no_data_states__head}>
                <AlignLeft />
                <strong>{D.general.noDataStates}</strong>
              </p>
              <ul className={c.no_data_states__list}>
                {Object.keys(props.missingStates).map((key, index) => {
                  return (
                    <li key={index} className={c.no_data_states__badge}>
                      <small>{props.missingStates[key].state.name}</small>
                    </li>
                  );
                })}
              </ul>
            </section>
          ) : null}
        </>
      )}
    </>
  );
};

TableBody.propTypes = {
  asCoin: PropTypes.bool,
  asPercentage: PropTypes.bool,
  dataId: PropTypes.arrayOf(PropTypes.string).isRequired,
  dataStates: PropTypes.object.isRequired,
  missingStates: PropTypes.object,
  hasSpecialTags: PropTypes.bool,
  tags: PropTypes.array,
  totalCountry: PropTypes.any,
  totalState: PropTypes.any,
  totalESE: PropTypes.any,
  totalName: PropTypes.any
};
