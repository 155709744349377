import React, { useState, useEffect, useContext } from 'react';
import { AlignLeft } from 'react-feather';
import * as queryString from 'query-string';
import PropTypes from 'prop-types';

// context
import { DatesContext } from 'providers/DatesProvider';
import { IndicatorContext } from 'providers/IndicatorProvider';
import { ItemContext } from 'providers/ItemProvider';
import { SearchContext } from 'providers/SearchProvider';

// constants
import { D } from 'constants/dictionary';
import * as QUERY from 'constants/search-query-parameters';
import * as ROUTES from 'constants/routes-constants';

// styles
import s from 'styles/components/header.module.scss';
import t from 'styles/components/text.module.scss';
import 'styles/grid.scss';

// components
import DateFilter from './DateFilter';
import Sidebar from 'components/Sidebar';
import { AlertTriangle } from 'react-feather';

const Spinner = require('assets/spinner.gif');

const WP_LINK = require('assets/wp-icon.png')

const Header = ({ history }) => {
  const [menuState, setMenuState] = useState(false);

  const { item, items, loadingItem, loading: loadingItems, setItem } = useContext(ItemContext);

  const { indicators, loading: loadingIndicators } = useContext(IndicatorContext);

  const { setYear } = useContext(DatesContext);

  const { setFirstURL, setSearch } = useContext(SearchContext);

  const [loading, setLoading] = useState(loadingItems || loadingIndicators || loadingItem);

  /** Use only one variable for the loading status */
  useEffect(() => {
    setLoading(loadingItems || loadingIndicators || loadingItem);
  }, [loadingItems, loadingIndicators, loadingItem]);

  const closeSidebar = () => {
    setMenuState(false);
  };

  const menuBehaviour = () => {
    setMenuState(!menuState);
  };

  /**
   * If the item clicked on the sidebar is differente than
   * the one already selected, reset the year and apply changes
   * to the search params.
   */
  const _setItem = selectedItem => {
    if (selectedItem._id !== item._id) {
      const newSearch = queryString.stringify({
        [QUERY.ITEM_ID]: selectedItem._id
      });

      setYear({});
      setItem(selectedItem);
      setSearch(newSearch);
      setFirstURL('');
      history.replace({ search: newSearch });
    }

    closeSidebar();
  };

  return (
    <>
      <nav className={s.__nav}>
        <section className={s.__header}>
          <section className={s.__header_wrap}>
            <button disabled={loading} onClick={menuBehaviour} className={s.__indicators} aria-label='Open Menu'>
              {loading ? <img src={Spinner} width={32} height={32} alt='spinner.gif' /> : <AlignLeft size={32} />}
            </button>
            <section className='__col_xs_8 __row start_xs'>
              <small className={[`${t.__gray} __col_xs_12`].join(' ')}>{D.items.selectedItem}</small>
              <h2 className={[t.__white, t.__ellipsis, `__col_xs_12`].join(' ')}>
                <strong>{item.shortName}</strong>
              </h2>
            </section>
          </section>
          <a href={ROUTES.REACT_APP_WP_PAGE} target='_self' rel='noopener noreferrer' className={s.__news}>
            <img src={WP_LINK} width={38} loading="lazy" alt="Hipervínculo a sitio de información"/>
            <small>{D.info.index}</small>
          </a>
        </section>
        {!!items.length && !!indicators.length ? (
          <DateFilter history={history} />
        ) : /* check that the value is actually false
         and not undefined */
        item.hasIndicators === false ? null : (
          <section className={[`${s.__filter} __row center_xs`].join(' ')}>
            <small className={[t.__gray, s.__errorIndicator].join(' ')}>
              <AlertTriangle size={16} color='#eaeaea' />
              &nbsp;&nbsp;{D.errors.noDataItem}
            </small>
          </section>
        )}
      </nav>
      {!menuState ? null : (
        <>
          <section className={s.__bg}></section>
          <Sidebar
            closeSidebar={closeSidebar}
            elements={items}
            menuBehaviour={menuBehaviour}
            setElement={_setItem}
            title={D.info.selectItem}
          />
        </>
      )}
    </>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired
};

export default Header;
