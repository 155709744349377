import React, { useContext, useEffect } from "react";
import { PieChart } from "react-feather";
import "react-quill/dist/quill.snow.css";
import MarkdownIt from "markdown-it";
import MarkdownItIns from "markdown-it-ins";
import moment from "moment";
import { tableFormat } from "constants/date-formats";
// context
import { ItemContext } from "providers/ItemProvider";

// constants
import { D } from "constants/dictionary";

// components
import Slider from "components/Slider";
import Title from "components/Title";
import ItalicContent from "components/ItalicContent";

// styles
import s from "styles/scenes/theme.module.scss";

const NoIndicatorSummary = () => {
  const { item } = useContext(ItemContext);
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true
  }).use(MarkdownItIns);

  /**
   * Replace the line breaks in the content property
   * with the <br /> tag to create paragraphs.
   */
  useEffect(() => {
    const div = document.getElementById("no_indicator_content");
    div.innerHTML = `<div class="md-editor">${mdParser.render(
      item.content || ""
    )}</div>`;
  }, [item.content, mdParser]);
  const updatedAt = moment
    .utc(item.updatedAt)
    .local()
    .format(tableFormat);
  const lastUpdateText = `${D.general.lastUpdateDate} ${updatedAt}`;
  return (
    <section key={item._id} className={s.__fadein}>
      {item.coverImage && item.coverImage.url && (
        <img
          alt="cover"
          width="100%"
          className={s.__cover}
          src={item.coverImage.url}
        />
      )}
      <section
        className={[s.__description, "__wrapper", s.__no_indicators].join(" ")}
      >
        <section className="__row">
          <Title icon={<PieChart />} title={item.title || D.errors.noData} />
          <section className={["__row", s.__content].join(" ")}>
            <div className={s.__content_container} id="no_indicator_content" />
          </section>
          {item.sliderImages && !!item.sliderImages.length && (
            <section className={s.__slider}>
              <Slider images={item.sliderImages.map(i => i.url)} />
            </section>
          )}
          {item.updatedAt && (
            <>
              <ItalicContent text={lastUpdateText} />
            </>
          )}
        </section>
      </section>
    </section>
  );
};

export default NoIndicatorSummary;
