import React from 'react'

import styles from 'styles/components/input.module.scss'
import PropTypes from "prop-types"

export const Input = ({
  advice,
  input,
  label,
  meta: { error, submitFailed }
}) => {
  return (
    <>
      <section className={styles.lgt_input__bound}>
        <label htmlFor={input.name} className={styles.lgt_input__label}>{label}</label>
        <input
          {...input}
          name={input.name}
          type="text"
          className={styles.lgt_input}
        />
      </section>
      {submitFailed && error && <p className={styles.lgt_input__error}>{error}</p>}
      <small className={styles.lgt_input__advice}>{advice}</small>
    </>
  )
}

Input.propTypes = {
  advice: PropTypes.string,
  name: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
  }),
}
