import React, { useContext } from "react";
import {
  PieChart,
  Percent,
  Clipboard,
  BookOpen,
  FilePlus,
  Book,
  FileText,
  Inbox,
  Flag,
  Link,
  Radio
} from "react-feather";
import isUrl from "is-url";
import PropTypes from "prop-types";
import moment from "moment";
import { CSVLink } from "react-csv";
import { tableFormat } from "constants/date-formats";

// context
import { IndicatorContext } from "providers/IndicatorProvider";

// constants
import { D } from "constants/dictionary";

// components
import Content from "components/Content";
import ItalicContent from "components/ItalicContent";
import SmallContent from "./../../../../components/SmallContent";
import Subtitle from "components/Subtitle";
import TableChart from "./Tablechart";
import Title from "components/Title";

// styles
import i from "styles/components/indicator-info.module.scss";
import s from "styles/scenes/theme.module.scss";
import a from 'styles/components/accordion.module.scss'

// Excel icon
const EXCEL_ICON = require("./../../../../../src/assets/excel-file.png");

const Summary = ({
  getRanking,
  getStatesBreakdown,
  legendColors,
  legendValues,
  missingStates,
  statesByHash,
  statesById,
  totalCountry,
  totalState,
  totalESE
}) => {
  const { indicator } = useContext(IndicatorContext);

  const csvData = indicator.requestInformation
    ? indicator.requestInformation.length
      ? JSON.parse(indicator.requestInformation[0])
      : undefined
    : undefined;

  const updatedAt = moment
    .utc(indicator.updatedAt)
    .local()
    .format(tableFormat);
  const lastUpdateText = `${D.general.lastUpdateDate} ${updatedAt}`;
  return (
    <>
      <section
        key={indicator._id}
        id="summary"
        className={[s.__description, "__wrapper", s.__fadein].join(" ")}
      >
        <section className="__row">
          <Title
            icon={<PieChart color="#4C5053" />}
            title={indicator._id ? indicator.name : D.errors.noData}
          />
          {indicator.itemId && indicator.itemId.description ? (
            <>
              <section className="__col_xs_12">
                <Subtitle
                  icon={<Book color="#4C5053" />}
                  title={D.indicators.save.aboutItem}
                />
              </section>
              <section className="__col_xs_12">
                <SmallContent text={indicator.itemId.description} />
              </section>
              </>
          ) : (
            ""
          )}
          {indicator.aboutIndicator && (
            <>
              <section className="__col_xs_12">
                <Subtitle
                  icon={<FileText color="#4C5053" />}
                  title={D.indicators.save.aboutIndicator}
                />
              </section>
              <section className="__col_xs_12">
                <Content text={indicator.aboutIndicator} />
              </section>
              </>
          )}
          {indicator.definition && (
            <section className="__row">
              <section className="__col_xs_12">
                <Subtitle
                  icon={<FileText color="#4C5053" />}
                  title={D.indicators.save.indicatorDesc}
                />
              </section>
              <section className="__col_xs_12">
                <Content text={indicator.definition} />
              </section>
            </section>
          )}
          {indicator.introduction && (
            <section className="__row">
              <section className="__col_xs_12">
                <Subtitle
                  icon={<FileText color="#4C5053" />}
                  title={D.indicators.save.introduction}
                />
              </section>
              <section className="__col_xs_12">
                <Content text={indicator.introduction} />
              </section>
            </section>
          )}
        </section>
        {indicator.learnMore && (
          <>
          <section className={`__row ${a.tab}`}>
            <input className={a.tab__check} type="checkbox" id="chck3" />
            <label className={`__row __col_xs_12 ${a.tab_label}`} htmlFor="chck3">
              <section className={`__col_xs_8 ${a.table__title}`}>
                <Subtitle title={D.indicators.save.learnMore} icon={<FilePlus color="#4C5053" size={20} />} />
              </section>
            </label>
            <div className={a.tab_content}>
              <Content text={indicator.learnMore} />
            </div>
          </section>
          </>
        )}
        {indicator._id && !!statesById.length && (
          <TableChart
            getRanking={getRanking}
            getStatesBreakdown={getStatesBreakdown}
            legendColors={legendColors}
            legendValues={legendValues}
            missingStates={missingStates}
            name={indicator.shortName}
            statesById={statesById}
            statesByHash={statesByHash}
            totalState={totalState}
            totalCountry={totalCountry}
            totalESE={totalESE}
          />
        )}
        {indicator._id ? (
          <>
            <section className="__row">
              {indicator.coverImage && indicator.coverImage.url ? (
                <section
                  className={["__col_xs_12 __col_sm_6", i.__content].join(" ")}
                >
                  <Subtitle
                    style={{padding: 0}}
                    title={D.indicators.save.calc}
                    icon={<Percent color="#4C5053" size={20} />}
                  />
                  <section className={s.__formula}>
                    <img alt="cover" width="100%" className={s.__cover} src={indicator.coverImage.url} />
                  </section>
                </section> ) : ''}
              {indicator.sources && !!indicator.sources.length && (
                <section
                  className={["__col_xs_12 __col_sm_6", i.__content].join(" ")}
                >
                  <Subtitle
                    style={{padding: 0}}
                    title={D.info.sources}
                    icon={<BookOpen color="#4C5053" size={20} />}
                  />
                  <section className={s.__sources}>
                    <ul>
                      {indicator.sources.map((source, i) => {
                        let newSource = source;
                        let validUrl = isUrl(source);
                        if (!validUrl) {
                          newSource = "http://" + source;
                          validUrl = isUrl(newSource);
                        }
                        return (
                          <li key={i}>
                            {validUrl ? (
                              <a
                                href={newSource}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <small><Link size={14}/><strong> {newSource}</strong></small>
                              </a>
                            ) : (
                              <small><Radio size={14}/> {source}</small>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </section>
                </section>
              )}
            </section>
            {csvData && csvData.length && (
              <>
                <Subtitle
                  title={D.indicators.save.addInfo}
                  icon={<Inbox color="#4C5053" size={20} />}
                />
                <section className="__row">
                  <section className="__col_xs_12">
                    <CSVLink
                      data={csvData}
                      filename={`${indicator.shortName}-${D.indicators.save.addInfo}.csv`}
                      className={s.__csv_link}
                    >
                      <img
                        width={32}
                        src={EXCEL_ICON}
                        alt={`${indicator.shortName}-${D.indicators.save.addInfo}.csv`}
                      />{" "}
                      Descargar documento
                    </CSVLink>
                  </section>
                </section>
              </>
            )}
            {indicator.specialTreatment && (
              <>
                <Subtitle
                  title={D.indicators.save.treatment}
                  icon={<Flag color="#4C5053" size={20} />}
                />
                <Content text={indicator.specialTreatment} />
              </>
            )}
            {indicator.indicatorWeaknesses && (
              <>
                <Subtitle
                  title={D.indicators.save.problems}
                  icon={<Clipboard color="#4C5053" size={20} />}
                />
                <Content text={indicator.indicatorWeaknesses} />
              </>
            )}
            {indicator.updatedAt && (
              <>
                <ItalicContent text={lastUpdateText} />
              </>
            )}
          </>
        ) : (
          <section className={[s.__formula, s.__no_data].join(" ")}>
            <code>{D.errors.noData}</code>
          </section>
        )}
      </section>
    </>
  );
};

Summary.propTypes = {
  getRanking: PropTypes.func.isRequired,
  getStatesBreakdown: PropTypes.func.isRequired,
  legendColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  legendValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  missingStates: PropTypes.object,
  statesByHash: PropTypes.object.isRequired,
  statesById: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalState: PropTypes.any,
  totalCountry: PropTypes.any,
  totalESE: PropTypes.any
};

export default Summary;
