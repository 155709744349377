import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share-hotfix';
import axios from 'axios';
import PropTypes from 'prop-types';

// constants
import { API_URL } from 'constants/general-constants';
import { D } from 'constants/dictionary';

// styles
import s from 'styles/components/share.module.scss';

const btnSize = 40;
const Share = ({
  strForGeoJSON,
  text,
}) => {

  const [shouldFetch, setShouldFetch] = useState(true);
  const [shareUrl, setShareUrl] = useState(window.location.href);
  const axiosSourceRef = useRef(undefined);

  /**
   * Make API request to get a short URL from the original long URL.
   *
   * When shouldFetch is true, it means that the URL has changed and
   * a new request must be made.
   */
  const getShortURL = useCallback(() => {

    if (!shouldFetch) {
      return;
    }

    if (axiosSourceRef.current) {
      axiosSourceRef.current.cancel(D.errors.requestCanceled);
    }

    axiosSourceRef.current = axios.CancelToken.source();
    const data = JSON.stringify({ longURL: shareUrl });

    return axios.post(`${API_URL}url`, data, { cancelToken: axiosSourceRef.current.token })
      .then((res) => {
        setShareUrl(res.data.data.shortURL);
        setShouldFetch(false);
      })
      .catch(() => {
        return;
      });
  }, [shouldFetch, shareUrl]);

  /**
   * When there is new data, indicate that a new request
   * must be made.
   */
  useEffect(() => {
    setShouldFetch(true);
  }, [strForGeoJSON]);

  return (
    <section className={s.__buttons}>
      <FacebookShareButton
        beforeOnClick={getShortURL}
        quote={text}
        url={shareUrl}
      >
        <FacebookIcon size={btnSize} />
      </FacebookShareButton>

      <TwitterShareButton
        beforeOnClick={getShortURL}
        title={text}
        url={shareUrl}
      >
        <TwitterIcon size={btnSize} />
      </TwitterShareButton>

      <WhatsappShareButton
        beforeOnClick={getShortURL}
        title={text}
        url={shareUrl}
      >
        <WhatsappIcon size={btnSize} />
      </WhatsappShareButton>

      <EmailShareButton
        beforeOnClick={getShortURL}
        subject={text}
        url={shareUrl}
      >
        <EmailIcon size={btnSize} />
      </EmailShareButton>

    </section>
  );
}

Share.propTypes = {
  strForGeoJSON: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default Share;
