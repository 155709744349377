/**
 *
 * @param {number} value Value to format
 * @param {any} digits Number of digits to display, or undefined
 *   if the value shouldn't ve truncated
 * @param {boolean} asPercentage If true, the % char will be added
 *   at the end.
 * @param {boolean} asCoin If true, the $ char will be added
 *   at the begininng.
 */
export const getFormattedValue = (value, digits, asPercentage, asCoin) => {
  const options = {};
  if (digits) {
    options.maximumFractionDigits = digits;
  }

  return `${asCoin ? '$' : ''}${value.toLocaleString(undefined, options)}${asPercentage ? '%' : ''}`;
}
