import React from 'react'
import PropTypes from 'prop-types';
import s from 'styles/components/loader.module.scss'

export const Loader = (props) => {
  return (
    <section className={s.__loader}>
      <div className={s.__dots}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {props.message && (
        <span className={s.__message}>
          {props.message}
        </span>
      )}
    </section>
  )
}

Loader.propTypes = {
  message: PropTypes.string,
}
