import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux';
import { Link } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import * as Validate from '../../helpers/validations'
import PropTypes from 'prop-types'
import { recoverPasswordCancelToken } from 'api/user-api'
//Components
import { Loader } from  'components/Loader'
import Button from 'components/Button'
import LoginError from '../login/components/LoginError'
import MailSendSuccess from '../login/components/MailSendSuccess'
import Textfield from 'scenes/login/components/Textfield'

//Styles
import login_styles from 'styles/scenes/login/login.module.scss'
import Logo from 'assets/logo.png'
import { recoverPassword, clearState } from 'actions/user-actions';

// constants
import { D } from 'constants/dictionary';
import { LGT_ADMIN } from 'constants/routes-constants';

class RecoverPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailSuccess: false,
    }
  }

  componentWillUnmount() {
    recoverPasswordCancelToken && recoverPasswordCancelToken.cancel(D.errors.requestCanceled);
  }

  componentDidMount() {
    const {location: { state }} = this.props;

    this.props.initialize({
      email: state && state.email,
    });
    this.props.clearState();
  }

  onSubmit = (value) => {
    let data = {
      ...value
    }
    this.props.recoverPassword(data)
  }

  render() {
    const {handleSubmit} = this.props;

    return (
      <main className={login_styles.__wrapper}>
        {this.props.loading && <Loader />}
        <section className="__wrapper">
          <section className="__row center_xs middle_xs">
            <section className="__col_xs_12 center-xs">
              <figure className={login_styles.__logo}>
                <img src={Logo} alt='logo' />
              </figure>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_12 center-xs">
              <h1>
                {D.login.recover}
              </h1>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_12 center-xs">
              <p>
                {D.login.addEmail}
              </p>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_8 center-xs ">
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  name="email"
                  props={{
                    withIcon: true,
                    icon: 'mail',
                    input_type: 'text',
                    input_placeholder: D.login.email,
                  }}
                  component={Textfield}
                  validate={[Validate.required, Validate.email]} />
                <Button login type='submit' label={D.login.sendEmail} />

              </form>
              {this.props.mailError && (this.props.errorStatus === 404 || this.props.errorStatus === 400) ?
                <LoginError label={D.validations.email} /> : (this.props.mailSuccess) ?
                  <MailSendSuccess label={D.login.emailSent} /> : ''
              }
              <section className={login_styles.__middle}>
                <Link to={LGT_ADMIN}>{D.login.backLogin}</Link>
              </section>
            </section>
          </section>
        </section>
      </main>
    )
  }
}

const form = reduxForm({
  form: 'recoverPassword'
});

RecoverPassword.propTypes = {
  initialize: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  errorStatus: PropTypes.number.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  clearState: PropTypes.func,
  mailSuccess:PropTypes.bool.isRequired,
  mailError: PropTypes.bool.isRequired,
  loading:PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    mailError: state.authReducer.mailError,
    errorStatus: state.authReducer.errorStatus,
    errorMessage: state.authReducer.errorMessage,
    mailSuccess: state.authReducer.mailSuccess,
    loading: state.authReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    recoverPassword: (data) => dispatch(recoverPassword(data)),
    clearState: () => dispatch(clearState())
  }
}

export default compose(
  form,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RecoverPassword)
