import React from 'react'
import { X } from 'react-feather';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

// components
import Button from 'components/Button';

// styles
import styles from 'styles/components/modal.module.scss'

export const AlertModal = (props) => {
  return (
    <section className={styles.modal__overlay}>
      <section className={styles.modal__card}>
        <button onClick={props.close} className={styles.modal__close}>
          <X size={32} color="red"/>
        </button>
        <h1>{props.title}</h1>
        {props.content && (
          <p>{props.content}</p>
        )}
        { props.children }
        <footer className={styles.modal__buttons}>
          <Button
            label={D.general.continue}
            click={props.onContinue}
            bgStyle="success" />
          <Button
            label={D.general.cancel}
            click={props.onCancel}
            bgStyle="danger" />
        </footer>
      </section>
    </section>
  )
}


AlertModal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  title: PropTypes.string,
  content: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
}
