import { D } from 'constants/dictionary';

let email_regex = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,4}$/i);

export const email = (email) => {
  return email && email_regex.test(email) ? '' : D.validations.email;
}

export const required = (value) => {
  return value && value.trim().length ? '' : D.validations.required;
}

export const maxLength = max => (value) => {
  return value && value.length > max
    ? `${D.validations.maxLength1}${max}${D.validations.maxLength2}`
    : undefined;
}

export const isNumber = (string) => {
  return string && !isNaN(string) ? '' : D.validations.number
}

export const onlyOneSelected = (value, allValues) => {
  return (value && value === allValues.recordsAsCoin) ? D.validations.onlyOne : undefined
}

export const maxLength4 = maxLength(4);
export const maxLength64 = maxLength(64);
export const maxLength255 = maxLength(255);
export const maxLength1024 = maxLength(1024);
export const maxLength16000000 = maxLength(16000000)
