import React from 'react';
import PropTypes from 'prop-types';

import s from 'styles/components/dropdown.module.scss';

export const Dropdown = (props) => {

  return (
    <>
      <label className={s.__hide}>{props.name}</label>
      <select
        aria-label={props.name}
        disabled={props.disabled}
        className={[props.className, s.__select].join(' ')}
        value={props.useProps ? props.value[props.useProps['id']] : props.value.id}
        onChange={props.onChange}
      >
        {
          props.data.map(i => {
            const id = props.useProps ? i[props.useProps['id']] : i.id;
            const label = props.useProps ? i[props.useProps['label']] : i.label;
            return (
              <option
                key={id}
                value={id}
                className={s.__option}
              >
                {label}
              </option>
            );
          })
        }
      </select>
    </>
  )
}

Dropdown.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  useProps: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string
  }),
  value: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string
  }),
}
