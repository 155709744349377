import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';

// actions
import { ADD_ITEM } from 'constants/routes-constants';

// constants
import { D } from 'constants/dictionary';

// Styles
import styles from 'styles/scenes/admin.module.scss'

// Components
import { AlertModal } from 'scenes/admin/components/AlertModal';
import { FAB } from 'scenes/admin/components/Fab';
import { Loader } from 'components/Loader';
import { TableRow, TableHead } from 'scenes/admin/components/Table';
import ServerErrors from 'scenes/admin/components/ServerErrors';
import SortableRow from './SortableRow';

const ShowItems = ({
  deleteItem,
  deleteItemWithId,
  error,
  hideAlertModal,
  history,
  item,
  itemsById,
  itemsByHash,
  loadingItems,
  onSortEnd,
  showAlertModal,
}) => {

  return (
    <section className={`${styles.lgt_admin} __wrapper`}>
      {showAlertModal && (
        <AlertModal
          title="Alerta"
          onContinue={() => deleteItemWithId(item._id)}
          onCancel={hideAlertModal}
          close={hideAlertModal}
        >
          <>
            <p>{D.items.deleteTitle}<strong>{item.shortName}</strong>?</p>
            {!!item.indicators.length && (
              <p>{D.items.deleteBody}</p>
            )}
          </>
        </AlertModal>
      )}
      {loadingItems && <Loader />}
      <section className={`${styles.lgt_head} __row between_xs`}>
        <h1>{D.items.title}</h1>
        <FAB label={D.items.create} goTo={ADD_ITEM} />
      </section>
      {error && <ServerErrors errors={[error]} cols={12} />}
      {
        itemsById.length ? (
          <>
            <section>
              <TableRow>
                <TableHead col="__col_xs_1 center_xs"><p><strong>#</strong></p></TableHead>
                <TableHead col="__col_xs_2"><p>{D.items.save.name}*</p></TableHead>
                <TableHead col="__col_xs_2"><p>{D.indicators.title}**</p></TableHead>
                <TableHead col="__col_xs_2"><p>{D.general.updatedAt}</p></TableHead>
                <TableHead col="__col_xs_3"><p>{D.general.updatedBy}</p></TableHead>
                <TableHead col="center_xs"><p>{D.general.actions}</p></TableHead>
              </TableRow>
              <SortableList
                deleteItem={deleteItem}
                items={itemsById}
                itemsByHash={itemsByHash}
                onSortEnd={onSortEnd}
                distance={2}
                history={history}
              />
            </section>
            <div className={styles.__footer}>
              <span className={['__row'].join(' ')}>
                * {D.items.note1}
              </span>
              <span className={['__row'].join(' ')}>
                ** {D.items.note2}
              </span>
            </div>
          </>
        ) : (
          <span>{D.items.noData}</span>
        )
      }
    </section>
  )
}

const SortableList = SortableContainer(({ deleteItem, items, history, itemsByHash }) => {
  return (
    <ul>
      {items.map((item, index) => (
        <SortableItem
          key={item}
          index={index}
          value={item}
          history={history}
          itemsByHash={itemsByHash}
          deleteItem={deleteItem}
        />
      ))}
    </ul>
  );
});

const SortableItem = SortableElement(({ deleteItem, value, history, itemsByHash }) => {
  return (
    <SortableRow
      itemId={value}
      history={history}
      itemsByHash={itemsByHash}
      deleteItem={deleteItem}
    />
  );
});

ShowItems.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  deleteItemWithId: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  hideAlertModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  itemsByHash: PropTypes.object.isRequired,
  itemsById: PropTypes.array.isRequired,
  loadingItems: PropTypes.bool.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  showAlertModal: PropTypes.bool.isRequired,
}

export default ShowItems;
