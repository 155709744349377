import React from 'react';
import { Activity, Users, LogOut, Monitor, List, TrendingUp, Globe } from 'react-feather'
import * as ROUTES from './routes-constants';

export const items = [
  { _id: 1, shortName: "Administrar Indicadores", icon: <List size={24} /> , route: ROUTES.INDICATORS},
  { _id: 2, shortName: "Administrar Rubros", icon: <Activity size={24} />, route: ROUTES.ITEMS},
  { _id: 3, shortName: "Administrar Usuarios", icon: <Users size ={24} />, route: ROUTES.USERS },
  { _id: 4, shortName: "Análisis de Visitantes", icon: <TrendingUp size ={24} />, route: ROUTES.REACT_APP_ANALYTICS_ADMIN_URL },
  { _id: 5, shortName: "Administrar Página de Inicio", icon: <Monitor size ={24} />, route: ROUTES.REACT_WP_ADMIN },
  { _id: 6, shortName: "Portal LGT", icon: <Globe size ={24} />, route: ROUTES.REACT_APP_APP_URL },
  { _id: 7, shortName: "Cerrar Sesión", icon: <LogOut size ={24} />, route: ROUTES.LGT_ADMIN}
]
