import { isMobileOnly } from 'react-device-detect';
import {
  MIN_ZOOM,
  MEDIUM_ZOOM,
  MAX_ZOOM,
} from 'constants/map-constants';

export const getMapZoom = () => {

  const { height } = window.screen;

  if (isMobileOnly) {
    return MIN_ZOOM;
  }

  return height >= 1000 ? MAX_ZOOM : MEDIUM_ZOOM;
}
