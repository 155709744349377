import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

// Create context
export const IndicatorContext = createContext();

// Create provider
export const IndicatorProvider = ({ children }) => {

  // Declare values of this provider
  const [indicators, setIndicators] = useState([]);
  const [indicator, setIndicator] = useState({});
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [strFetchIndicators, setStrFetchIndicators] = useState('');

  const value = {
    indicators,
    setIndicators,
    indicator,
    setIndicator,
    error,
    setError,
    loading,
    setLoading,
    strFetchIndicators,
    setStrFetchIndicators,
  }


  return (
    <IndicatorContext.Provider value={value}>
      { children }
    </IndicatorContext.Provider>
  );
}

IndicatorProvider.propTypes = {
  children: PropTypes.any,
}
