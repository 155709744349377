import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

// context
import { DatesContext } from 'providers/DatesProvider';
import { IndicatorContext } from 'providers/IndicatorProvider';
import { SearchContext } from 'providers/SearchProvider';

// helpers
import { getRankingCSV } from 'helpers/get-ranking-csv';
import { getCSVData } from 'helpers/get-csv-data';
import { generateStatesInfo } from 'helpers/generate-states-info';

// constants
import { API_URL, STR_GEOJSON_NO_DATA_PREFIX } from 'constants/general-constants';
import { D } from 'constants/dictionary';

// components
import IndicatorInfo from './IndicatorInfo';

const DataContainer = ({
  history,
}) => {

  const [legendValues, setLegendValues] = useState([]);
  const [legendColors, setLegendColors] = useState([]);
  const [missingData, setMissingData] = useState(undefined);
  const [totalCountry, setTotalCountry] = useState(undefined);
  const [totalState, setTotalState] = useState(undefined);
  const [totalESE, setTotalESE] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [statesById, setStatesById] = useState([]);
  const [statesByHash, setStatesByHash] = useState({});
  const [strForGeoJSON, setStrForGeoJSON] = useState('');
  const [breakdownQuery, setBreakdownQuery] = useState('');
  const [rankingQuery, setRankingQuery] = useState('');
  const [rankingStates, setRankingStates] = useState([]);
  const [breakdownStates, setBreakdownStates] = useState([]);
  const [missingStates, setMissingStates] = useState({});
  const axiosSourceRef = useRef(undefined);

  const {
    indicator
  } = useContext(IndicatorContext);

  const {
    isMonthSelected,
    monthOrQuarter,
    year
  } = useContext(DatesContext);

  const {
    search
  } = useContext(SearchContext);

  const generateQuery = useCallback(() => {
    let query = `${API_URL}records/${indicator._id}?operation=${indicator.recordsAsPercentage ? 'avg' : 'sum'}`;

    // if year.id is 0, it means all years must be retrieved, so it's equal to omitting the parameter
    const selectedYear = year.id;
    if (selectedYear) {
      query += `&year=${selectedYear}`;

      const selectedMonthOrQuarter = monthOrQuarter.id;
      if (selectedMonthOrQuarter !== undefined) {
        const paramName = isMonthSelected ? 'month' : 'quarter';
        query += `&${paramName}=${selectedMonthOrQuarter}`;
      }
    }

    return query;
  }, [indicator, year, monthOrQuarter, isMonthSelected]);

  const getStatesBreakdown = useCallback(async () => {
    if (axiosSourceRef.current) {
      axiosSourceRef.current.cancel(D.errors.requestCanceled);
    }

    const result = { success: false };

    if (!indicator._id || year.id === undefined) {
      return result;
    }

    let query = generateQuery();

    query += '&breakdown=1';

    if (query === breakdownQuery) {
      return {
        success: true,
        data: breakdownStates,
        random: Math.random().toString(36),
      }
    } else {
      setBreakdownQuery(query);
    }

    axiosSourceRef.current = axios.CancelToken.source();
    await axios.get(query, { cancelToken: axiosSourceRef.current.token })
      .then((response) => {
        const { data } = response;

        if (data.success && data.data) {
          result.data = getCSVData(data.data);
          result.success = true;
          response.random = Math.random().toString(36);
          setBreakdownStates(result.data);
        }
      })
      .catch((err) => {
        result.error = err;
      });

    return result;

  }, [year, indicator, generateQuery, breakdownQuery, breakdownStates]);

  const getRanking = useCallback((period) => {
    return new Promise((resolve) => {

      const result = { success: false };

      if (!indicator._id || year.id === undefined || !statesById.length || !Object.keys(statesByHash).length) {
        return resolve(result);
      }

      const query = generateQuery();

      if (query === rankingQuery) {
        return resolve({
          success: true,
          data: rankingStates,
          random: Math.random().toString(36),
        });
      } else {
        setRankingQuery(query);
      }

      const data = getRankingCSV(statesById, statesByHash, period);
      setRankingStates(data);

      resolve({
        success: true,
        data: data,
        random: Math.random().toString(36),
      });
    });
  }, [indicator, statesById, statesByHash, year, rankingQuery, rankingStates, generateQuery]);

  /**
   * When changing values from date filters.
   *
   * If no data can be fetched, clear the states array and object and
   * trigger a new key for the GeoJSON to render again.
   */
  useEffect(() => {

    // clear old values
    setStatesById([]);
    setStatesByHash({});
    setLegendColors([]);
    setLegendValues([]);
    setMissingStates({});
    setTotalCountry(undefined);
    setTotalState(undefined);
    setTotalESE(undefined);

    // When there is no data, reset values to rerender the map
    if (!indicator._id || year.id === undefined) {
      setStrForGeoJSON(STR_GEOJSON_NO_DATA_PREFIX + Math.random().toString('36'));
      return;
    }

    history.replace({ search: search });

    const query = generateQuery();

    const source = axios.CancelToken.source();
    setLoading(true);
    axios.get(query, { cancelToken: source.token })
      .then((response) => {
        const { data } = response;
        if (data.success && data.data) {
          generateStatesInfo(
            data.data,
            indicator,
            setMissingData,
            setLegendColors,
            setLegendValues,
            setTotalCountry,
            setTotalState,
            setTotalESE,
            setStatesByHash,
            setStatesById,
            setMissingStates
          );
        } else {
          setStatesById([]);
          setStatesByHash({});
        }
        setStrForGeoJSON(Math.random().toString('36'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });

    return (() => {
      source.cancel(D.errors.requestCanceled);
    });
  }, [year, monthOrQuarter, indicator, isMonthSelected, generateQuery, history, search]);

  return (
    <>
    <IndicatorInfo
        getRanking={getRanking}
        getStatesBreakdown={getStatesBreakdown}
        legendColors={legendColors}
        legendValues={legendValues}
        loadingData={loading}
        missingData={missingData}
        missingStates={missingStates}
        statesByHash={statesByHash}
        statesById={statesById}
        strForGeoJSON={strForGeoJSON}
        totalCountry={totalCountry}
        totalState={totalState}
        totalESE={totalESE}
    />
    </>
  );
}

DataContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default DataContainer;
