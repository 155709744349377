import * as UserActions from '../constants/user-constants';
import { API } from 'api/user-api';
import { D } from 'constants/dictionary';
import { displayAlert } from 'actions/admin-actions';
import { interceptor } from "services/interceptor-service";
import * as ALERT_TYPES from 'constants/alert-types';

//Actions for when user is trying to login
export const login = (data) => dispatch => {
  dispatch(loginRequest(data));
  API.login(data)
    .then(response => {
      const token = response.data.token;
      interceptor.addToken(token);
      dispatch(loginSuccess(response))
    })
    .catch((error) => {
      dispatch(loginFailure(error.response, data));
    });
}

const loginRequest = (data) => {
  return {
    type: UserActions.LOGIN_REQUEST,
    data
  }
}

const loginSuccess = (data) => {
  return {
    type: UserActions.LOGIN_SUCCESS,
    data
  }
}

const loginFailure = (e, data) => {
  return {
    type: UserActions.LOGIN_FAILURE,
    error: e,
    data,
  }
}

export const logout = () => dispatch => {
  interceptor.removeToken();
  dispatch(setCurrentUser({}))
}

const setCurrentUser = (user) => {
  return {
    type: UserActions.SET_CURRENT_USER,
    payload: user
  }
}
//Actions when user is trying to recover password
export const recoverPassword = (data) => dispatch => {
  dispatch(recoverPasswordRequest(data));
  API.recoverPassword(data)
    .then(response => {
      dispatch(recoverPasswordSuccess(response))
    })
    .catch((error) => {
      dispatch(recoverPasswordFailure(error.response, data));
    });
}

const recoverPasswordRequest = (data) => {
  return {
    type: UserActions.RECOVER_PASS_REQUEST,
    data
  }
}

const recoverPasswordSuccess = (data) => {
  return {
    type: UserActions.RECOVER_PASS_SUCCESS,
    data
  }
}

const recoverPasswordFailure = (e, data) => {
  return {
    type: UserActions.RECOVER_PASS_FAILURE,
    error: e,
    data,
  }
}

//Actions when user changes password via email
export const changePassword = (data, token, defaultPass) => dispatch => {
  dispatch(changePasswordRequest(data));
  API.changePassword(data, token)
    .then(response => {
      if (defaultPass) {
        dispatch(changePasswordSuccess(response))
        dispatch(login(data))
      } else {
        dispatch(changePasswordSuccess(response))
      }
    })
    .catch((error) => {
      dispatch(changePasswordFailure(error.response));
    });
}

const changePasswordRequest = () => {
  return {
    type: UserActions.CHANGE_PASS_REQUEST,
  }
}

const changePasswordSuccess = () => {
  return {
    type: UserActions.CHANGE_PASS_SUCCESS,
  }
}

const changePasswordFailure = (e) => {
  return {
    type: UserActions.CHANGE_PASS_FAILURE,
    error: e,
  }
}

export const clearState = () => {
  return {
    type: UserActions.CLEAR_USER_STATE
  }
}

// Action to get Users list
export const getUsers = () => dispatch => {
  dispatch(getUsersBegin())
  API.getUsers().then(response => {
    const { data } = response
    if (data && data.success) {
      dispatch(getUsersSuccess(data.data))
    } else {
      dispatch(getUsersSuccess([]))
    }
  }).catch(err => {
    dispatch(getUsersError(err))
  })
}

const getUsersBegin = () => {
  return {
    type: UserActions.GET_USERS_BEGIN
  }
}

const getUsersSuccess = (users) => {
  return {
    type: UserActions.GET_USERS_SUCCESS,
    payload: users
  }
}

const getUsersError = (err) => {
  return {
    type: UserActions.GET_USERS_ERROR,
    payload: err
  }
}

export const getUser = (_id) => dispatch => {
  dispatch(getUserBegin())
  API.getUser(_id).then(response => {
    const { data } = response
    if (data && data.success) {
      dispatch(getUserSuccess(data.data))
    } else {
      dispatch(getUserSuccess([]))
    }
  }).catch(err => {
    dispatch(getUserError(err))
  })
}

const getUserBegin = (_id) => {
  return {
    type: UserActions.GET_USER_BEGIN,
    payload: _id
  }
}

const getUserSuccess = (user) => {
  return {
    type: UserActions.GET_USER_SUCCESS,
    payload: user
  }
}

const getUserError = (err) => {
  return {
    type: UserActions.GET_USER_ERROR,
    payload: err
  }
}


// Action to add User list

export const addUser = (user) => (dispatch) => {
  dispatch(addUserBegin())
  API.addUser(user)
    .then((res) => {
      const { data } = res
      if (data) {
        dispatch(addUserSuccess(data.data))
      } else {
        dispatch(addUserError(new Error()))
      }
    }).catch((err) => {
      dispatch(addUserError(err))
    })
}

export const saveUser = (_id, user) => (dispatch) => {
  dispatch(addUserBegin());
  API.saveUser(_id, user)
    .then((res) => {
      const { data } = res;
      if (data && data.success) {

        dispatch(addUserSuccess(data.data));
      } else {
        dispatch(addUserError(new Error(data.message)));
      }
    })
    .catch((err) => {
      dispatch(addUserError(err));
    });
}

const addUserBegin = () => {
  return {
    type: UserActions.ADD_USER_BEGIN
  }
}
const addUserSuccess = (user) => {
  return {
    type: UserActions.ADD_USER_SUCCESS,
    payload: user
  }
}
const addUserError = (err) => {
  return {
    type: UserActions.ADD_USER_ERROR,
    payload: err
  }
}

export const deleteUser = (_id) => (dispatch) => {
  dispatch(deleteUserSuccess(_id));
  API.deleteUser(_id)
    .then(() => {
      dispatch(displayAlert({
        message: D.users.elimSuccess,
        timeout: 3000,
        type: ALERT_TYPES.SUCCESS,
      }));
    }).catch((err) => {
      if(err.message === D.errors.requestCanceled){
        return;
      }
      let message = D.users.elimDefaultError;
      if (err.response) {
        switch (err.response.status) {
          case 404:
            message = D.users.notFound;
            break;
          default:
            break;
        }
      } else {
        message = D.errors.noConnection;
      }
      dispatch(displayAlert({
        message,
        timeout: 5000,
        type: ALERT_TYPES.DANGER,
      }));
    })
}

const deleteUserSuccess = (_id) => {
  return {
    type: UserActions.DELETE_USER_SUCCESS,
    payload: _id
  }
}

export const clearLoadingUser = (payload) => {
  return {
    type: UserActions.CLEAR_LOADING_USER,
    payload
  }
}


export const clearUserState = (payload) => {
  return {
    type: UserActions.CLEAR_USER_STATE,
    payload
  }
}
