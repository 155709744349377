import React, { useCallback, useEffect, useRef, useState, useContext } from "react";
import { CSVLink } from "react-csv";
import { BarChart } from "react-feather";
import PropTypes from "prop-types";
import ReactTooltip from 'react-tooltip';

// constants
import { D } from 'constants/dictionary';
import {REACT_APP_APP_URL} from './../../../../../constants/routes-constants';

// context
import { DatesContext } from 'providers/DatesProvider';
import { IndicatorContext } from 'providers/IndicatorProvider';

// helpers
import { getPeriod } from 'helpers/get-period';
import { getSpecialTags } from 'helpers/get-special-tags';

// styles
import e from "styles/scenes/theme.module.scss";
import s from "styles/components/table.module.scss";
import t from "styles/components/text.module.scss";

// Components
import { TableBody } from "./Body";
import ChartsContainer from './ChartsContainer';
import { getFormattedValue } from 'helpers/get-formatted-value';

// Assets
const EXCEL = require("assets/excel-file.png");
const PDF = require("assets/pdf.png");
const Spinner = require('assets/xls_spinner.gif');

const TableChart = ({
  getRanking,
  getStatesBreakdown,
  legendColors,
  legendValues,
  missingStates,
  name,
  statesByHash,
  statesById,
  totalState,
  totalCountry,
  totalESE
}) => {
  const [data, setData] = useState({ data: { data: [], headers: [] } });
  const [ranking, setRanking] = useState({ data: { data: [], headers: [] } });
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('');
  const isFirstRender = useRef(true);
  const isFirstRankingRender = useRef(true);
  const csvRef = useRef();
  const rankingRef = useRef();
  const { indicator } = useContext(IndicatorContext);
  const { monthOrQuarter, minMaxYears, year, } = useContext(DatesContext);


  useEffect(() => {
    setPeriod(getPeriod(year, monthOrQuarter, minMaxYears));
  }, [year, minMaxYears, monthOrQuarter]);

  // Trigger click for CSVLink
  const triggerRankingClick = useCallback(() => {
    if (rankingRef.current) {
      rankingRef.current.link.click();
    }
  }, []);

  // Trigger click when ranking data changes
  useEffect(() => {
    if (isFirstRankingRender.current) {
      isFirstRankingRender.current = false;
      return;
    }

    triggerRankingClick();
  }, [ranking, triggerRankingClick]);

  // Get and set CSV ranking data
  const _setRanking = async () => {
    setLoading(true);
    let result = await getRanking(period);

    if (result.success) {
      if(indicator.hasSpecialTags && indicator.tags){
        //Map all tags available in the indicator and save it in var excelMap
        let excelMap = getSpecialTags(indicator.tags)
        //Search on Map element the value that matches with the tag index and replace value for tag name
        result.data.data.forEach((element, index) => {
          if(index === 0) {
            return
          }
          element[1] = excelMap.get(element[1]) || D.info.noTag
        })
      } else{
        //else format each value to be displayed correctly on excel file
        result.data.data.forEach((element, index) =>{
          if(index === 0){
            return
          }
          element[1] = getFormattedValue(element[1], 2, indicator.recordsAsPercentage, indicator.recordsAsCoin)
        })
      }

      if (Object.keys(missingStates).length) {
        Object.keys(missingStates).forEach(key => {
          let missingStateValue = [missingStates[key].state.name, D.info.noData]
          result.data.data.push(missingStateValue)
        })
      }
      
      setRanking(result);
    }
    setLoading(false);
  }

  // Trigger click for CSVLink
  const triggerClick = useCallback(() => {
    if (csvRef.current) {
      csvRef.current.link.click();
    }
  }, []);

  // Trigger click when data changes
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    triggerClick();
  }, [data, triggerClick]);

  // Get and set CSV data for all states with dates
  // eslint-disable-next-line
  const _setData = async () => {
    setLoading(true);
    const result = await getStatesBreakdown();
    if (result.success) {
      setData(result);
    }
    setLoading(false);
  };

  const getPDFHeader = () => {
    return [[D.info.number, D.info.state, indicator.shortName, '']];
  };

  const getPDFHeaderNostates = () => {
    return [[D.general.noDataStates]];
  };

  const getPDFHeaderGlobal = () => {
    return [[D.info.state, indicator.shortName]];
  };

  const exportToPdf = () => {
    const jsPDF = require("jspdf");
    require("jspdf-autotable");

    const pdf = new jsPDF({ orientation: "landscape" });

    let body = [];
    let bodyHead = [];
    let bodyNoStates = [];
    let elementTableNoStates;
    let elementTableGlobal;
    let elementTable;
    let rank = 0;

    const header = data => {
      pdf.setFontSize(14);
      pdf.setTextColor(40);
      pdf.setFontStyle("normal");
      pdf.text(indicator.name, data.settings.margin.left, 10);
    };

    statesById.forEach((s, i) => {
      let data = statesByHash[s];
      let res = {};
      if (i !== -1) {
        if (indicator.hasSpecialTags && indicator.tags) {
          res = indicator.tags.find(tag => {
            return parseInt(tag.index) === data.totalAmount;
          });
          data = { ...data, totalAmount: res ? res.name : D.info.noTag };
        }
        if (i === 0) {
          rank = 0
        }
        if (data.state.type === 'state') {
          rank++;

          elementTable = [rank, data.state.name, getFormattedValue(data.totalAmount, 2, indicator.recordsAsPercentage, indicator.recordsAsCoin)];
          body.push(Object.values(elementTable));
        }
      }
      if (data.state.type !== 'state') {
        elementTableGlobal = [data.state.name, getFormattedValue(data.totalAmount, 2, indicator.recordsAsPercentage, indicator.recordsAsCoin)];
        bodyHead.push(Object.values(elementTableGlobal));
      }
    });

    Object.keys(missingStates).forEach(key => {
      elementTableNoStates = [missingStates[key].state.name];
      bodyNoStates.push(Object.values(elementTableNoStates))
    })


    // Fix style on PDF
    // Global data FED, EST, ESE
    pdf.autoTable({
      columnStyles: {
        0: {cellWidth: 70},
        1: {cellWidth: 30},
      },
      head: getPDFHeaderGlobal(),
      body: bodyHead,
      styles: { valign: "middle" },
      headStyles: { halign: "left", fillColor: [8, 28, 43] },
      beforePageContent: header
    });
    // Ranking data STATES
    pdf.autoTable({
      columnStyles: {
        0: {columnWidth: 10},
        1: {columnWidth: 65},
        2: {columnWidth: 25},
      },
      head: getPDFHeader(),
      body: body,
      styles: { valign: "middle" },
      headStyles: { halign: "left", fillColor: [49, 67, 84] },
      beforePageContent: header,
    });

    // Global data No states
    if (Object.keys(missingStates).length) {
      pdf.autoTable({
        head: getPDFHeaderNostates(),
        body: bodyNoStates,
        styles: { valign: "middle" },
        headStyles: { halign: "left", fillColor: [49, 67, 84]},
        beforePageContent: header
      });
    }

    pdf.autoTable({
      showFoot: 'lastPage',
      footStyles: { halign: "left", fillColor: [8, 28, 43] },
      foot: [[`${D.source.source} ${D.source.oct} - ${REACT_APP_APP_URL}`]]
    });

    pdf.save(`${indicator._id ? indicator.shortName : "mexico"}.pdf`);
  };
  return (
    <section key={indicator._id} className={[s.__table, "__wrapper", e.__fadein].join(" ")}>
      <section className="__row">
        <section className="__col_xs_12">
          <h2 className={[s.__stitle, t.__black].join(" ")}>
            <BarChart size={20} />
            {D.info.ranking}
          </h2>
        </section>
      </section>
      {!indicator.hasSpecialTags &&
      <ChartsContainer
        legendColors={legendColors}
        legendValues={legendValues}
        name={name}
        statesByHash={statesByHash}
        statesById={statesById}
        missingStates={missingStates}
        totalState={totalState}
        totalESE={totalESE}
        totalCountry={totalCountry}
        asPercentage={indicator.recordsAsPercentage}
        asCoin={indicator.recordsAsCoin}
      />}
      <section className={s.__download}>
        <small className={[t.__uppercase, t.__black].join(" ")}>
          {D.info.download}
        </small>
        <button data-tip={D.info.exportRanking} className={s.__data_dw} onClick={_setRanking}>
          <img src={EXCEL} alt={D.info.altExcel} />
        </button>
        <CSVLink
          aria-label={D.info.exportRanking}
          ref={rankingRef}
          filename={`${indicator.shortName}.csv`}
          headers={ranking.data.headers}
          data={ranking.data.data}
        />
        <button data-tip={D.info.exportPDF} className={s.__data_dw} onClick={exportToPdf}>
          <img src={PDF} alt={D.info.altPdf} />
        </button>
        {loading && <img src={Spinner} className={s.__spinner} width={32} height={32} alt="spinner.gif" />}
        <ReactTooltip />
      </section>
      <section className={s.__table_C}>
        <TableBody
          asPercentage={indicator.recordsAsPercentage}
          asCoin={indicator.recordsAsCoin}
          hasSpecialTags={indicator.hasSpecialTags}
          tags={indicator.tags ? indicator.tags : []}
          dataId={statesById}
          dataStates={statesByHash}
          missingStates={missingStates}
          totalName={indicator.shortName || ""}
          totalState={totalState}
          totalCountry={totalCountry}
          totalESE={totalESE}
        />
      </section>
    </section>
  );
};

TableChart.propTypes = {
  getRanking: PropTypes.func.isRequired,
  getStatesBreakdown: PropTypes.func.isRequired,
  legendColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  legendValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  missingStates: PropTypes.object,
  name: PropTypes.string.isRequired,
  statesById: PropTypes.arrayOf(PropTypes.string).isRequired,
  statesByHash: PropTypes.object.isRequired,
  totalState: PropTypes.any,
  totalCountry: PropTypes.any,
  totalESE: PropTypes.any
};

export default TableChart;
