import React from 'react';
import PropTypes from 'prop-types';

// Styles
import picture from 'styles/components/imageUpload.module.scss';

const ImagePreview = (props) => {

  const style = props.fullWidth ? { width: '100%' } : {};
  return (
    <>
      <div className={picture.__container} style={style}>
        <div
          className={picture.__remove}
          onClick={() => props.onRemove(props.image._id)}
        >
          X
        </div>
        <img
          src={props.image.url || props.image.data}
          className={picture.__image}
          alt="preview" />
      </div>
    </>
  );
}

ImagePreview.propTypes = {
  fullWidth: PropTypes.bool,
  image: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    url: PropTypes.string,
    data: PropTypes.string,
  }),
  onRemove: PropTypes.func.isRequired,
}

export default ImagePreview;
