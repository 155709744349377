const january = 'enero';
const february = 'febrero';
const march = 'marzo';
const april = 'abril';
const may = 'mayo';
const june = 'junio';
const july = 'julio';
const august = 'agosto';
const september = 'septiembre';
const october = 'octubre';
const november = 'noviembre';
const december = 'diciembre';
/**
 * Return the corresponding month number.
 * Starting on 0 because that's how 'moment' works.
 *
 * @param {string} month
 */
export const getMonthNumber = (month) => {
  switch (month) {
    case january:
      return 0;
    case february:
      return 1;
    case march:
      return 2;
    case april:
      return 3;
    case may:
      return 4;
    case june:
      return 5;
    case july:
      return 6;
    case august:
      return 7;
    case september:
      return 8;
    case october:
      return 9;
    case november:
      return 10;
    case december:
      return 11;
    default:
      return undefined;
  }
}
