import React from 'react';
import { Edit2, Trash2 } from 'react-feather';
import moment from 'moment';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

// constants
import { ADD_INDICATOR, EDIT_INDICATOR } from 'constants/routes-constants';
import { D } from 'constants/dictionary';
import { tableFormat } from 'constants/date-formats';

// Styles
import styles from 'styles/scenes/admin.module.scss'

// Components
import { AlertModal } from 'scenes/admin/components/AlertModal';
import { FAB } from 'scenes/admin/components/Fab';
import { Loader } from 'components/Loader';
import { TableRow, TableHead, TableCell, TableButton } from 'scenes/admin/components/Table';
import ServerErrors from 'scenes/admin/components/ServerErrors';


const ShowIndicators = ({
  deleteIndicatorAction,
  displayModal,
  error,
  hideModal,
  history,
  loading,
  indicatorsByHash,
  indicatorsById,
  indicator,
  itemsByHash,
  itemsById,
  onItemChange,
  selectedItemId,
  showAlertModal,
}) => {


  return (
    <section className={`${styles.lgt_admin} __wrapper`} style={{ marginBottom: '1rem' }}>
      {loading && <Loader />}
      {showAlertModal && (
        <AlertModal
          title={D.general.alert}
          onContinue={() => deleteIndicatorAction(indicator._id)}
          onCancel={hideModal}
          close={hideModal}
        >
          <>
            <p>{D.indicators.deleteTitle}<strong>{indicator.shortName}</strong>?</p>
            <p>{D.indicators.deleteBody}</p>
          </>
        </AlertModal>
      )}
      <section className={`${styles.lgt_head} __row between_xs`}>
        <h1>{D.indicators.title}</h1>
        <FAB label={D.indicators.create} goTo={ADD_INDICATOR} />
      </section>
      <section className={`${styles.sslgt_head} __row between_xs`}>
        <section className="__col_xs_12">
          <span>{D.indicators.selectItem}</span>
        </section>
        <section className="__col_xs_12">
          <select
            className={`__excel__drop ${styles.__items_dropdown}`}
            value={selectedItemId}
            onChange={onItemChange}
          >
            <option value="">{D.indicators.noItem}</option>
            {itemsById.map((id) => {
              const item = itemsByHash[id];
              if (!item || (item && !item.hasIndicators)) {
                return null;
              }
              return (
                <option key={item._id} value={item._id}>{item.shortName}</option>
              )
            })}
          </select>
        </section>
      </section>
      {error && <ServerErrors errors={[error]} cols={12} />}
      <TableRow>
        <TableHead col="__col_xs_1"><p><strong>#</strong></p></TableHead>
        <TableHead col="__col_xs_2"><p>{D.indicators.save.name}</p></TableHead>
        <TableHead col="__col_xs_2"><p>{D.indicators.item}</p></TableHead>
        <TableHead col="__col_xs_2"><p>{D.general.updatedAt}</p></TableHead>
        <TableHead col="__col_xs_3"><p>{D.general.updatedBy}</p></TableHead>
        <TableHead col="__col_xs_2"><p>{D.general.actions}</p></TableHead>
      </TableRow>
      {indicatorsById.length ? (
        indicatorsById.map((id) => {
          const indicator = indicatorsByHash[id];

          if (!indicator) {
            return null;
          }

          const indicatorName = indicator.itemId ? indicator.itemId.shortName : '';
          const updatedAt = moment.utc(indicator.updatedAt).local().format(tableFormat);
          const updatedBy = (indicator.updatedBy && indicator.updatedBy.name) ? `${indicator.updatedBy.name} ${indicator.updatedBy.lastName}` : '';
          return (
            <TableRow key={id}>
              <TableCell col="__col_xs_1"><p data-tip={indicator.number}>{indicator.number}</p></TableCell>
              <TableCell col="__col_xs_2"><p data-tip={indicator.shortName}> {indicator.shortName}</p></TableCell>
              <TableCell col="__col_xs_2"><p data-tip={indicatorName}>{indicatorName}</p></TableCell>
              <TableCell col="__col_xs_2"><p data-tip={updatedAt}>{updatedAt}</p></TableCell>
              <TableCell col="__col_xs_3">
                <p data-tip={updatedBy}>
                  {updatedBy}
                </p>
              </TableCell>
              <TableCell col="__col_xs_2">
                <TableButton click={() => history.push(`${EDIT_INDICATOR}${id}`)}><Edit2 data-tip={D.general.edit} color="#007AFF" /></TableButton>
                <TableButton click={() => displayModal(indicator._id)}><Trash2 data-tip={D.general.delete} color="#F31C1C" /></TableButton>
              </TableCell>
              <ReactTooltip />
            </TableRow>
          )
        })
      ) : (
        <p>{D.indicators.noData}</p>
      )}
    </section>
  )
}

ShowIndicators.propTypes = {
  deleteIndicatorAction: PropTypes.func.isRequired,
  displayModal: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  indicatorsByHash: PropTypes.object.isRequired,
  indicatorsById: PropTypes.arrayOf(PropTypes.string).isRequired,
  indicator: PropTypes.object.isRequired,
  itemsByHash: PropTypes.object.isRequired,
  itemsById: PropTypes.arrayOf(PropTypes.string).isRequired,
  onItemChange: PropTypes.func.isRequired,
  selectedItemId: PropTypes.string.isRequired,
  showAlertModal: PropTypes.bool.isRequired,
}

export default ShowIndicators;
