import React, { useCallback, useRef } from "react";
import { HorizontalBar } from "react-chartjs-2";
import PropTypes from "prop-types";

// constants
import { D } from "constants/dictionary";

// styles
import b from "styles/components/table.module.scss";
import s from "styles/components/chart.module.scss";
import t from "styles/components/text.module.scss";

// components
import Separator from "components/Separator";
import HeadAmount from "./../../../../../components/HeadAmount";
import { getFormattedValue } from 'helpers/get-formatted-value';

const DOWNLOAD = require("assets/download.png");

const HorizontalBarChart = ({
  chartHeight,
  data,
  name,
  options,
  federalAmount,
  totalState,
  totalESE,
  totalCountry,
  asPercentage,
  asCoin,
  period
}) => {
  const chartRef = useRef(undefined);

  /**
   * Generate base64 image from the canvas and generate an a tag
   * to export.
   */
  const exportImage = useCallback(() => {
    const { current } = chartRef;
    if (!current) {
      return;
    }

    const { chartInstance } = current;

    // Display title before exporting
    chartInstance.options.title.display = true;
    chartInstance.update();

    // Function to execute after the title is displayed
    const getAndExportImage = () => {
      const downloadLink = document.createElement("a");
      downloadLink.style.display = "none";
      downloadLink.setAttribute("href", chartInstance.toBase64Image());
      downloadLink.setAttribute("download", `${name}.png`);
      // Add a tag to the DOM
      document.body.appendChild(downloadLink);
      // Trigger click
      downloadLink.click();
      // Remove a tag
      document.body.removeChild(downloadLink);

      // Hide the title again
      chartInstance.options.title.display = false;
      chartInstance.update();
    };

    setTimeout(getAndExportImage, 500);
  }, [name]);

  if (!Object.keys(data).length) {
    return null;
  }

  return (
    <>
      <header className={[s.__header, "__row"].join(" ")}>
        <small className={[t.__uppercase, t.__gray, "__col_xs_10"].join(" ")}>
          {D.info.period}: {period}
        </small>
        <section className="__col_xs_2 __row middle_xs end_xs">
          <button className={[b.__data_dw].join(" ")} onClick={exportImage}>
            <img src={DOWNLOAD} alt={D.info.altExcel} />
          </button>
        </section>
      </header>
      <section className={[s.__pie, "__row"].join(" ")}>
        <section className={`__col_xs_12 __row ${s.__chart_head}`} style={{ padding: 0,     borderBottom: '1px solid #eaeaea' }}>
          {totalCountry && <section className="__col_xs_12 __col_md_4"><HeadAmount
            labelAmount={D.info.total}
            amount={ getFormattedValue(federalAmount, 2, asPercentage, asCoin)}
          /></section>}
          {totalState && <section className="__col_xs_12 __col_md_4"><HeadAmount
            labelAmount={D.info.totalState}
            amount={getFormattedValue(totalState, 2, asPercentage, asCoin)}
          /></section>}
          {totalESE && <section className="__col_xs_12 __col_md_4"><HeadAmount
            labelAmount={D.info.totalESE}
            amount={getFormattedValue(totalESE, 2, asPercentage, asCoin)}
          /></section>}
        </section>
        <div style={{ height: `${chartHeight}px` }} className="__col_xs_12">
          <HorizontalBar
            ref={chartRef}
            data={data}
            height={chartHeight}
            options={options}
          />
        </div>
      </section>
      <Separator />
    </>
  );
};

HorizontalBarChart.propTypes = {
  chartHeight: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  federalAmount: PropTypes.number.isRequired,
  options: PropTypes.object.isRequired,
  totalState: PropTypes.any,
  totalESE: PropTypes.any,
  totalCountry: PropTypes.any,
  asCoin: PropTypes.bool,
  asPercentage: PropTypes.bool,
  period: PropTypes.any
};

export default HorizontalBarChart;
