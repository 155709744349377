import { API } from 'api/item-api';
import { D } from 'constants/dictionary';
import { displayAlert } from 'actions/admin-actions';
import * as ALERT_TYPES from 'constants/alert-types';
import * as ItemActions from 'constants/item-constants';

/**
 * Action to get all items.
 * Make API call and dispatch the
 * correct actions.
 */
export const getItems = () => dispatch => {
  dispatch(getItemsBegin());
  API.getItems()
    .then(response => {
      const { data } = response;
      if (data && data.success) {
        dispatch(getItemsSuccess(data.data));
      } else {
        dispatch(getItemsSuccess([]));
      }
    })
    .catch(err => {
      dispatch(getItemsError(err));
    });
}

/**
 * Action dispatched to let the state know
 * that an API request has started.
 */
const getItemsBegin = () => {
  return {
    type: ItemActions.GET_ITEMS_BEGIN
  }
}

/**
 * Action dispatched to let the state know
 * that the items were fetched successfully.
 *
 * @param {Array} items Array of objects with the
 * info of each item.
 */
const getItemsSuccess = (items) => {
  return {
    type: ItemActions.GET_ITEMS_SUCCESS,
    payload: items
  }
}

/**
 * Action dispatched to let know the state that
 * there was an error.
 *
 * @param {any} err Error object.
 */
const getItemsError = (err) => {
  return {
    type: ItemActions.GET_ITEMS_ERROR,
    payload: err
  }
}

/**
 * Action to change the position of the items in the state
 * and also in the server.
 *
 * @param {Array} itemsById Array of strings with the ids of
 *   the items ordered.
 * @param {Array} itemsPositions Array of objects with the id
 *   of the items and their new position.
 */
export const reorderItems = (itemsById, itemsPositions) => dispatch => {
  API.reorderItems(itemsPositions)
    .then(() => {
      dispatch(displayAlert({
        message: D.items.reorderSuccess,
        timeout: 3000,
        type: ALERT_TYPES.SUCCESS,
      }));
    })
    .catch((err) => {
      if (err.message === D.errors.requestCanceled) {
        return;
      }
      let message = D.items.reorderError;
      if (!err.response) {
        message = D.errors.noConnection;
      }
      dispatch(displayAlert({
        message,
        timeout: 5000,
        type: ALERT_TYPES.WARNING,
      }));
    });
  dispatch(changeItemsOrder(itemsById, itemsPositions));
}

/**
 * Action dispatched to change the order of the items.
 *
 * @param {Array} itemsById Array of strings with the ids of
 *   the items ordered.
 * @param {Array} itemsPositions Array of objects with the id
 *   of the items and their new position.
 */
const changeItemsOrder = (itemsById, itemsPositions) => {
  return {
    type: ItemActions.REORDER_ITEMS,
    payload: {
      itemsById,
      itemsPositions,
    }
  }
}

/**
 * Action to save a new item.
 *
 * @param {object} item
 */
export const createItem = (item) => (dispatch) => {
  dispatch(saveItemBegin());
  API.createItem(item)
    .then((res) => {
      const { data } = res;
      if (data && data.success) {
        dispatch(createItemSuccess(data.data));
      } else {
        dispatch(saveItemError(new Error(data.message)));
      }
    })
    .catch((err) => {
      dispatch(saveItemError(err));
    });
}

/**
 * Action dispatched to let the state know
 * that an item is about to be saved.
 */
const saveItemBegin = () => {
  return {
    type: ItemActions.SAVE_ITEM_BEGIN
  }
}

/**
 * Action dispatched to let the state know
 * that the item was successfully added.
 *
 * @param {object} item
 */
const createItemSuccess = (item) => {
  return {
    type: ItemActions.SAVE_ITEM_SUCCESS,
    payload: item
  }
}

/**
 * Action to let the state know that there
 * was an error savign the item.
 *
 * @param {object} err
 */
const saveItemError = (err) => {
  return {
    type: ItemActions.SAVE_ITEM_ERROR,
    payload: err
  }
}

/**
 * Action dispatched to change the value of
 * the variablese used to display the loader.
 *
 * @param {object} payload
 */
export const clearLoadingItem = (payload) => {
  return {
    type: ItemActions.CLEAR_LOADING_ITEM,
    payload
  }
}

/**
 * Action to get the data of the item with the
 * given id.
 *
 * @param {string} id
 */
export const findItem = (id) => (dispatch) => {
  dispatch(findItemBegin());
  API.findItem(id)
    .then((res) => {
      const { data } = res;
      if (data.data && data.success) {
        dispatch(findItemSuccess(data.data));
      } else {
        dispatch(findItemError(new Error()));
      }
    })
    .catch((err) => {
      dispatch(findItemError(err));
    });
}

/**
 * Action dispatched to let the state know
 * that an API request to get an item has started.
 */
const findItemBegin = () => {
  return {
    type: ItemActions.FIND_ITEM_BEGIN
  }
}

/**
 * Action dispatched to let the state know
 * that the item was found.
 *
 * @param {object} item Object with fields
 * of the item.
 */
const findItemSuccess = (item) => {
  return {
    type: ItemActions.FIND_ITEM_SUCCESS,
    payload: item
  }
}

/**
 * Action dispatched to let the state know
 * that there was an error.
 *
 * @param {object} err
 */
const findItemError = (err) => {
  return {
    type: ItemActions.FIND_ITEM_ERROR,
    payload: err
  }
}

/**
 * Action to save the edited item.
 *
 * @param {string} id Id of the item to save.
 * @param {object} item New data of the item.
 */
export const saveItem = (id, item) => (dispatch) => {
  dispatch(saveItemBegin());
  API.saveItem(id, item)
    .then((res) => {
      const { data } = res;
      if (data && data.success) {
        dispatch(saveItemSuccess(data.data));
      } else {
        dispatch(saveItemError(new Error(data.message)));
      }
    })
    .catch((err) => {
      dispatch(saveItemError(err));
    });
}

/**
 * Action to let the state know that the item
 * was successfully saved.
 *
 * @param {object} item
 */
const saveItemSuccess = (item) => {
  return {
    type: ItemActions.SAVE_ITEM_SUCCESS,
    payload: item
  }
}

/**
 * Action to delete an item.
 *
 * @param {string} id Id of item to delete
 */
export const deleteItem = (id) => (dispatch) => {
  dispatch(deleteItemSuccess(id));
  API.deleteItem(id)
    .then(() => {
      dispatch(displayAlert({
        message: D.items.elimSuccess,
        timeout: 3000,
        type: ALERT_TYPES.SUCCESS,
      }));
    })
    .catch((err) => {
      if (err.message === D.errors.requestCanceled) {
        return;
      }
      let message = D.items.elimDefaultError;
      if (err.response) {
        switch (err.response.status) {
          case 404:
            message = D.items.notFound;
            break;
          default:
            break;
        }
      } else {
        message = D.errors.noConnection;
      }
      dispatch(displayAlert({
        message,
        timeout: 5000,
        type: ALERT_TYPES.DANGER,
      }));
    })
}

/**
 * Let the state know that an item must be removed.
 *
 * @param {string} id
 */
const deleteItemSuccess = (id) => {
  return {
    type: ItemActions.DELETE_ITEM_SUCCESS,
    payload: id
  }
}
