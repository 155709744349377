import { API } from 'api/indicator-api';
import { D } from 'constants/dictionary';
import { displayAlert } from 'actions/admin-actions';
import * as ALERT_TYPES from 'constants/alert-types';
import * as IndicatorActions from 'constants/indicator-constants';

/**
 * Action to get all the indicators.
 */
export const getIndicators = (itemId) => (dispatch) => {
  dispatch(getIndicatorsBegin());
  API.getIndicators(itemId)
    .then(response => {
      const { data } = response;
      if (data && data.success) {
        dispatch(getIndicatorsSuccess(data.data));
      } else {
        dispatch(getIndicatorsSuccess([]));
      }
    })
    .catch(err => {
      dispatch(getIndicatorsError(err));
    });
}

/**
 * Let the state know that an API request to get
 * the indicators has started.
 */
const getIndicatorsBegin = () => {
  return {
    type: IndicatorActions.GET_INDICATORS_BEGIN
  }
}

/**
 * Let the state know that the indicators were
 * successfully fetched.
 *
 * @param {Array} indicators
 */
const getIndicatorsSuccess = (indicators) => {
  return {
    type: IndicatorActions.GET_INDICATORS_SUCCESS,
    payload: indicators
  }
}

/**
 * Let the state know that there was an error
 * fetching the items.
 *
 * @param {Error} err
 */
const getIndicatorsError = (err) => {
  return {
    type: IndicatorActions.GET_INDICATORS_ERROR,
    payload: err
  }
}

/**
 * Action to create a new indicator.
 *
 * @param {object} indicator Indicator data.
 */
export const createIndicator = (indicator) => (dispatch) => {
  dispatch(saveIndicatorBegin());
  API.createIndicator(indicator)
    .then((res) => {
      const { data } = res;
      if (data.data && data.success) {
        dispatch(createIndicatorSuccess(data.data));
      } else {
        dispatch(saveIndicatorError(new Error()));
      }
    })
    .catch((err) => {
      dispatch(saveIndicatorError(err));
    });
}

/**
 * Let the state know that an indicator is
 * going to to be saved.
 */
const saveIndicatorBegin = () => {
  return {
    type: IndicatorActions.SAVE_INDICATOR_BEGIN
  }
}

/**
 * Let the state know that there was an error
 * saving the indicator.
 *
 * @param {Error} err
 */
const saveIndicatorError = (err) => {
  return {
    type: IndicatorActions.SAVE_INDICATOR_ERROR,
    payload: err
  }
}

/**
 * Let the state know that the indicator was successfully
 * created.
 *
 * @param {object} indicator Indicator data.
 */
const createIndicatorSuccess = (indicator) => {
  return {
    type: IndicatorActions.CREATE_INDICATOR_SUCCESS,
    payload: indicator
  }
}

/**
 * Let the state know that the properties used to let the
 * application know that something is loaded should change.
 *
 * @param {object} payload New values.
 */
export const clearLoadingIndicator = (payload)  => {
  return {
    type: IndicatorActions.CLEAR_LOADING_INDICATOR,
    payload
  }
}

/**
 * Action to get the data of the indicator with the
 * given id.
 *
 * @param {string} id
 */
export const findIndicator = (id) => (dispatch) => {
  dispatch(findIndicatorBegin());
  API.findIndicator(id)
    .then((res) => {
      const { data } = res;
      if (data.data && data.success) {
        dispatch(findIndicatorSuccess(data.data));
      } else {
        dispatch(findIndicatorError(new Error()));
      }
    })
    .catch((err) => {
      dispatch(findIndicatorError(err));
    })
}

/**
 * Action dispatched to let the state know
 * that an API request to get an indicator has started.
 */
const findIndicatorBegin = () => {
  return {
    type: IndicatorActions.FIND_INDICATOR_BEGIN,
  }
}

/**
 * Action dispatched to let the state know
 * that the indicator was found.
 *
 * @param {object} indicator Object with fields
 * of the indicator.
 */
const findIndicatorSuccess = (indicator) => {
  return {
    type: IndicatorActions.FIND_INDICATOR_SUCCESS,
    payload: indicator,
  }
}

/**
 * Action dispatched to let the state know
 * that there was an error.
 *
 * @param {object} err
 */
const findIndicatorError = (err) => {
  return {
    type: IndicatorActions.FIND_INDICATOR_ERROR,
    payload: err
  }
}

/**
 * Action to save the indicator.
 *
 * @param {string} id Save this indicator.
 * @param {object} indicator Data of the indicator.
 */
export const saveIndicator = (id, indicator) => (dispatch) => {
  dispatch(saveIndicatorBegin());
  API.saveIndicator(id, indicator)
    .then((res) => {
      const { data } = res;
      if (data.data && data.success) {
        dispatch(saveIndicatorSuccess(data.data));
      }
    })
    .catch((err) => {
      dispatch(saveIndicatorError(err));
    });
}

/**
 * Let the state know that the indicator was
 * successfully saved.
 *
 * @param {object} indicator Data of the indicator
 */
const saveIndicatorSuccess = (indicator) => {
  return {
    type: IndicatorActions.SAVE_INDICATOR_SUCCESS,
    payload: indicator,
  }
}

/**
 * Action to delete an indicator.
 *
 * @param {string} id Id of the indicator to delete
 */
export const deleteIndicator = (id) => (dispatch) => {
  dispatch(deleteIndicatorSuccess(id));
  API.deleteIndicator(id)
    .then(() => {
      dispatch(displayAlert({
        message: D.indicators.elimSuccess,
        timeout: 3000,
        type: ALERT_TYPES.SUCCESS,
      }));
    })
    .catch((err) => {
      if (err.message === D.errors.requestCanceled) {
        return;
      }
      let message = D.indicators.elimDefaultError;
      if (err.response) {
        switch (err.response.status) {
          case 404:
            message = D.indicators.notFound;
            break;
          default:
            break;
        }
      } else {
        message = D.errors.noConnection;
      }
      dispatch(displayAlert({
        message,
        timeout: 5000,
        type: ALERT_TYPES.DANGER,
      }));
    })
}

/**
 * Let the state know that an item must be removed.
 *
 * @param {string} id
 */
const deleteIndicatorSuccess = (id) => {
  return {
    type: IndicatorActions.DELETE_INDICATOR_SUCCESS,
    payload: id
  }
}

/**
 * Action to update the records of the indicator.
 * This will also delete the old records.
 *
 * @param {string} id The indicator id
 * @param {Array} records Array of objects with data
 *   of each record
 */
export const updateRecords = (id, records) => (dispatch) => {
  dispatch(saveIndicatorBegin());
  API.updateRecords(id, records)
    .then((res) => {
      const { data } = res;
      if (data.success) { // records updated
        dispatch(changeRecordsSuccess());
      } else { // records updated, but error deleting the old ones
        dispatch(saveIndicatorError(new Error(data.message)));
      }
    })
    .catch((err) => {
      dispatch(saveIndicatorError(err));
    });
}

/**
 * Let the state know that the records were successfully updated.
 */
const changeRecordsSuccess = () => {
  return {
    type: IndicatorActions.UPDATE_RECORDS_SUCCESS
  }
}

/**
 * Action to delete the records of the indicator.
 *
 * @param {string} id Id of the indicator.
 */
export const deleteRecords = (id) => (dispatch) => {
  dispatch(saveIndicatorBegin());
  API.deleteRecords(id)
    .then((res) => {
      const { data } = res;
      if (data.success) {
        dispatch(changeRecordsSuccess());
      } else {
        dispatch(saveIndicatorError(new Error()));
      }
    })
    .catch((err) => {
      dispatch(saveIndicatorError(err));
    })
}

/**
 * Action to get all records of an indicator.
 *
 * @param {string} id Id of the indicator.
 */
export const getRecords = (id) => (dispatch) => {
  dispatch(getRecordsBegin());
  API.getRecords(id)
    .then((res) => {
      const { data } = res;
      if (data.data && data.success) {
        dispatch(getRecordsSuccess(data.data));
      } else {
        dispatch(getRecordsSuccess([]));
      }
    })
    .catch((err) => {
      dispatch(getRecordsError(err));
    });
}

/**
 * Let the state know that a request to get the
 * records of an indicator has started.
 */
const getRecordsBegin = () => {
  return {
    type: IndicatorActions.FIND_RECORDS_BEGIN
  }
}

/**
 * Let the state know that the request was successful.
 *
 * @param {Array} data Array of objects with all records.
 */
const getRecordsSuccess = (data) => {
  return {
    type: IndicatorActions.FIND_RECORDS_SUCCESS,
    payload: data
  }
}

/**
 * Let the state know that there was an error finding
 * the records.
 *
 * @param {any} err Request error
 */
const getRecordsError = (err) => {
  return {
    type: IndicatorActions.FIND_RECORDS_ERROR,
    payload: err
  }
}

/**
 * Let the state know that a new item has been selected.
 *
 * @param {string} id Item id
 */
export const setSelectedIndicatorsItemId = (id) => {
  return {
    type: IndicatorActions.SET_INDICATORS_ITEM_ID,
    payload: id
  }
}
