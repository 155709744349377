import React from "react";
import { PropTypes } from 'prop-types';

// constants
import { D } from "constants/dictionary";

// styles
import styles from "./styles.module.scss";

const EXCEL_ICON = require("assets/excel-file.png");

export const DropzoneInfo = ({ files, onChangeHandler }) => {
  return (
    <>
      <section className={styles.lgt_dropdown}>
        <section className={styles.lgt_dropzone}>
          <div>
            <label className={styles.lgt_dropzone__input} htmlFor="excel">
              <input
                type="file"
                id="excel"
                accept=".xlsx"
                onClick={e => (e.target.value = "")}
                onChange={onChangeHandler}
              />
            </label>
            <small className={styles.lgt_dropzone__label}>
              {D.indicators.data.dropzoneInfo}
            </small>
          </div>
        </section>
        {files === null || files.length === 0 ? null :  (
          <aside
            style={{ textAlign: "center" }}
            className={styles.lgt_dropzone__summary}
          >
            <small className={styles.lgt_dropzone__label}>
              {D.indicators.data.excel}
            </small>
            <section className={styles.lgt_dropzone__ul}>
              <img
                width={100}
                src={EXCEL_ICON}
                alt="Excel Icon from the upload file"
              />
            </section>
            <small>{files.name}</small>
          </aside>
        )}
        <small className={styles.lgt_textarea__advice}>
          {D.indicators.data.instructionInfo}
        </small>
      </section>
    </>
  );
};

DropzoneInfo.propTypes = {
  files: PropTypes.any,
  onChangeHandler: PropTypes.func.isRequired,
};
