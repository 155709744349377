import React, { useEffect, useContext } from 'react';
import * as queryString from 'query-string';
import axios from 'axios';
import PropTypes from 'prop-types';

// context
import { ItemContext } from 'providers/ItemProvider';
import { SearchContext } from 'providers/SearchProvider';

// constants
import { API_URL } from 'constants/general-constants';
import { D } from 'constants/dictionary';
import * as QUERY from 'constants/search-query-parameters';

// components
import IndicatorContainer from './IndicatorContainer';

const ItemContainer = ({
  history
}) => {

  const {
    item,
    setError,
    setErrorItem,
    setItem,
    setItems,
    setLoading,
    setLoadingItem,
    strFetchItem,
  } = useContext(ItemContext);

  const {
    setFirstURL,
    setSearch,
  } = useContext(SearchContext);

  // Fetch items
  useEffect(() => {

    // Set the initial search value
    const initialSearchValue = history.location.search;
    setFirstURL(initialSearchValue);
    setSearch(initialSearchValue);

    let didCancel = false;
    const source = axios.CancelToken.source();
    setError('');
    axios.get(`${API_URL}items`)
      .then((res) => {
        if (!didCancel) {
          const data = res.data;
          if (data.success && data.data) {

            setItems(data.data);
            if (data.data.length) {
              const { [QUERY.ITEM_ID]: itemId } = queryString.parse(initialSearchValue);
              if (itemId) { // If item is set in url
                const _item = data.data.find((i) => i._id === itemId);
                if (_item) { // If the item was found
                  setItem(_item);
                } else {
                  setItem({});
                }
              } else { // If no itemId in url, set the first one
                setSearch(queryString.stringify({
                  [QUERY.ITEM_ID]: data.data[0]._id
                }));
                setItem(data.data[0]);
              }
            }
          } else {
            setError(D.errors.noData);
          }
          setLoading(false);
        }
      })
      .catch((err) => {
        if (!didCancel) {
          if (!err.response) {
            setError(D.errors.noConnection);
          } else {
            setError(D.errors.serverError);
          }
          setLoading(false);
        }
      });

    return (() => {
      didCancel = true;
      source.cancel(D.errors.requestCanceled);
    });
  /**
   * Disable warning since this effect should run only when mounting
   * the component.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch data of an item that has no indicators.
   */
  useEffect(() => {

    if (!item._id || item.hasIndicators) {
      return;
    }

    // Helps to not set new values to the state when the component
    // is unmounted
    let didCancel = false;

    const source = axios.CancelToken.source();
    setErrorItem('');
    setLoadingItem(true);
    axios.get(`${API_URL}items/${item._id}`, { cancelToken: source.token })
      .then((res) => {
        if (!didCancel) {
          if (res.data && res.data.success && res.data.data) {
            const { data } = res.data;
            const newItem = {
              ...item,
              coverImage: data.coverImage,
              title: data.title,
              content: data.content,
              sliderImages: data.sliderImages,
              updatedAt: data.updatedAt
            }
            setItem(newItem);
          } else {
            setErrorItem(D.errors.noData);
          }
          setLoadingItem(false);
        }
      })
      .catch((err) => {
        if (!didCancel) {
          if (!err.response) {
            setErrorItem(D.errors.noConnection);
          } else {
            setErrorItem(D.errors.serverError);
          }
          setLoadingItem(false);
        }
      });

    return (() => {
      didCancel = true;
      source.cancel(D.errors.requestCanceled);
    });
  /**
   * Disable warning since this effect should run only when the
   * selected item changes.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item._id, strFetchItem]);

  return (
    <IndicatorContainer
      history={history}
    />
  );

}

ItemContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default ItemContainer;
