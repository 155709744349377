import moment from 'moment';
import { getExcelHeaders } from 'helpers/get-excel-headers';
import { HEADERS } from 'helpers/transform-excel-data';
import { stateCodes } from 'constants/state-codes';

moment.locale('es');

export const transformRecords = (data) => {

  if (!data.length) {
    return Promise.resolve({ rows: [], cols: [] });
  }

  return new Promise((resolve) => {
    let rows = [HEADERS.map((h) => { return { value: h }})];
    const countryRows = [];

    iterateObjectsNonBlocking(data, (row, done) => {
      // If finished checking all rows, return the data of the states and the
      // country at the end
      if (done) {
        rows = [...rows, ...countryRows];
        return resolve({ rows, cols: getExcelHeaders(HEADERS.length) });
      }

      // If the data is about the country, save it in a temp array
      // to append it at the end of the final result
      if (row[2].value === stateCodes.FED.code) {
        countryRows.push(row);
      } else {
        rows.push(row);
      }
    });
  });
}

/**
 * Function that will return a row with the properties
 * needed for the datasheet.
 *
 * @param {object} state Info about the state.
 * @param {Array} breakdown Info about the amount and date.
 */
const generateRow = (state, breakdown) => {
  const { amount, date } = breakdown;
  const { name, code } = state;

  const d = moment(date);
  const year = d.year();
  const month = d.format('MMMM');

  return [ { value: `${code}${year}`}, { value: name }, { value: code }, { value: year }, { value: month }, { value: amount }];
}

/**
 * @param {Array} data Array with all available records.
 * @param {Function} callback Function called after each
 *   state is handled.
 */
const iterateObjectsNonBlocking = (data, callback) => {
  let i = 0;
  const loop = () => {
    if (i < data.length) {

      // Call function to iterate through each record of one state
      generateRowNonBlocking(data[i], (row, done) => {
        if (!done) {
          callback(row, false);
        } else {
          i++;
          setTimeout(loop, 0);
        }
      });

    } else {
      callback(undefined, true);
    }
  }

  loop();
}

/**
 *
 * @param {object} obj All records of one state.
 * @param {Function} callback Function called after
 *   each record is handled.
 */
const generateRowNonBlocking = (obj, callback) => {
  const chunk = 1000;
  let i = 0;
  const { breakdown, state } = obj;
  const loop = () => {
    let cont = chunk;

    while(cont-- && i < breakdown.length) {
      const row = generateRow(state, breakdown[i]);
      callback(row, false);
      i++;
    }

    // Only set the timeout after a complete chunk has been handled
    // or there is no more data to make this process faster
    if (i < breakdown.length) {
      setTimeout(loop, 0);
    } else {
      callback(undefined, true);
    }
  }

  loop();
}
