import React from 'react';
import PropTypes from 'prop-types';

// styles
import i from "styles/components/indicator-info.module.scss";
import styles from 'styles/components/text.module.scss';

const Content = ({
  text,
}) => {

  return (
    <>
      <section className={['__row', i.__content].join(' ')}>
        <section className="__col_xs_12">
          <p className={[styles.__black, styles.__justify].join(' ')}>{text}</p>
        </section>
      </section>
    </>
  );
}

Content.propTypes = {
  text: PropTypes.string.isRequired,
}

export default Content;
