import React from 'react'
import { isMobile } from 'react-device-detect';

// constants
import { D } from 'constants/dictionary';

// Styles
import styles from 'styles/components/orientation.module.scss';

// Assets
const PC = require('assets/computer.svg')
export const Orientation = () => {

  if (!isMobile) {
    return null;
  }

  return (
    <section className={styles.frame}>
      <figure className={styles.holder}>
        <img src={PC} alt={D.general.orientationAlt} />
        <figcaption><p>{D.general.orientation}</p></figcaption>
      </figure>
    </section>
  )
}
