import React, { Component } from 'react'
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import PropTypes from 'prop-types';

// api
import * as Tokens from 'api/item-api';

// actions
import * as ItemActions from 'actions/item-actions';

// constants
import { D } from 'constants/dictionary';

// Components
import ShowItems from './ShowItems';

class ShowItemsContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
      showAlertModal: false,
      selectedItem: {},
    }
  }

  componentDidMount() {
    this.props.getItems();
  }

  componentDidUpdate(prevProps) {
    const loaded = prevProps.loadingItems && !this.props.loadingItems;

    if (!loaded) {
      return;
    }

    const { errorLoadingItems: error } = this.props;

    if (error) {
      if (error.response) {
        this.setState({ error: D.errors.serverError });
      } else {
        this.setState({ error: D.errors.noConnection });
      }
    }
  }

  componentWillUnmount() {
    const { requestCanceled: rc } = D.errors;
    // cancel pending requests
    Tokens.getItemsCT && Tokens.getItemsCT.cancel(rc);
    Tokens.reorderItemsCT && Tokens.reorderItemsCT.cancel(rc);
    Tokens.deleteItemCT && Tokens.deleteItemCT.cancel(rc);
  }

  /**
   * Action called after moving row.
   * Get new positions of the items and save them.
   *
   * @param {number} oldIndex Of the row moved
   * @param {number} newIndex Of the row moved
   */
  onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {

      // Generate a new array with the ids of the items after drag finishes
      const newItemsById = arrayMove(this.props.itemsById, oldIndex, newIndex);

      // Generate array of objects with the new position for each item
      const newPositions = newItemsById.reduce((positions, itemId, index) => {
        return [
          ...positions,
          {
            _id: itemId,
            position: (index + 1) * 10,
          }
        ]
      }, []);

      this.props.reorderItems(newItemsById, newPositions);
    }
  }

  /**
   * If the item has indicators, display alert to confirm
   * before calling action to delete.
   */
  deleteItemAction = (id) => {
    const item = this.props.itemsByHash[id];
    this.setState({
      showAlertModal: true,
      selectedItem: item,
    });
  }

  /**
   * Action used after confirming the alert.
   */
  deleteItemWithId = (id) => {
    this.props.deleteItem(id);
    this.resetState();
  }

  hideAlertModal = () => {
    this.resetState();
  }

  resetState = () => {
    this.setState({
      showAlertModal: false,
      selectedItem: {},
    });
  }

  render() {
    const { itemsById, itemsByHash, loadingItems } = this.props;
    const { error, selectedItem, showAlertModal } = this.state;

    return (
      <ShowItems
        deleteItem={this.deleteItemAction}
        deleteItemWithId={this.deleteItemWithId}
        error={error}
        hideAlertModal={this.hideAlertModal}
        history={this.props.history}
        item={selectedItem}
        itemsById={itemsById}
        itemsByHash={itemsByHash}
        loadingItems={loadingItems}
        onSortEnd={this.onSortEnd}
        showAlertModal={showAlertModal}
      />
    )
  }
}

ShowItemsContainer.propTypes = {
  deleteItem: PropTypes.func.isRequired,
  errorLoadingItems: PropTypes.any,
  getItems: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  itemsById: PropTypes.array.isRequired,
  itemsByHash: PropTypes.object.isRequired,
  loadingItems: PropTypes.bool.isRequired,
  reorderItems: PropTypes.func.isRequired,
}

/**
 * Get data from the store for this component.
 */
const mapStateToProps = (state) => {
  return {
    errorLoadingItems: state.itemReducer.errorLoadingItems,
    itemsByHash: state.itemReducer.itemsByHash,
    itemsById: state.itemReducer.itemsById,
    loadingItems: state.itemReducer.loadingItems,
  }
}

/**
 * Actions to be dispatched to the store.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    deleteItem: (id) => dispatch(ItemActions.deleteItem(id)),
    getItems: () => dispatch(ItemActions.getItems()),
    reorderItems: (itemsById, itemsPositions) => dispatch(ItemActions
      .reorderItems(itemsById, itemsPositions)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowItemsContainer);
