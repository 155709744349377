import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/components/input.module.scss'
// Components
import { Checkbox } from './Checkbox';

const CheckboxField = ({ 
  input, 
  label, 
  meta:{error,submitFailed} }) => {
  return (
    <>
    <Checkbox
      cbName={input.name}
      cbValue={input.value}
      checked={input.value}
      label={label}
      onChange={input.onChange}
    />
    {submitFailed && error && <p className={styles.lgt_input__error}>{error}</p>}
    </>
  )
}

CheckboxField.propTypes = {
  advice: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.bool,
    onChange: PropTypes.func,
  }).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
  }),
}

export default CheckboxField;
