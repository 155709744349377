const AguascalientesCode = 'AGS';
const BajaCaliforniaCode = 'BCA';
const BajaCaliforniaSurCode = 'BCS';
const CampecheCode = 'CAM';
const ChiapasCode = 'CHS';
const ChihuahuaCode = 'CHH';
const CDMXCode = 'CMX';
const CoahuilaCode = 'COH';
const ColimaCode = 'COL';
const DurangoCode = 'DGO';
const GuanajuatoCode = 'GTO';
const GuerreroCode = 'GRO';
const HidalgoCode = 'HGO';
const JaliscoCode = 'JAL';
const MexicoCode = 'MEX';
const MichoacanCode = 'MIC';
const MorelosCode = 'MOR';
const NayaritCode = 'NAY';
const NuevoLeonCode = 'NLN';
const OaxacaCode = 'OAX';
const PueblaCode = 'PUE';
const QueretaroCode = 'QRO';
const QuintanaRooCode = 'QUI';
const SanLuisPotosiCode = 'SLP';
const SinaloaCode = 'SIN';
const SonoraCode = 'SON';
const TabascoCode = 'TAB';
const TamaulipasCode = 'TAM';
const TlaxcalaCode = 'TLA';
const VeracruzCode = 'VER';
const YucatanCode = 'YUC';
const ZacatecasCode = 'ZAC';
const FederalCode = 'FED';
const EstatalCode = 'EST';
const NotEspecified = 'ESE';

const AGUASCALIENTES = 'Aguascalientes';
const BAJA_CALIFORNIA = 'Baja California';
const BAJA_CALIFORNIA_S = 'Baja California Sur';
const CAMPECHE = 'Campeche';
const CHIAPAS = 'Chiapas';
const CHIHUAHUA = 'Chihuahua';
const CIUDAD_DE_MEXICO = 'Ciudad de México';
const COAHUILA = 'Coahuila de Zaragoza';
const COLIMA = 'Colima';
const DURANGO = 'Durango';
const GUANAJUATO = 'Guanajuato';
const GUERRERO = 'Guerrero';
const HIDALGO = 'Hidalgo';
const JALISCO = 'Jalisco';
const ESTADO_DE_MEXICO = 'Estado de México';
const MICHOACAN = 'Michoacán de Ocampo';
const MORELOS = 'Morelos';
const NAYARIT = 'Nayarit';
const NUEVO_LEON = 'Nuevo León';
const OAXACA = 'Oaxaca';
const PUEBLA = 'Puebla';
const QUERETARO = 'Querétaro';
const QUINTANA_ROO = 'Quintana Roo';
const SAN_LUIS_POTOSI = 'San Luis Potosí';
const SINALOA = 'Sinaloa';
const SONORA = 'Sonora';
const TABASCO = 'Tabasco';
const TAMAULIPAS = 'Tamaulipas';
const TLAXCALA = 'Tlaxcala';
const VERACRUZ = 'Veracruz';
const YUCATAN = 'Yucatán';
const ZACATECAS = 'Zacatecas';
const FEDERAL = 'Federal';
const ESTATAL = 'Estatal';
const ENTIDADNOESPECIFICADA = 'Entidad No Especificada';

export const stateCodes = {
  [AguascalientesCode]: {
    code: AguascalientesCode,
    name: AGUASCALIENTES,
  },
  [BajaCaliforniaCode]: {
    code: BajaCaliforniaCode,
    name: BAJA_CALIFORNIA,
  },
  [BajaCaliforniaSurCode]: {
    code: BajaCaliforniaSurCode,
    name: BAJA_CALIFORNIA_S,
  },
  [CampecheCode]: {
    code: CampecheCode,
    name: CAMPECHE,
  },
  [ChiapasCode]: {
    code: ChiapasCode,
    name: CHIAPAS,
  },
  [ChihuahuaCode]: {
    code: ChihuahuaCode,
    name: CHIHUAHUA,
  },
  [CDMXCode]: {
    code: CDMXCode,
    name: CIUDAD_DE_MEXICO,
  },
  [CoahuilaCode]: {
    code: CoahuilaCode,
    name: COAHUILA,
  },
  [ColimaCode]: {
    code: ColimaCode,
    name: COLIMA,
  },
  [DurangoCode]: {
    code: DurangoCode,
    name: DURANGO,
  },
  [GuanajuatoCode]: {
    code: GuanajuatoCode,
    name: GUANAJUATO,
  },
  [GuerreroCode]: {
    code: GuerreroCode,
    name: GUERRERO,
  },
  [HidalgoCode]: {
    code: HidalgoCode,
    name: HIDALGO,
  },
  [JaliscoCode]: {
    code: JaliscoCode,
    name: JALISCO,
  },
  [MexicoCode]: {
    code: MexicoCode,
    name: ESTADO_DE_MEXICO,
  },
  [MichoacanCode]: {
    code: MichoacanCode,
    name: MICHOACAN,
  },
  [MorelosCode]: {
    code: MorelosCode,
    name: MORELOS,
  },
  [NayaritCode]: {
    code: NayaritCode,
    name: NAYARIT,
  },
  [NuevoLeonCode]: {
    code: NuevoLeonCode,
    name: NUEVO_LEON,
  },
  [OaxacaCode]: {
    code: OaxacaCode,
    name: OAXACA,
  },
  [PueblaCode]: {
    code: PueblaCode,
    name: PUEBLA,
  },
  [QueretaroCode]: {
    code: QueretaroCode,
    name: QUERETARO,
  },
  [QuintanaRooCode]: {
    code: QuintanaRooCode,
    name: QUINTANA_ROO,
  },
  [SanLuisPotosiCode]: {
    code: SanLuisPotosiCode,
    name: SAN_LUIS_POTOSI,
  },
  [SinaloaCode]: {
    code: SinaloaCode,
    name: SINALOA,
  },
  [SonoraCode]: {
    code: SonoraCode,
    name: SONORA,
  },
  [TabascoCode]: {
    code: TabascoCode,
    name: TABASCO,
  },
  [TamaulipasCode]: {
    code: TamaulipasCode,
    name: TAMAULIPAS,
  },
  [TlaxcalaCode]: {
    code: TlaxcalaCode,
    name: TLAXCALA,
  },
  [VeracruzCode]: {
    code: VeracruzCode,
    name: VERACRUZ,
  },
  [YucatanCode]: {
    code: YucatanCode,
    name: YUCATAN,
  },
  [ZacatecasCode]: {
    code: ZacatecasCode,
    name: ZACATECAS,
  },
  [FederalCode]: {
    code: FederalCode,
    name: FEDERAL,
  },
  [EstatalCode]: {
    code: EstatalCode,
    name: ESTATAL,
  },
  [NotEspecified]: {
    code: NotEspecified,
    name: ENTIDADNOESPECIFICADA,
  },
}
