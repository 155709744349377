import React, { Component } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { persist, store } from "./store";
import { interceptor } from "./services/interceptor-service";


// Main component
import { Scenes } from "./scenes/index";


class App extends Component {
  constructor() {
    super();
    interceptor.addInterceptor();
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persist}>
          <Scenes />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
