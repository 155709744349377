import React from 'react';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

// styles
import b from 'styles/components/button.module.scss';
import s from 'styles/components/header.module.scss';
import t from 'styles/components/text.module.scss';

const ErrorMessage = ({
  error,
  onClickAction
}) => {

  return (
    error !== D.errors.noData ? (
      <div>
        <button
          onClick={() => onClickAction(Math.random().toString(36))}
          className={[b.__transparent, t.__gray, s.__retry_btn].join(' ')}
        >
          {error} {D.info.tryAgain}
        </button>
      </div>
    ) : <small className={t.__gray}>{error}</small>
  );
}

ErrorMessage.propTypes = {
  error: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
}

export default ErrorMessage;
