import React, { useState } from 'react';
import { AlignLeft } from 'react-feather';
import { connect } from 'react-redux';
import { logout } from 'actions/user-actions'
import PropTypes from 'prop-types';
import { items } from 'constants/admin-options';
import { defaultTitle } from 'helpers/default-title';
import Avatar from 'react-avatar';

import { SUPER } from 'constants/admin-constants'
//Styles
import s from 'styles/components/header.module.scss';
import t from 'styles/components/text.module.scss';

//Components
import Sidebar from 'components/Sidebar'

// constants
import { D } from 'constants/dictionary';

const AdminHeader = ({ path, section, _setPage, user }) => {
  const [menuState, setMenuState] = useState(false);

  const avatarName =  (user.name || '').replace(/\s+/g, '');
  const avatarLastName = (user.lastName || '').replace(/\s+/g, '');
  const closeSidebar = () => {
    setMenuState(false);
  }

  const menuBehaviour = () => {
    setMenuState(!menuState)
  }

  const onSidebarClick = (element) => {
    closeSidebar();
    _setPage(element);
  }

  const renderTitle = () => {

    section = defaultTitle(path);

    return (
      <p className={[t.__gray, t.__uppercase].join(' ')}>
        <strong>{section}</strong>
      </p>
    );
  }

  return (
    <>
      <nav className={s.__nav}>
        <section className={s.__header}>
          <button
            disabled={false}
            onClick={menuBehaviour}
            className={s.__indicators}
            aria-label="Open Menu">
            <AlignLeft size={32} />
          </button>
          <header className={s.__title}>
            <small className={[t.__gray, t.__uppercase, s.__item].join(' ')}>{D.admin.header}</small>
            {renderTitle()}
          </header>
          <figure className={s.__user}>
            <figcaption>
              <small className={[t.__gray, t.__uppercase, t.__right].join(' ')}>
                <strong>{user.role === SUPER ? D.admin.admin : D.admin.contentAdmin}</strong>
              </small>
              <p className={[t.__gray, t.__right].join(' ')}>
                <strong>{user.name + " " + user.lastName}</strong>
              </p>
            </figcaption>
            <Avatar name={avatarName + " " + avatarLastName} className={s.__avatar}  color="#5c6e80" round="60%" size="52" />
          </figure>
        </section>
      </nav>
      {!menuState ? null : <>
        <section className={s.__bg}></section>
        <Sidebar
          closeSidebar={closeSidebar}
          elements={items}
          menuBehaviour={menuBehaviour}
          title={D.admin.header}
          _setPage={onSidebarClick}
          panel={true}
          role={user.role}
        />
      </>
      }
    </>
  )
}
AdminHeader.propTypes = {
  user: PropTypes.object.isRequired,
  _setPage: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  section: PropTypes.string.isRequired,
}
const mapStateToProps = (state) => {
  return {
    user: state.authReducer.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminHeader)

