import React from 'react';
import PropTypes from 'prop-types';

// components
import { Checkbox } from './Checkbox';

const CheckboxGroup = ({
  label,
  options,
  input,
}) => {
  return (
    <>
      <p className="__col_xs_12">{label}</p>
      {options.map((option, index) => {
        return (
          <section key={index} className="__col_xs_3">
            <Checkbox
              cbName={`${input.name}[${index}]`}
              cbValue={option.value}
              checked={input.value.indexOf(option.value) !== -1}
              label={option.label}
              onChange={(event) => {
                const newValue = [...input.value];
                if (event.target.checked) {
                  newValue.push(option.value);
                } else {
                  newValue.splice(newValue.indexOf(option.value), 1);
                }

                return input.onChange(newValue);
              }}
            />
          </section>
        )
      })}
    </>
  );
}

CheckboxGroup.propTypes = {
  label: PropTypes.string,
  options: PropTypes.any,
  input: PropTypes.any,
  meta: PropTypes.any,
}

export default CheckboxGroup;
