export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';


export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const RECOVER_PASS_REQUEST = 'RECOVER_PASS_REQUEST';
export const RECOVER_PASS_SUCCESS = 'RECOVER_PASS_SUCCESS';
export const RECOVER_PASS_FAILURE = 'RECOVER_PASS_FAILURE';

export const CHANGE_PASS_REQUEST = 'CHANGE_PASS_REQUEST';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CHANGE_PASS_FAILURE = 'CHANGE_PASS_FAILURE';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';

// Get Users constants
export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const GET_USER_BEGIN = 'GET_USER_BEGIN';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const ADD_USER_BEGIN = 'ADD_USER_BEGIN';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_ERROR = 'ADD_USER_ERROR';

export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const CLEAR_LOADING_USER = 'CLEAR_LOADING_USER';
export const CLEAR_USER = 'CLEAR_USER'
