import React from 'react'

import styles from 'styles/components/input.module.scss';
import PropTypes, { oneOfType } from "prop-types";

export const Dropdown = ({
  advice,
  input,
  label,
  name,
  children,
  meta: { error, submitFailed }
}) => {
  return (
    <>
      <section className={styles.lgt_input__bound}>
        <label htmlFor={name} className={styles.lgt_input__label}>{label}</label>
        <select
          {...input}
          name={name}
          type="text"
          className={styles.lgt_dropdown}
        >
          {children}
        </select>
      </section>
      {submitFailed && error && <p className={styles.lgt_input__error}>{error}</p>}
      <small className={styles.lgt_input__advice}>{advice}</small>
    </>
  )
}

Dropdown.propTypes = {
  advice: PropTypes.string,
  children: PropTypes.any,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: oneOfType ([
      PropTypes.string,
      PropTypes.bool
    ]),
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
  }),
  name: PropTypes.string,
}
