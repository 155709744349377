// export const minColorRGB = [249, 195, 242];
export const minColorRGB = [255, 229, 204];

// export const maxColorRGB = [108, 3, 169];
export const maxColorRGB = [225, 109, 74];

export const API_URL = process.env.REACT_APP_API_URL;

export const NO_INDICATOR_SELECTED = 'SelectOne';
export const NO_INDICATOR_SELECTED_LABEL = 'Selecciona una opción';

export const STR_GEOJSON_NO_DATA_PREFIX = '__no_data__';
