import axios from 'axios';
import { D } from 'constants/dictionary';

const API_URL = process.env.REACT_APP_API_URL;
export let getItemsCT = undefined;
export let reorderItemsCT = undefined;
export let createItemCT = undefined;
export let findItemCT = undefined;
export let saveItemCT = undefined;
export let deleteItemCT = undefined;

/**
 * GET Request to get all items.
 */
const getItems = () => {
  if (getItemsCT) {
    getItemsCT.cancel(D.errors.requestCanceled);
  }

  getItemsCT = axios.CancelToken.source();
  return axios.get(`${API_URL}admin/items`, { cancelToken: getItemsCT.token });
}

/**
 * PUT Request to set the new order of the items.
 *
 * @param {Array} items
 */
const reorderItems = (items) => {
  if (reorderItemsCT) {
    reorderItemsCT.cancel(D.errors.requestCanceled);
  }

  reorderItemsCT = axios.CancelToken.source();
  const data = JSON.stringify({ items });

  return axios.put(`${API_URL}admin/items/reorder`, data, { cancelToken: reorderItemsCT.token });
}

/**
 * POST Request to save a new item.
 *
 * @param {Object} item
 */
const createItem = (item) => {
  if (createItemCT) {
    createItemCT.cancel(D.errors.requestCanceled);
  }

  createItemCT = axios.CancelToken.source();
  const data = JSON.stringify({ ...item });

  return axios.post(`${API_URL}admin/items`, data, { cancelToken: createItemCT.token });
}

/**
 * GET Request to get the data of an item.
 *
 * @param {string} id
 */
const findItem = (id) => {
  if (findItemCT) {
    findItemCT.cancel(D.errors.requestCanceled);
  }

  findItemCT = axios.CancelToken.source();
  return axios.get(`${API_URL}admin/items/${id}`, { cancelToken: findItemCT.token });
}

/**
 * PUT Request to save the new data of the item
 *
 * @param {string} id Item to save.
 * @param {object} item New data of the item.
 */
const saveItem = (id, item) => {
  if (saveItemCT) {
    saveItemCT.cancel(D.errors.requestCanceled);
  }

  saveItemCT = axios.CancelToken.source();
  const data = JSON.stringify({ ...item });

  return axios.put(`${API_URL}admin/items/${id}`, data, { cancelToken: saveItemCT.token });
}

/**
 * DELETE Request to delete an item.
 *
 * @param {string} id Item to delete
 */
const deleteItem = (id) => {
  if (deleteItemCT) {
    deleteItemCT.cancel(D.errors.requestCanceled);
  }

  deleteItemCT = axios.CancelToken.source();
  return axios.delete(`${API_URL}admin/items/${id}`, { cancelToken: deleteItemCT.token });
}

export const API = {
  createItem,
  deleteItem,
  findItem,
  getItems,
  reorderItems,
  saveItem,
}
