/**
 * Function to return an array that will look like excel headers
 * given the length.
 *
 * @param {number} length Amount of headers
 *
 * @returns {Array} Return array of letters.
 *
 * @example getExcelHeaders(2) returns [{ name: 'A', key: 0 }, { name: 'B', key: 1 }]
 */
export const getExcelHeaders = (length) => {
  let headers = [];
  for (let i = 0; i < length; i++) {
    headers.push({ name: String.fromCharCode(i + 65), key: i });
  }

  return headers;
}
