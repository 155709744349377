import axios from 'axios';
import { D } from 'constants/dictionary';

const API_URL = process.env.REACT_APP_API_URL;
const API_USER = `${API_URL}admin/users`
const API_AUTH_LOGIN = `${API_URL}auth/login`
const API_AUTH_LOGOUT = `${API_URL}auth/logout`
const API_RECOVER_PASSWORD = `${API_URL}auth/recoverPassword`

export let loginCancelToken = undefined;
export let logoutCancelToken = undefined;
export let recoverPasswordCancelToken = undefined;
export let changePasswordCancelToken = undefined;

export let createUserCancelToken = undefined;
export let saveUserCancelToken = undefined;
export let getUserCancelToken = undefined;
export let getUsersCancelToken = undefined;
export let deleteUserCancelToken = undefined;

const login = (data) => {
  if (loginCancelToken) {
    loginCancelToken.cancel(D.errors.requestCanceled);
  }

  loginCancelToken = axios.CancelToken.source();
  return axios.post(API_AUTH_LOGIN, JSON.stringify(data), {cancelToken: loginCancelToken.token })
}

const logout = () => {
  if (logoutCancelToken) {
    logoutCancelToken.cancel(D.errors.requestCanceled);
  }

  logoutCancelToken = axios.CancelToken.source();
  return axios.post(API_AUTH_LOGOUT, {cancelToken: logoutCancelToken.token });
}

const recoverPassword = (data) => {
  if (recoverPasswordCancelToken) {
    recoverPasswordCancelToken.cancel(D.errors.requestCanceled);
  }

  recoverPasswordCancelToken = axios.CancelToken.source();
  return axios.post(API_RECOVER_PASSWORD, JSON.stringify(data), {cancelToken: recoverPasswordCancelToken.token })
}

const changePassword = (data, token) => {
  if (changePasswordCancelToken) {
    changePasswordCancelToken.cancel(D.errors.requestCanceled);
  }

  changePasswordCancelToken = axios.CancelToken.source();
  return axios.post(`${API_RECOVER_PASSWORD}/${token}`, JSON.stringify(data), {cancelToken: changePasswordCancelToken.token })
}

const getUsers = () => {
  if (getUsersCancelToken) {
    getUsersCancelToken.cancel(D.errors.requestCanceled);
  }

  getUsersCancelToken = axios.CancelToken.source();
  return axios.get(API_USER, {cancelToken: getUsersCancelToken.token })
}

const getUser = (_id) => {
  if (getUserCancelToken) {
    getUserCancelToken.cancel(D.errors.requestCanceled);
  }

  getUserCancelToken = axios.CancelToken.source();
  return axios.get(`${API_USER}/${_id}`)
}

/**
 * POST Request to create a new indicator.
 *
 * @param {object} user User data.
 */
const addUser = (user) => {
  if (createUserCancelToken) {
    createUserCancelToken.cancel(D.errors.requestCanceled);
  }
  const data = JSON.stringify({...user})

  createUserCancelToken = axios.CancelToken.source();
  return axios.post(API_USER, data, {cancelToken: createUserCancelToken.token})
    .then(res => { return res })
    .catch(err => {throw err})
}

/**
 * PUT Request to save the new data of the user
 *
 * @param {string} id Indicator to save.
 * @param {object} user New data of the user.
 */

const saveUser = (_id,user) => {
  if (saveUserCancelToken) {
    saveUserCancelToken.cancel(D.errors.requestCanceled);
  }
  const data = JSON.stringify({...user})
  saveUserCancelToken = axios.CancelToken.source();

  return axios.put(`${API_USER}/${_id}`, data, {cancelToken: saveUserCancelToken.token})
    .then(res => { return res })
    .catch(err => {throw err})
}

const deleteUser = (id) => {
  if (deleteUserCancelToken) {
    deleteUserCancelToken.cancel(D.errors.requestCanceled);
  }
  deleteUserCancelToken = axios.CancelToken.source();
  return axios.delete(`${API_USER}/${id}`, {cancelToken: deleteUserCancelToken.token});
}



export const API = {
  login,
  logout,
  recoverPassword,
  changePassword,
  getUsers,
  getUser,
  addUser,
  saveUser,
  deleteUser,
}
