import axios from 'axios';
import { store } from 'store';
import { logout } from 'actions/user-actions'

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

/**
 * Function to configure the interceptor
 * to be used in all requests made by the application.
 */
const addInterceptor = () => {
  getToken();
  axios.interceptors.request.use(
    config => {
      config.headers = headers;
      return config;
    },
    error => Promise.reject(error)
  );

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if(error.response && error.response.status === 401){
        store.dispatch(logout())
      }
      return Promise.reject(error);
    }
  );
}

/**
 * Get the token in the storage and set the header.
 */
const getToken = async () => {
  const token = await localStorage.getItem('token');
  if (token) {
    headers.Authorization = 'Bearer ' + token;
  }
}

/**
 * Add token to header and save it to the
 * local storage.
 *
 * @param {string} token
 */
const addToken = async (token) => {
  headers.Authorization = 'Bearer ' + token;
  await localStorage.setItem('token', token);
}

/**
 * Remove token from header and local storage.
 */
const removeToken = async () => {
  headers.Authorization = undefined;
  await localStorage.removeItem('token');
}

export const interceptor = {
  addInterceptor,
  addToken,
  removeToken
}
