import React from 'react';
import s from './../styles/components/table.module.scss';
import t from './../styles/components/text.module.scss';

import { PropTypes } from 'prop-types';


const HeadAmount = ({ labelAmount, amount }) => {
  return (
    <>
      <section className={[`${s.fedAmount}`].join(" ")}>
        <section className={[`${s.card} __row`]}>
        <small className={t.__white}>
            {labelAmount}
          </small>
          <h2 className={t.__white}>
            <strong>{amount}</strong>
          </h2>
        </section>
      </section>
    </>
  )
}

HeadAmount.propTypes = {
  labelAmount: PropTypes.any,
  amount: PropTypes.any,
  asPercentage: PropTypes.bool,
  asCoin: PropTypes.bool
}

export default HeadAmount;
