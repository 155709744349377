import * as AdminActions from 'constants/admin-constants';

const initialState = {
  alertParams: {
    id: ''
  }
}

export const adminReducer = (state = initialState, action) => {
  switch (action.type) {

    case AdminActions.DISPLAY_ALERT:
      return {
        ...state,
        alertParams: action.payload
      }

    default:
      return state;
  }
}
