import * as React from 'react'
import styles from 'styles/scenes/login/textfield.module.scss'
import { Mail, Eye, EyeOff, Lock } from 'react-feather'
import textfield_helper from 'styles/scenes/login/textfield.module.scss'
import PropTypes from 'prop-types'

const Textfield = (props) => {
  const { error, submitFailed } = props.meta;
  return (
    <section className={styles.__outer}>
      <section
        id={props.id}
        className={styles.__bound}>

        {props.withIcon ? <i className={styles.__icon}>
          {props.icon === 'mail' ? <Mail color="#24282b" /> : props.icon === 'lock' ? <Lock color="#24282b" /> : null}
        </i> : null}

        <input
          {...props.input}
          className={styles.__field}
          type={props.input_type}
          placeholder={props.input_placeholder} />

        {props.viewPassword ?
          <i className={[styles.__icon, styles.__icon__eye].join(' ')} onClick={props.viewPassword_press}>
            {props.viewPassword_icon === "eye" ? <Eye color="#24282b" /> : props.viewPassword_icon === "eyeoff" ? <EyeOff color="#24282b" /> : null}
          </i>
          : null}
      </section>
      {submitFailed && error && <label className={textfield_helper.__err_helper}>{error}</label>}
      {props.children}
    </section>
  )
}

Textfield.propTypes = {
  input_placeholder: PropTypes.string.isRequired,
  input_type: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  withIcon: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  viewPassword_press: PropTypes.func,
  viewPassword_icon: PropTypes.string,
  viewPassword: PropTypes.bool,
  id:PropTypes.string,
  children: PropTypes.func
}

export default Textfield;

