import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";

// constants
import { D } from "constants/dictionary";

// helpers
import * as Validate from "helpers/validations";

// components
import { Input } from "scenes/admin/components/TextInput";
import Button from "components/Button";

const Tags = ({ fields }) => {
  return (
    <>
      {fields.map((tag, index) => {
        return (
          <section key={index} className="__row __col_xs_12">
            <section className="__col_xs_2">
              <Field
                name={`${tag}.index`}
                label={D.indicators.save.sourceNum}
                advice={D.indicators.save.sourceAdvNum}
                component={Input}
                validate={[Validate.isNumber]}
              />
            </section>
            <section className="__col_xs_4">
              <Field
                name={`${tag}.name`}
                label={D.indicators.save.sourceDesc}
                advice={D.indicators.save.sourceAdvDesc}
                component={Input}
                validate={[Validate.maxLength255]}
              />
            </section>

            <section className="__col_xs_2">
              {index > 0 && (
                <Button
                  click={() => fields.remove(index)}
                  label={D.general.remove}
                  bgStyle="danger"
                />
              )}
            </section>
          </section>
        );
      })}

      <section className="__row">
        <section className="__col_xs_3">
          <Button
            click={() => fields.push({})}
            label={D.indicators.save.addTag}
          />
        </section>
      </section>
    </>
  );
};

Tags.propTypes = {
  fields: PropTypes.object
};

export default Tags;
