import React from 'react';

// constants
import { D } from 'constants/dictionary';

// styles
import styles from 'styles/scenes/admin.module.scss'

const FormErrorsMessage = () => {

  return (
    <section className="__row">
      <section className="__col_xs_10 __row end_xs">
        <span className={styles.__lgt_form_errors}>
          {D.validations.defaultError}
        </span>
      </section>
    </section>
  );
}

export default FormErrorsMessage;
