import axios from 'axios';
import { D } from 'constants/dictionary';

const API_URL = process.env.REACT_APP_API_URL;
export let getIndicatorsCT = undefined;
export let createIndicatorCT = undefined;
export let findIndicatorCT = undefined;
export let saveIndicatorCT = undefined;
export let deleteIndicatorCT = undefined;
export let saveRecordsCT = undefined;
export let deleteRecordsCT = undefined;
export let getRecordsCT = undefined;

/**
 * GET Request to get all the indicators.
 */
const getIndicators = (itemId) => {
  if (getIndicatorsCT) {
    getIndicatorsCT.cancel(D.errors.requestCanceled);
  }

  let query = '';
  if (itemId) {
    query = `?itemId=${itemId}`;
  }

  getIndicatorsCT = axios.CancelToken.source();
  return axios.get(`${API_URL}admin/indicators${query}`, { cancelToken: getIndicatorsCT.token });
}

/**
 * POST Request to create a new indicator.
 *
 * @param {object} indicator Indicator data.
 */
const createIndicator = (indicator) => {
  if (createIndicatorCT) {
    createIndicatorCT.cancel(D.errors.requestCanceled);
  }

  const data = JSON.stringify({ ...indicator });

  createIndicatorCT = axios.CancelToken.source();
  return axios.post(`${API_URL}admin/indicators`, data, { cancelToken: createIndicatorCT.token });
}

/**
 * GET Request to get the data of an indicator.
 *
 * @param {string} id
 */
const findIndicator = (id) => {
  if (findIndicatorCT) {
    findIndicatorCT.cancel(D.errors.requestCanceled);
  }

  findIndicatorCT = axios.CancelToken.source();
  return axios.get(`${API_URL}admin/indicators/${id}`, { cancelToken: findIndicatorCT.token });
}

/**
 * PUT Request to save the new data of the indicator
 *
 * @param {string} id Indicator to save.
 * @param {object} indicator New data of the indicator.
 */
const saveIndicator = (id, indicator) => {
  if (saveIndicatorCT) {
    saveIndicatorCT.cancel(D.errors.requestCanceled);
  }

  const data = JSON.stringify({ ...indicator });

  saveIndicatorCT = axios.CancelToken.source();
  return axios.put(`${API_URL}admin/indicators/${id}`, data, { cancelToken: saveIndicatorCT.token });
}

/**
 * DELETE Request to delete an indicator.
 *
 * @param {string} id Indicator to delete
 */
const deleteIndicator = (id) => {
  if (deleteIndicatorCT) {
    deleteIndicatorCT.cancel(D.errors.requestCanceled);
  }

  deleteIndicatorCT = axios.CancelToken.source();
  return axios.delete(`${API_URL}admin/indicators/${id}`, { cancelToken: deleteIndicatorCT.token });
}

/**
 * PUT Request to add new records.
 * This will also delete the old records of the indicator.
 *
 * @param {string} id Id of the indicator
 * @param {Array} records Array of objects with the data
 *   to create new records.
 */
const updateRecords = (id, records) => {
  if (saveRecordsCT) {
    saveRecordsCT.cancel(D.errors.requestCanceled);
  }

  const data = JSON.stringify({ records });
  saveRecordsCT = axios.CancelToken.source();
  return axios.put(`${API_URL}admin/records/${id}`, data, { cancelToken: saveRecordsCT.token });
}

/**
 * Delete the records of this indicator.
 *
 * @param {string} id Id of the indicator.
 */
const deleteRecords = (id) => {
  if (deleteRecordsCT) {
    deleteRecordsCT.cancel(D.errors.requestCanceled);
  }

  deleteRecordsCT = axios.CancelToken.source();
  return axios.delete(`${API_URL}admin/records/${id}`, { cancelToken: deleteRecordsCT.token });
}

/**
 * GET Request to fetch all records of an indicator.
 *
 * @param {string} id Id of the indicator.
 */
const getRecords = (id) => {
  if (getRecordsCT) {
    getRecordsCT.cancel(D.errors.requestCanceled);
  }

  getRecordsCT = axios.CancelToken.source();
  return axios.get(`${API_URL}admin/records/${id}`, { cancelToken: getRecordsCT.token });
}

export const API = {
  deleteIndicator,
  deleteRecords,
  createIndicator,
  findIndicator,
  getIndicators,
  getRecords,
  saveIndicator,
  updateRecords,
}
