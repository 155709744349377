import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import PropTypes from "prop-types";

// api
import { getItemsCT } from "api/item-api";
import * as Tokens from "api/indicator-api";

// constants
import { D } from "constants/dictionary";
import { INDICATORS, EDIT_INDICATOR } from "constants/routes-constants";
import * as ALERT_TYPES from "constants/alert-types";
import * as BREAKDOWN from "constants/breakdown-constants";
import * as FREQUENCIES from "constants/measurement-frequencies";

//helpers
import { excelInformationRequests } from "helpers/excel-information-requests";
// actions
import { displayAlert } from "actions/admin-actions";
import { getItems } from "actions/item-actions";
import * as IndicatorActions from "actions/indicator-actions";

// components
import SaveIndicator from './SaveIndicator';
class SaveIndicatorContainer extends Component {
  constructor(props) {
    super(props);

    const {
      match: {
        path,
        params: { id }
      }
    } = props;
    this.state = {
      editMode: path.includes("edit"),
      selectedId: id,
      serverErrors: [],
      initialState: undefined,
      showChangesModal: false,
      // New states for files
      fileLoaded: false,
      selectedFile: null,
      requestInformation: "",
      csvFile: false,
      cover: {},
      coverRemoved: false
    };
  }

  componentDidMount() {
    this.props.getItems();

    /**
     * If the reducer has data from a previous action that couldn't
     * properly finish.
     */
    if (this.props.saving || this.props.loadingOne) {
      this.props.setSaving({
        saving: -1,
        loadingOne: -1,
        loadingRecords: -1
      });
    }

    /**
     * If editing, load the data of the indicator and populate
     * the fields.
     */
    if (this.state.editMode) {
      const { selectedId } = this.state;
      // Search the indicator
      if (selectedId) {
        this.props.findIndicator(selectedId);
      } else {
        this.displayAlertAndGoBack(
          D.indicators.notFound,
          3000,
          ALERT_TYPES.WARNING
        );
      }
    } else {
      this.setState({ initialState: this.props.formValues });
    }
  }

  /**
   * When the cover image is selected on the window file.
   */
  onDropCover = (pictureFiles, pictureDataURLs) => {
    if (pictureDataURLs.length) {
      this.setState({
        cover: {
          _id: Math.random().toString(36),
          data: pictureDataURLs[pictureDataURLs.length - 1]
        }
      });
    }
  };

  // Verify if content has changed
  hasContentChanged = () => {
    const { initialState, content, cover, pictures } = this.state;
    const { formValues } = this.props;

    const currentContent = this.getCurrentState(
      formValues,
      content,
      cover,
      pictures
    );

    return initialState !== currentContent;
  };

  /**
   * If the cover image is removed, verify if it's the
   * one stored in the api (has url property) and set coverRemoved.
   */
  removeCover = () => {
    const { cover } = this.state;
    let coverRemoved = false;

    if (cover.url) {
      coverRemoved = true;
    }

    this.setState({
      cover: {},
      coverRemoved
    });
  };

  /**
   * When the props change, check if an indicator was being saved.
   */
  didUpdateOnSave = prevProps => {
    const saved = prevProps.saving === 1 && this.props.saving === 0;

    if (!saved) {
      return;
    }

    const {
      errorSaving,
      displayAlert,
      history,
      selectedIndicator
    } = this.props;

    const serverErrors = [];
    if (!errorSaving) {
      const { editMode } = this.state;
      displayAlert({
        message: editMode
          ? D.indicators.save.saveSuccess
          : D.indicators.save.createSuccess,
        timeout: 3000,
        type: ALERT_TYPES.SUCCESS
      });
      history.replace({
        pathname: `${EDIT_INDICATOR}${selectedIndicator._id}/data`,
        state: {
          indicator: selectedIndicator
        }
      });
    } else {
      const { response } = errorSaving;
      if (response) {
        switch (response.status) {
          case 404:
            if (response.data.error.includes("Item")) {
              serverErrors.push(D.indicators.save.itemNotFound);
            } else {
              this.displayAlertAndGoBack(
                D.indicators.notFound,
                5000,
                ALERT_TYPES.WARNING
              );
            }
            break;
          case 409:
            response.data.errors.forEach(e => {
              if (e.name) {
                serverErrors.push(D.indicators.save.nameExists);
              }

              if (e.shortName) {
                serverErrors.push(D.indicators.save.shortNameExists);
              }

              if (e.item) {
                serverErrors.push(D.indicators.save.itemError);
              }
            });
            break;
          default:
            serverErrors.push(D.errors.serverError);
        }
      } else {
        serverErrors.push(D.errors.noConnection);
      }

      this.setState({ serverErrors });
    }
  };

  displayAlertAndGoBack = (message, timeout, type) => {
    this.props.displayAlert({
      message,
      timeout,
      type
    });
    this.props.history.replace(INDICATORS);
  };

  /**
   * If props change, check if the indicator was being loaded.
   */
  didUpdateOnLoad = prevProps => {
    const loaded = prevProps.loadingOne === 1 && this.props.loadingOne === 0;

    if (!loaded) {
      return;
    }

    const { errorLoadingOne } = this.props;
    if (!errorLoadingOne) {
      const indicator = this.props.selectedIndicator;
      const {
        itemId,
        name,
        shortName,
        introduction,
        aboutIndicator,
        learnMore,
        definition,
        calculationMethod,
        measurementFrequency,
        geographicBreakdown,
        recordsAsPercentage,
        recordsAsCoin,
        sources,
        hasSpecialTags,
        tags,
        specialTreatment,
        indicatorWeaknesses,
        requestInformation,
        coverImage
      } = indicator;

      const { formula, numerator, denominator } = calculationMethod || {};

      let frequencies = [];
      Object.keys(measurementFrequency).forEach(key => {
        if (measurementFrequency[key]) {
          frequencies.push(key);
        }
      });

      let breakdown = [];
      Object.keys(geographicBreakdown).forEach(key => {
        if (geographicBreakdown[key]) {
          breakdown.push(key);
        }
      });

      const initObject = {
        itemId: itemId && itemId._id,
        name,
        shortName,
        introduction,
        aboutIndicator,
        learnMore,
        coverImage: !coverImage ? '' : coverImage,
        definition,
        formula,
        numerator,
        denominator,
        frequencies,
        geographicBreakdown: breakdown,
        recordsAsPercentage,
        recordsAsCoin,
        sources: sources.length ? sources : [''],
        hasSpecialTags,
        tags: tags ? tags : [],
        treatment: specialTreatment,
        problems: indicatorWeaknesses,
        requestInformation: requestInformation.length
          ? requestInformation
          : [""]
      };

      this.props.initialize(initObject);
      this.setState({ initialState: JSON.stringify(initObject) });
    } else {
      let message = D.errors.loadInfo;
      const { response } = errorLoadingOne;
      if (response) {
        if (response.status === 404) {
          message = D.indicators.notFound;
        }
      }
      this.displayAlertAndGoBack(message, 5000, ALERT_TYPES.WARNING);
    }

    // Check if indicator contains request information data
    if (
      this.props.selectedIndicator.requestInformation &&
      this.props.selectedIndicator.requestInformation.length
    ) {
      this.setState({ csvFile: true, requestInformation: this.props.selectedIndicator.requestInformation });
    }
    // Check if indicator contains request information data
    if (this.props.selectedIndicator.coverImage) {
      this.setState({ cover: this.props.selectedIndicator.coverImage });
    }
  };

  // Adding validation to remove excel on file
  updateExcel = () => {
    this.setState({
      csvFile: false,
      selectedFile: [],
      requestInformation: []
    });
  };

  componentDidUpdate(prevProps) {
    this.didUpdateOnSave(prevProps);
    this.didUpdateOnLoad(prevProps);
  }

  componentWillUnmount() {
    const { requestCanceled: rc } = D.errors;
    // cancel pending requests
    getItemsCT && getItemsCT.cancel(rc);
    Tokens.findIndicatorCT && Tokens.findIndicatorCT.cancel(rc);
    Tokens.createIndicatorCT && Tokens.createIndicatorCT.cancel(rc);
    Tokens.saveIndicatorCT && Tokens.saveIndicatorCT.cancel(rc);
  }

  onSubmit = values => {
    const { formValues, selectedIndicator, history } = this.props;
    const { selectedFile, cover, coverRemoved } = this.state;

    // there were no changes on the form
    if (this.state.initialState === formValues && !selectedFile) {
      history.replace({
        pathname: `${EDIT_INDICATOR}${selectedIndicator._id}/data`,
        state: {
          indicator: selectedIndicator
        }
      });
      return;
    }

    const {
      itemId,
      name,
      shortName,
      introduction,
      aboutIndicator,
      definition,
      formula,
      numerator,
      denominator,
      frequencies,
      geographicBreakdown,
      recordsAsPercentage,
      recordsAsCoin,
      sources,
      treatment,
      problems,
      learnMore,
      hasSpecialTags,
      tags
    } = values;
    const { requestInformation } = this.state;
    // Add only the sources that are not empty
    const sourcesArray = sources.reduce((arr, s) => {
      const newSource = s.trim();
      if (newSource.length) {
        return [...arr, newSource];
      }
      return arr;
    }, []);

    const tagsArray = tags.reduce((s, a) =>{

      if(Object.entries(a).length === 0 || !a.hasOwnProperty('index') || !a.hasOwnProperty('name')){
        return s
      }
      const currentIndex = a.index.trim()
      const currentValue = a.name.trim()
      if(!currentIndex.length || !currentValue.length){
        return s
      }
      s.push(a);
      return s;
    },[]);

    // Create request object
    let indicator = {
      name: name.trim(),
      shortName: shortName.trim(),
      frequencies: {
        [FREQUENCIES.ANNUAL_PARAM]:
          frequencies && frequencies.includes(FREQUENCIES.ANNUAL_PARAM),
        [FREQUENCIES.QUARTERLY_PARAM]:
          frequencies && frequencies.includes(FREQUENCIES.QUARTERLY_PARAM),
        [FREQUENCIES.MONTHLY_PARAM]:
          frequencies && frequencies.includes(FREQUENCIES.MONTHLY_PARAM)
      },
      geographicBreakdown: {
        [BREAKDOWN.FEDERAL_PARAM]:
          geographicBreakdown &&
          geographicBreakdown.includes(BREAKDOWN.FEDERAL_PARAM),
        [BREAKDOWN.STATE_PARAM]:
          geographicBreakdown &&
          geographicBreakdown.includes(BREAKDOWN.STATE_PARAM),
        [BREAKDOWN.MUNICIPAL_PARAM]:
          geographicBreakdown &&
          geographicBreakdown.includes(BREAKDOWN.MUNICIPAL_PARAM)
      },
      recordsAsPercentage,
      recordsAsCoin
    };

    if (itemId) {
      indicator.itemId = itemId;
    }

    // If array is not empty, add it to indicator
    if (sourcesArray.length) {
      indicator.sources = sourcesArray;
    }

    if(tagsArray.length){
      indicator.tags = tagsArray
    }

    const calculationMethod = {};

    const formulaToSave = formula && formula.trim();
    if (formulaToSave) {
      calculationMethod.formula = formulaToSave;
    }

    const numeratorToSave = numerator && numerator.trim();
    if (numeratorToSave) {
      calculationMethod.numerator = numeratorToSave;
    }

    const denominatorToSave = denominator && denominator.trim();
    if (denominatorToSave) {
      calculationMethod.denominator = denominatorToSave;
    }

    // Add the object with non empty values
    indicator.calculationMethod = calculationMethod;

    indicator.hasSpecialTags = hasSpecialTags

    const aboutIndicatorToSave = aboutIndicator && aboutIndicator.trim();
    if (aboutIndicator) {
      indicator.aboutIndicator = aboutIndicatorToSave;
    }

    const definitionToSave = definition && definition.trim();
    if (definitionToSave) {
      indicator.definition = definitionToSave;
    }

    const treatmentToSave = treatment && treatment.trim();
    if (treatmentToSave) {
      indicator.treatment = treatmentToSave;
    }

    const problemsToSave = problems && problems.trim();
    if (problemsToSave) {
      indicator.problems = problemsToSave;
    }

    const learnMoreToSave = learnMore && learnMore.trim();
    if (learnMore) {
      indicator.learnMore = learnMoreToSave;
    }

    const coverToSave = cover;
    if (cover) {
      indicator.cover = coverToSave;
    }

    if (requestInformation) {
      indicator.requestInformation = requestInformation;
    }

    const introductionToSave = introduction && introduction.trim();
    if (introduction) {
      indicator.introduction = introductionToSave;
    }
    // Edit/Create indicator
    this.setState({ serverErrors: [] });
    const { selectedId, editMode } = this.state;
    if (editMode) {
      indicator = {
        ...indicator,
        coverRemoved
      }
    }
    if (selectedId && editMode) {
      this.props.saveIndicator(selectedId, indicator);
    } else {
      this.props.createIndicator(indicator);
    }
  };

  onCancelChanges = () => {
    const { formValues, history } = this.props;
    // there were no changes on the form
    if (this.state.initialState === formValues) {
      history.replace(INDICATORS);
    } else {
      this.setState({ showChangesModal: true });
    }
  };

  hideChangesModal = () => this.setState({ showChangesModal: false });

  onAcceptChanges = () => this.props.history.replace(INDICATORS);

  // Adding Handler on document upload
  onChangeHandler = event => {
    let fileObj = event.target.files ? event.target.files[0] : "";
    this.setState({
      fileLoaded: true,
      selectedFile: event.target.files[0]
    });
    excelInformationRequests(fileObj, (err, resp) => {
      if (err) {
        this.setState({
          loadedFile: "",
          fileError: D.indicators.data.readError
        });
      } else {
        this.setState({
          requestInformation: resp
        });
      }
    });
  };

  render() {
    const {
      editMode,
      serverErrors,
      showChangesModal,
      selectedFile,
      csvFile,
      cover
    } = this.state;
    
    const {
      displayTags,
      handleSubmit,
      itemsByHash,
      itemsById,
      loadingItems,
      loadingOne,
      saving,
      submitFailed,
      selectedIndicator
    } = this.props;

    let requestInformation = this.props.selectedIndicator.requestInformation

    return (
      <>
        <SaveIndicator
          onChangeHandler={this.onChangeHandler}
          csvData={ requestInformation ? (requestInformation.length ? JSON.parse(requestInformation[0]) : undefined) : undefined }
          displayTags= {displayTags}
          editMode={editMode}
          files={selectedFile}
          handleSubmit={handleSubmit}
          hideChangesModal={this.hideChangesModal}
          itemsByHash={itemsByHash}
          itemsById={itemsById}
          loading={loadingItems || !!saving || !!loadingOne}
          onAcceptChanges={this.onAcceptChanges}
          onCancelChanges={this.onCancelChanges}
          onSubmit={this.onSubmit}
          serverErrors={serverErrors}
          showChangesModal={showChangesModal}
          submitFailed={submitFailed}
          csvName={selectedIndicator.shortName}
          csvFile={csvFile}
          updateExcel={this.updateExcel}
          cover={cover}
          onDropCover={this.onDropCover}
          removeCover={this.removeCover}
        />
      </>
    );
  }
}

SaveIndicatorContainer.propTypes = {
  createIndicator: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  displayTags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  errorLoadingOne: PropTypes.any,
  errorSaving: PropTypes.any,
  findIndicator: PropTypes.func.isRequired,
  formValues: PropTypes.string.isRequired,
  getItems: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired,
  itemsByHash: PropTypes.object.isRequired,
  itemsById: PropTypes.array.isRequired,
  loadingItems: PropTypes.bool.isRequired,
  loadingOne: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  saveIndicator: PropTypes.func.isRequired,
  saving: PropTypes.number.isRequired,
  selectedIndicator: PropTypes.object.isRequired,
  setSaving: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: "addIndicator",
  initialValues: {
    sources: [""],
    frequencies: [],
    geographicBreakdown: [],
    recordsAsPercentage: false,
    recordsAsCoin: false,
    requestInformation: [],
    hasSpecialTags: 'false',
    tags: ['']
  }
});

const mapStateToProps = state => {
  const selector = formValueSelector("addIndicator");
  return {
    displayTags: selector(state, 'hasSpecialTags'),
    formValues: JSON.stringify(selector(state,
      'itemId',
      'name',
      'shortName',
      'learnMore',
      'aboutIndicator',
      'introduction',
      'definition',
      'formula',
      'numerator',
      'denominator',
      'frequencies',
      'geographicBreakdown',
      'recordsAsPercentage',
      'recordsAsCoin',
      'sources',
      'treatment',
      'problems',
      'requestInformation',
      'hasSpecialTags',
      'tags',
      'coverImage'
    )),
    errorLoadingOne: state.indicatorReducer.errorLoadingOne,
    errorSaving: state.indicatorReducer.errorSaving,
    itemsByHash: state.itemReducer.itemsByHash,
    itemsById: state.itemReducer.itemsById,
    loadingOne: state.indicatorReducer.loadingOne,
    loadingItems: state.itemReducer.loadingItems,
    selectedIndicator: state.indicatorReducer.selected,
    saving: state.indicatorReducer.saving
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createIndicator: indicator =>
      dispatch(IndicatorActions.createIndicator(indicator)),
    displayAlert: alert => dispatch(displayAlert(alert)),
    findIndicator: id => dispatch(IndicatorActions.findIndicator(id)),
    getItems: () => dispatch(getItems()),
    saveIndicator: (id, indicator) =>
      dispatch(IndicatorActions.saveIndicator(id, indicator)),
    setSaving: payload =>
      dispatch(IndicatorActions.clearLoadingIndicator(payload))
  };
};

export default compose(
  form,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SaveIndicatorContainer);
