import React from 'react'
import ImageUploader from 'react-images-upload-disabled';
import './styles.scss'
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

const MAX_SIZE = 5300000 ; // Aprox 5 MB

export const ImageUploaderComp = (props) => {
  return (
    <>
      <ImageUploader
        buttonClassName="lgt_image_uploader__button"
        className="lgt_image_uploader"
        buttonText={props.label}
        onChange={props.onChange}
        imgExtension={['.jpg', '.jpeg', '.png', '.gif']}
        maxFileSize={MAX_SIZE}
        withIcon={false}
        withLabel={false}
        disabled={props.disabled}
        fileTypeError={D.items.save.typeError}
        fileSizeError={D.items.save.sizeError}
        singleImage
      />
      <small className="lgt_image_uploader__label">{props.advice}</small>
    </>
  )
}

ImageUploaderComp.propTypes = {
  onChange: PropTypes.func,
  advice: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
}
