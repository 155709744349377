import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withAlert } from 'react-alert';
import PropTypes from 'prop-types'

// actions
import { logout, getUser } from 'actions/user-actions'

// constants
import * as ROUTES from 'constants/routes-constants';
import { items } from 'constants/admin-options';

//Components
import AdminHeader from './components/AdminHeader'

// Adding Scenes
import AddIndicator from './scenes/indicators/save';
import AddItem from './scenes/items/save';
import AddUser from './scenes/users/save';
import Indicators from './scenes/indicators';
import IndicatorData from './scenes/indicators/data';
import Items from './scenes/items';
import Users from './scenes/users/';

class AdminPanel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      section: '',
    }
  }

  isLogged = () => {
    const isLoggedIn = !!this.props.user._id;
    if (!isLoggedIn) {
      this.props.history.push(ROUTES.LGT_ADMIN);
    }

    return isLoggedIn;
  }

  _setPage = (selectedItem) => {
    if (selectedItem.route === ROUTES.LGT_ADMIN) {
      this.props.logout();
      this.props.history.push(ROUTES.LGT_ADMIN)
    }

    if (selectedItem.route === ROUTES.REACT_WP_ADMIN) {
      window.open(ROUTES.REACT_APP_WP_ADMIN_URL, '_blank' );
    }

    if (selectedItem.route === ROUTES.REACT_APP_ANALYTICS_ADMIN_URL) {
      window.open(ROUTES.REACT_APP_ANALYTICS_ADMIN_URL, '_blank' );
    }

    if (selectedItem.route === ROUTES.REACT_APP_APP_URL) {
      window.open(ROUTES.REACT_APP_APP_URL, '_blank' );
    }

    this.setState({"section": selectedItem.shortName})
  }

  componentDidMount() {
    const isLoggedIn = this.isLogged();

    const { location: { pathname }, match: { path } } = this.props;
    const isIndex = pathname === path || pathname === `${path}/`;
    if (isLoggedIn && isIndex) {
      this.props.history.push(items[0].route);
    }
  }

  componentDidUpdate(prevProps) {
    this.isLogged();

    const {
      message,
      id,
      timeout,
      type,
    } = this.props.alertParams;
    if (id !== prevProps.alertParams.id) {
      this.props.alert.show(message, {
        timeout,
        type,
      });
    }
  }


  render() {
    return (
      <>
        <AdminHeader
          path={this.props.location.pathname}
          section={this.state.section}
          _setPage={this._setPage}
          user={this.props.user}
          history={this.props.history}
          logout={this.props.logout}
        />
        <>
          <Route exact path={ROUTES.INDICATORS} component={Indicators} />
          <Route exact path={ROUTES.ITEMS} component={Items} />
          <Route exact path={ROUTES.ADD_ITEM} component={AddItem} />
          <Route path={`${ROUTES.EDIT_ITEM}:id`} component={AddItem} />
          <Route exact path={ROUTES.ADD_INDICATOR} component={AddIndicator} />
          <Route exact path={`${ROUTES.EDIT_INDICATOR}:id`} component={AddIndicator} />
          <Route exact path={`${ROUTES.EDIT_INDICATOR}:id/data`} component={IndicatorData} />
          <Route exact path={ROUTES.ADD_USER} component={AddUser} />
          <Route path={`${ROUTES.EDIT_USER}:id`} component={AddUser} />
          <Route exact path={ROUTES.USERS} component={Users} />
        </>
      </>
    )
  }
}

AdminPanel.propTypes = {
  alert: PropTypes.object.isRequired,
  alertParams: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    alertParams: state.adminReducer.alertParams,
    user: state.authReducer.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
    getUser: (_id) => dispatch(getUser(_id))
  }
}

export default compose(
  withAlert(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AdminPanel);
