/**
 *
 * @param {number} tags Special tags in indicator to store in Map
 */
export const getSpecialTags = tags => {
  let specialTagsMap = new Map();
  tags.forEach(tag => {
    specialTagsMap.set(parseInt(tag.index), tag.name);
  });
  return specialTagsMap;
};
