import * as UserActions from 'constants/user-constants'

const initialState = {
  // Getting users
  errorGettingUsers: undefined,
  gettingUsers: false,
  users: [],
  // Adding Users
  savingUser: 0,
  errorSavingUser: undefined,
  newUser: {}
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    //Get all users actions
    case UserActions.GET_USERS_BEGIN:
      return {
        ...state,
        errorGettingUsers: undefined,
        gettingUsers: true
      }
    case UserActions.GET_USERS_SUCCESS:
      return {
        ...state,
        gettingUsers: false,
        users: action.payload
      }
    case UserActions.GET_USERS_ERROR:
      return {
        ...state,
        errorGettingUsers: action.payload,
        gettingUsers: false
      }

    case UserActions.GET_USER_BEGIN:
      return {
        ...state,
        selectedUser: {},
        loadingOneUser: 1,
        errorLoadingOneUser: undefined,
      }
    case UserActions.GET_USER_SUCCESS:
      return {
        ...state,
        selectedUser: action.payload,
        loadingOneUser: 0,
      }
    case UserActions.GET_USER_ERROR:
      return {
        ...state,
        loadingOneUser: 0,
        errorLoadingOneUser: action.payload,
      }

    // Adding Users
    case UserActions.ADD_USER_BEGIN:
      return {
        ...state,
        savingUser: 1,
        errorSavingUser: undefined
      }
    case UserActions.ADD_USER_SUCCESS: {
      const user = action.payload
      return {
        ...state,
        savingUser: 0,
        newUser: { ...state.newUser, user }
      }
    }
    case UserActions.DELETE_USER_SUCCESS: {
      // eslint-disable-next-line no-unused-vars
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      }
    }

    case UserActions.ADD_USER_ERROR:
      return {
        ...state,
        savingUser: 0,
        errorSavingUser: action.payload
      }


    case UserActions.CLEAR_USER: {
      const { payload } = action;
      return {
        ...state,
        savingUser: payload.savingUser
      }
    }
    default:
      return state
  }
}
