import React, { useEffect, useState } from 'react';
import axios from 'axios';

// constants
import { API_URL } from 'constants/general-constants';
import { D } from 'constants/dictionary';

// components
import SharedUrl from './components/SharedUrl';

const SharedUrlContainer = (props) => {
  const [error, setError] = useState(false);

  /**
   * Make API call to find and redirect to the URL that matches
   * the code given in the url.
   *
   * If there are any errors, render error page.
   *
   * If no code is provided go to main page.
   */
  useEffect(() => {
    const { match: { params: { code } } } = props;
    const source = axios.CancelToken.source();

    axios.get(`${API_URL}url/${code}`, { cancelToken: source.token })
      .then((res) => {
        if (res.data.data) {
          window.location.href = res.data.data;
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });

    return (() => {
      source.cancel(D.errors.requestCanceled);
    });

  /**
   * Don't care about the props updating since only
   * the code is required when mounting the component.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = () => {
    window.location.href = '/';
  }

  return (
    <SharedUrl
      error={error}
      redirect={redirect}
      {...props}
    />
  );
}

export default SharedUrlContainer;
