import React from "react";
import PropTypes from "prop-types";

// styles
import i from "styles/components/indicator-info.module.scss";
import styles from "styles/components/text.module.scss";

const ItalicContent = ({ text }) => {
  return (
    <>
      <section className={["__row", i.__content].join(" ")}>
        <section className="__col_xs_12">
          <small>
            <em className={styles.__black__light}>{text}</em>
          </small>
        </section>
      </section>
    </>
  );
};

ItalicContent.propTypes = {
  text: PropTypes.string.isRequired
};

export default ItalicContent;
