import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// api
import * as Tokens from 'api/indicator-api';

// actions
import { displayAlert } from 'actions/admin-actions';
import * as IndicatorActions from 'actions/indicator-actions';

// constants
import { D } from 'constants/dictionary';
import { INDICATORS } from 'constants/routes-constants';
import * as ALERT_TYPES from 'constants/alert-types';

// helpers
import { cancelablePromise } from 'helpers/cancelable-promise';
import { excelRenderer } from 'helpers/excel-renderer';
import { getExcelHeaders } from 'helpers/get-excel-headers';
import { transformExcelData } from 'helpers/transform-excel-data';
import { transformRecords } from 'helpers/transform-records-to-table';

// components
import ExcelTable from './ExcelTable';
import IndicatorData from './IndicatorData';

const UPDATING = 1;
const DELETING = -1;
const PAGE_AMOUNT = 100;

class IndicatorDataContainer extends Component {

  pendingPromises = [];

  constructor(props) {
    super(props);

    const {
      location: { state },
      match: { params: { id } },
    } = props;
    let indicator = undefined;
    if (state && state.indicator) {
      indicator = state.indicator;
    }
    this.state = {
      // current rows used
      rows: [],
      // displayed table
      cols: [],
      selectedRows: [],
      pages: [],
      pageSelected: 0,
      validating: false,
      // handle excel
      excelErrors: [],
      fileError: '',
      fileLoaded: false,
      loadedFile: '',
      // related to the indicator
      creatingTable: false,
      indicator: indicator || {},
      originalCols: [],
      originalRows: [],
      areOriginalSelected: false,
      selectedId: id,
      // request related (save/delete)
      action: undefined,
      recordsToAdd: [],
      serverErrors: [],
      showModal: false,
      // adding an excel file
      showChangesModal: false,
    }
  }

  componentDidMount() {

    /**
     * If the reducer has data from a previous action that couldn't
     * properly finish.
     */
    if (this.props.saving || this.props.loadingOne) {
      this.props.setSaving({
        saving: -1,
        loadingOne: -1,
        loadingRecords: -1,
      });
    }

    // if the indicator was not passed after edit/create, fetch
    if (!this.state.indicator._id) {
      this.props.findIndicator(this.state.selectedId);
    } else {
      this.getRecords(this.state.indicator._id);
    }
  }

  componentDidUpdate(prevProps) {
    this.didUpdateOnLoad(prevProps);
    this.didUpdateOnAction(prevProps);
    this.didUpdateOnLoadRecords(prevProps);
  }

  componentWillUnmount() {
    const { requestCanceled: rc } = D.errors;
    // Cancel pending requests
    Tokens.findIndicatorCT && Tokens.findIndicatorCT.cancel(rc);
    Tokens.getRecordsCT && Tokens.getRecordsCT.cancel(rc);
    Tokens.saveRecordsCT && Tokens.saveRecordsCT.cancel(rc);
    Tokens.deleteRecordsCT && Tokens.deleteRecordsCT.cancel(rc);

    this.removeAllPromises();
  }

  /**
   * If props change, check if the indicator was being loaded.
   */
  didUpdateOnLoad = (prevProps) => {
    const loaded = (prevProps.loadingOne === 1) && (this.props.loadingOne === 0);

    if (!loaded) {
      return;
    }

    const { errorLoadingOne, selectedIndicator } = this.props;
    if (!errorLoadingOne) {
      this.setState({
        indicator: selectedIndicator,
      });
      this.getRecords(selectedIndicator._id);
    } else {
      let message = D.errors.loadInfo;
      const { response } = errorLoadingOne;
      if (response) {
        if (response.status === 404) {
          message = D.indicators.notFound;
        }
      }
      this.displayAlertAndGoBack(
        message,
        5000,
        ALERT_TYPES.WARNING
      );
    }
  }

  /**
   * When the props change, check if data was being saved/removed.
   */
  didUpdateOnAction = (prevProps) => {
    const saved = (prevProps.saving === 1) && (this.props.saving === 0);

    if (!saved) {
      return;
    }

    const { errorSaving, displayAlert } = this.props;
    const serverErrors = [];

    const { action } = this.state;
    const isUpdating = action === UPDATING;
    if (!errorSaving) {
      displayAlert({
        message: isUpdating
          ? D.indicators.data.saveSuccess
          : D.indicators.data.deleteSuccess,
        timeout: 3000,
        type: ALERT_TYPES.SUCCESS,
      });
      if (isUpdating) {
        this.props.history.replace(INDICATORS);
      } else {
        const { rows, cols, selectedRows, areOriginalSelected } = this.state;

        // If the records currently displayed are the original, clear them
        let newRows = [...rows];
        let newCols = [...cols];
        let newSelected = [...selectedRows];

        if (areOriginalSelected) {
          newRows = [];
          newCols = [];
          newSelected = [];
        }

        // Clear the original rows and records of this indicator
        this.setState({ originalRows: [], originalCols: [], rows: newRows, cols: newCols, selectedRows: newSelected });
      }
    } else {
      const { response } = errorSaving;
      if (response) {
        switch (response.status) {
          case 404:
            this.displayAlertAndGoBack(
              D.indicators.notFound,
              5000,
              ALERT_TYPES.WARNING,
            );
            break;
          case 409:
            if (response.data.errors) {
              serverErrors.push(`${D.indicators.data.placesError} ${response.data.errors.map((e) => e.place).join(' ')}`);
            }
            break;
          default:
            serverErrors.push(D.errors.serverError);
        }
      } else {
        if (errorSaving.message.includes('error deleting')) {
          displayAlert({
            message: D.indicators.data.deleteOldErr,
            timeout: 5000,
            type: ALERT_TYPES.WARNING
          });
        } else {
          serverErrors.push(D.errors.noConnection);
        }
      }

      this.setState({ serverErrors });
    }

    this.setState({ action: undefined });
  }

  /**
   * If props change, check if the indicator records were being loaded.
   */
  didUpdateOnLoadRecords = (prevProps) => {
    const loaded = (prevProps.loadingRecords === 1) && (this.props.loadingRecords === 0);

    if (!loaded) {
      return;
    }

    const { errorLoadingRecords, records } = this.props;
    if (!errorLoadingRecords) {

      // If there are no records, don't continue
      if (!records.length) {
        return;
      }

      // Make the promise cancelable
      const cPromise = cancelablePromise(transformRecords(records));
      this.addPendingPromise(cPromise);

      const { fileLoaded } = this.state;

      // If an excel file was loaded, don't display message
      if (!fileLoaded) {
        this.setState({ creatingTable: true });
      }
      cPromise.promise
        .then((res) => {
          const { rows, cols } = res;

          // Save the original rows and cols of the indicator
          this.setState({ originalRows: [...rows], originalCols: [...cols] });

          // If no excel file has been added
          if (!fileLoaded) {
            this.setState({
              creatingTable: false,
              rows: [...rows],
              cols: [...cols],
              areOriginalSelected: true,
            }, () => rows.length && this.initPagination());
          }
        })
        .then(() => this.removePendingPromise(cPromise))
        .catch((err) => {
          // If the promise was canceled but the component is still
          // mounted (excel file was added), save the original rows and cols
          const { isCanceled, value } = err;
          if (!isCanceled && value) {
            this.setState({
              creatingTable: false,
              originalRows: [...value.rows],
              originalCols: [...value.cols],
            });
          }
        });
    } else {
      let message = D.errors.loadRecords;
      const { response } = errorLoadingRecords;
      if (response) {
        if (response.status === 404) {
          message = D.indicators.notFound;
          this.displayAlertAndGoBack(
            message,
            5000,
            ALERT_TYPES.WARNING
          );
        }
      } else {
        this.setState({ serverErrors: [D.errors.noConnection] });
      }
    }
  }

  displayAlertAndGoBack = (message, timeout, type) => {
    this.props.displayAlert({
      message,
      timeout,
      type
    });
    this.props.history.replace(INDICATORS);
  }

  // Add a promise to the array
  addPendingPromise = promise => this.pendingPromises = [...this.pendingPromises, promise];

  // Remove the specified promise
  removePendingPromise = promise => this.pendingPromises.filter((p) => p !== promise);

  // Cancel all promises
  removeAllPromises = () => this.pendingPromises.map((p) => p.cancel());

  // Fetch the records of the indicator
  getRecords = (id) =>  this.props.getRecords(id);

  // Generate array of pages for the current rows
  initPagination = (pageSelected = 0, goLast = false) => {
    const { rows } = this.state;
    const pages = [...Array(Math.ceil(rows.length / PAGE_AMOUNT)).keys()];
    // If a new row is added, this will be true
    if (goLast) {
      pageSelected = pages[pages.length - 1];
    }
    this.setState({ pages }, () => this.setRows(undefined, pageSelected));
  }

  /**
   * When a file is added, try to extract data.
   */
  fileHandler = (event) => {
    this.removeAllPromises();
    let fileObj = event.target.files[0];
    const { indicator } = this.state;
    this.setState({
      fileLoaded: true,
      creatingTable: false,
      excelErrors: [],
      fileError: '',
      cols: [],
      rows: [],
      areOriginalSelected: false,
    });

    // This function will extract the data and set it on the state
    excelRenderer(fileObj, 1, indicator.recordsAsPercentage, (err, resp) => {
      if (err) {
        this.setState({ loadedFile: '', fileError: D.indicators.data.readError });
      } else {
        this.setState({
          recordsToAdd: [],
          serverErrors: [],
          cols: resp.cols,
          rows: resp.rows,
          loadedFile: fileObj.name,
        }, () => this.initPagination());
      }
    });
  }

  /**
   * Alert about adding or deleting records.
   * Used only when the indicator has records.
   */
  displayModal = (toUpdate) => {
    this.setState({
      showModal: true,
      action: toUpdate ? UPDATING : DELETING,
    });
  }

  /** Hide the warning modal */
  hideModal = () => {
    this.setState({ showModal: false, action: undefined });
  }

  /**
   * Validate current rows.
   *
   * transformExcelData returns the objects that will
   * be sent to the API.
   */
  updateRecordsAction = async () => {
    const { originalRows, rows, cols, validating } = this.state;

    if (validating) {
      return;
    }

    this.setState({ validating: true });
    const response = await transformExcelData(rows, cols);
    this.setState({ validating: false });
    // If errors in excel data, display errors
    if (response.errors.length) {
      this.setState({ excelErrors: response.errors });
    } else if (originalRows.length) { // If no errors, but indicator has data, show alert but don't call any action
      this.setState({ excelErrors: [], showModal: true, action: UPDATING, recordsToAdd: response.result });
    } else { // If no records, just save
      this.updateRecordsAfterConfirm(response.result);
    }
  }

  // Call redux action to update records
  updateRecordsAfterConfirm = (records) => {
    const { indicator: { _id } } = this.state;
    this.setState({
      action: UPDATING,
      excelErrors: [],
      serverErrors: [],
      showModal: false,
    }, () => this.props.updateRecords(_id, records));
  }

  // Call redux action to delete current records
  deleteRecordsAction = () => {
    this.setState({
      showModal: false,
      serverErrors: [],
      action: DELETING
    }, () => this.props.deleteRecords(this.state.indicator._id));
  }

  onCancelChanges = () => {
    const { history } = this.props;
    const { fileLoaded, rows, originalRows, areOriginalSelected } = this.state;
    // new file was uploaded or original rows changed
    if (fileLoaded || (areOriginalSelected && originalRows.length && JSON.stringify(originalRows) !== JSON.stringify(rows))) {
      this.setState({ showChangesModal: true })
    } else {
      history.replace(INDICATORS);
    }
  };

  hideChangesModal = () => this.setState({ showChangesModal: false });

  onAcceptChanges = () => this.props.history.replace(INDICATORS);

  /**
   * Function called when changes are made.
   *
   * @param {Array} changes Array with modified cells
   */
  onCellsChanged = changes => {
    const { pageSelected, rows: stateRows } = this.state;
    const rows = JSON.parse(JSON.stringify(stateRows));
    changes.forEach(({ row, col, value }) => {
      const correctRowIndex = pageSelected * PAGE_AMOUNT + row;
      rows[correctRowIndex][col] = { ...rows[correctRowIndex][col], value };
    });
    this.setState({ rows }, () => this.setRows(undefined, pageSelected));
  }

  // Add an empty row to the end of the rows
  onAddRow = () => {
    const { cols, rows, pageSelected } = this.state;
    const newRow = new Array(cols.length);
    newRow.fill({ value: undefined });
    const newRows = [...rows, newRow];
    this.setState({ rows: newRows }, () => this.initPagination(pageSelected, true));
  }

  /**
   * Remove the specified row.
   * Don't allow to delete the first row.
   */
  onDeleteRow = (index, pageSelected) => {

    if (index === 0 && pageSelected === 0) {
      return;
    }

    const { rows } = this.state;
    const rowsBefore = [...rows].slice(0, index);
    const rowsAfter = [...rows].slice(index + 1);
    const newRows = [...rowsBefore, ...rowsAfter];

    this.setState({ rows: newRows }, () => this.initPagination(pageSelected));
  }

  /**
   * Add a column to the right of the specified index.
   * Generate new excel headers.
   */
  onAddColumn = (index) => {
    const { rows: stateRows, pageSelected } = this.state;
    const rows = [...stateRows];
    rows.forEach((row, i) => {
      const colsBefore = [...row].slice(0, index + 1);
      const colsAfter = [...row].slice(index + 1);
      const newCol = [...colsBefore, { value: undefined }, ...colsAfter];
      rows[i] = newCol;
    });

    const cols = getExcelHeaders(rows[0].length);

    this.setState({ rows, cols }, () => this.initPagination(pageSelected));
  }

  /**
   * Delete the specified column.
   * Generate new excel headers.
   */
  onDeleteColumn = (index) => {
    const { rows: stateRows, pageSelected } = this.state;
    let rows = [...stateRows];
    rows.forEach((row, i) => {
      const colsBefore = [...row].slice(0, index);
      const colsAfter = [...row].slice(index + 1);
      const newCol = [...colsBefore, ...colsAfter];
      rows[i] = newCol;
    });

    const cols = getExcelHeaders(rows[0].length);

    if (!cols.length) {
      rows = [];
    }

    this.setState({ rows, cols }, () => this.initPagination(pageSelected));
  }

  /**
   * Select the rows to display based on page.
   *
   * @param {event} e Used when changing values with <select />.
   * @param {number} page Used when selecting a page programmatically.
   */
  setRows = (e, page) => {

    const { rows, pages } = this.state;

    // If there are no rows, initialize values
    if (!rows.length) {
      this.setState({
        selectedRows: [],
        pageSelected: 0
      });
      return;
    }

    // If the method was called with onChange event from select
    if (e) {
      page = parseInt(e.target.value);
    }

    // If the page selected does not exist, select the last one
    if (!pages.includes(page)) {
      page = pages[pages.length - 1];
    }

    const start = page * PAGE_AMOUNT;
    const end = start + PAGE_AMOUNT;
    let selectedRows = [...rows].slice(start, end);

    this.setState({ selectedRows, pageSelected: page });
  }

  /**
   * Show the original records of this indicator.
   */
  onShowOriginal = () => {
    const { originalRows, originalCols } = this.state;
    this.setState({
      rows: [...originalRows],
      cols: [...originalCols],
      fileLoaded: false,
      loadedFile: '',
      excelErrors: [],
      fileError: '',
      areOriginalSelected: false,
    }, () => this.initPagination());
  }

  render() {
    const {
      loadingOne,
      loadingRecords,
      saving,
    } = this.props;

    const {
      action,
      cols,
      creatingTable,
      excelErrors,
      fileError,
      fileLoaded,
      indicator,
      loadedFile,
      originalRows,
      pages,
      pageSelected,
      recordsToAdd,
      rows,
      selectedRows,
      serverErrors,
      showChangesModal,
      showModal,
      validating,
    } = this.state;

    const files = loadedFile.length ? [loadedFile] : [];

    return (
      <>
      <IndicatorData
        cols={cols}
        creatingTable={creatingTable}
        deleteRecords={this.deleteRecordsAction}
        displayModal={this.displayModal}
        fileError={fileError}
        fileHandler={this.fileHandler}
        fileLoaded={fileLoaded}
        files={files}
        hasRecords={!!originalRows.length}
        hideChangesModal={this.hideChangesModal}
        hideModal={this.hideModal}
        isUpdating={action === UPDATING}
        loading={!!loadingOne || !!saving}
        loadingRecords={!!loadingRecords}
        name={indicator.shortName}
        onAcceptChanges={this.onAcceptChanges}
        onCancelChanges={this.onCancelChanges}
        onSave={this.updateRecordsAction}
        onShowOriginal={this.onShowOriginal}
        recordsToAdd={recordsToAdd}
        rows={rows}
        serverErrors={serverErrors}
        showChangesModal={showChangesModal}
        showModal={showModal}
        updateRecordsAfterConfirm={this.updateRecordsAfterConfirm}
        validating={validating}
      />
      <ExcelTable
        cols={cols}
        excelErrors={excelErrors}
        loading={!!loadingOne || !!saving}
        onAddColumn={this.onAddColumn}
        onAddRow={this.onAddRow}
        onCellsChanged={this.onCellsChanged}
        onDeleteColumn={this.onDeleteColumn}
        onDeleteRow={this.onDeleteRow}
        pageAmount={PAGE_AMOUNT}
        pages={pages}
        pageSelected={pageSelected}
        rows={rows}
        selectedRows={selectedRows}
        serverErrors={serverErrors}
        setRows={this.setRows}
        validating={validating}
      />
      </>
    );
  }
}

IndicatorDataContainer.propTypes = {
  deleteRecords: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  errorLoadingOne: PropTypes.any,
  errorLoadingRecords: PropTypes.any,
  errorSaving: PropTypes.any,
  findIndicator: PropTypes.func.isRequired,
  getRecords: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loadingOne: PropTypes.number.isRequired,
  loadingRecords: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  records: PropTypes.array.isRequired,
  saving: PropTypes.number.isRequired,
  selectedIndicator: PropTypes.object.isRequired,
  setSaving: PropTypes.func.isRequired,
  updateRecords: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    errorSaving: state.indicatorReducer.errorSaving,
    errorLoadingOne: state.indicatorReducer.errorLoadingOne,
    errorLoadingRecords: state.indicatorReducer.errorLoadingRecords,
    loadingOne: state.indicatorReducer.loadingOne,
    loadingRecords: state.indicatorReducer.loadingRecords,
    records: state.indicatorReducer.records,
    selectedIndicator: state.indicatorReducer.selected,
    saving: state.indicatorReducer.saving,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteRecords: (id) => dispatch(IndicatorActions.deleteRecords(id)),
    displayAlert: (alert) => dispatch(displayAlert(alert)),
    findIndicator: (id) => dispatch(IndicatorActions.findIndicator(id)),
    getRecords: (id) => dispatch(IndicatorActions.getRecords(id)),
    setSaving: (payload) => dispatch(IndicatorActions.clearLoadingIndicator(payload)),
    updateRecords: (id, records) => dispatch(IndicatorActions.updateRecords(id, records)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndicatorDataContainer);
