export const UNAVAILABLE = 'No disponible';
export const ALL_YEARS = 'Todos';

// For quarters, moment.js takes 1 as as the first quarter
export const quarterlyValues = [
  { id: 1, label: 'Ene-Mar' },
  { id: 2, label: 'Abr-Jun' },
  { id: 3, label: 'Jul-Sep' },
  { id: 4, label: 'Oct-Dic' }
];

// For months, moment.js takes 0 as the first month
export const monthlyValues = [
  { id: 0, label: 'Enero' },
  { id: 1, label: 'Febrero' },
  { id: 2, label: 'Marzo' },
  { id: 3, label: 'Abril' },
  { id: 4, label: 'Mayo' },
  { id: 5, label: 'Junio' },
  { id: 6, label: 'Julio' },
  { id: 7, label: 'Agosto' },
  { id: 8, label: 'Septiembre' },
  { id: 9, label: 'Octubre' },
  { id: 10, label: 'Noviembre' },
  { id: 11, label: 'Diciembre' }
];
