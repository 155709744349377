import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

// Create context
export const DatesContext = createContext();

// Create provider
export const DatesProvider = ({ children }) => {

  // Declare values of this provider
  const [year, setYear] = useState({});
  const [frequency, setFrequency] = useState({});
  const [monthOrQuarter, setMonthOrQuarter] = useState({});
  const [isMonthSelected, setIsMonthSelected] = useState(false);
  const [strFetchDates, setStrFetchDates] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [minMaxYears, setMinMaxYears] = useState('');

  const value = {
    year,
    setYear,
    frequency,
    setFrequency,
    monthOrQuarter,
    setMonthOrQuarter,
    isMonthSelected,
    setIsMonthSelected,
    strFetchDates,
    setStrFetchDates,
    error,
    setError,
    loading,
    setLoading,
    minMaxYears,
    setMinMaxYears,
  }

  return (
    <DatesContext.Provider value={value}>
      { children }
    </DatesContext.Provider>
  );
}

DatesProvider.propTypes = {
  children: PropTypes.any,
}
