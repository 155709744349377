import React from 'react';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

const Pages = ({
  pages,
  pageSelected,
  setRows,
}) => {

  return (
    <section className="__page_wrapper __row center_xs middle_xs">
      <span>{D.indicators.data.page}:</span>
      <select
        className="__excel__drop"
        value={pageSelected}
        onChange={setRows}
      >
        {pages.map((p) => (
          <option
            key={p}
            value={p}
          >
            {p + 1}
          </option>
        ))}
      </select>
    </section>
  );
}

Pages.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSelected: PropTypes.number.isRequired,
  setRows: PropTypes.func.isRequired,
}

export default Pages;
