/**
 * Function to return the current selected period
 * as a string.
 *
 * @param {object} year Id and label are the year selected
 * @param {object} monthOrQuarter Label has the name of the month or quarter
 * @param {object} minMaxYears String with the first and last years available.
 */
export const getPeriod = (year, monthOrQuarter, minMaxYears) => {
  if (!year.id) {
    return minMaxYears;
  }

  let period = '';

  if (Object.keys(monthOrQuarter).length) {
    period = `${monthOrQuarter.label} `;
  }

  period += year.label;

  return period;
}
