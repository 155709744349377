import React from 'react';
import { Link } from 'react-router-dom'
import { X, Activity } from 'react-feather';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';

import { SUPER } from 'constants/admin-constants';

// styles
import b from 'styles/components/button.module.scss';
import s from 'styles/components/header.module.scss';
import t from 'styles/components/text.module.scss';

//Using class because onClickOutside is not working with functional components
class Sidebar extends React.Component {

  handleClickOutside = () => this.props.closeSidebar();

  render() {
    const panel = this.props.panel;
    const role = this.props.role;
    const _setPage= this.props._setPage;
    return (
      <aside className={s.__menu}>
        <header className={s.__menu_head}>
          <small className={[t.__gray, t.__uppercase].join(' ')}>{this.props.title}</small>
          <button
            onClick={this.props.menuBehaviour}
            className={s.__close}>
            <X size={32} />
          </button>
        </header>
        <section className={[s.__options].join(' ')}>
          {panel ?
            this.props.elements.map((e,index) => {
              return (
                <div key={index}>
                  {role !== SUPER && e._id === 3 ? null :
                    <>
                      { e._id !== 7 && e._id !== 6 && e._id !== 5 && e._id !== 4 ?
                        <li
                          className={[b.__transparent, s.__menu_item].join(' ')}
                          key={index}>
                          {e.icon ? e.icon : <Activity size={24} />}
                          <Link className={[t.__gray, s.__decoration_none].join(' ')} to={e.route} onClick={() => _setPage(e)}>
                            <h2 className={[t.__gray].join(' ')}>
                              {e.shortName}
                            </h2>
                          </Link>
                        </li>
                        :
                        <Button
                          key={index}
                          element={e}
                          setElement={this.props._setPage}
                        />
                      }
                    </>
                  }
                </div>
              )
            })
            :
            this.props.elements.map(e => {
              return (
                <Button
                  key={e._id}
                  element={e}
                  setElement={this.props.setElement}
                />
              )
            })}
        </section>
      </aside>
    );
  }
}

const Button = ({ element, setElement }) => {
  return (
    <button
      key={element._id}
      style={{ width: '100%' }}
      className={[b.__transparent, s.__menu_item].join(' ')}
      onClick={() => setElement(element)}
    >
      {element.icon ? element.icon : <Activity size={24} />}
      <h2 className={[t.__gray].join(' ')}>
        {element.shortName}
      </h2>
    </button>
  );
}

Sidebar.propTypes = {
  closeSidebar: PropTypes.func.isRequired,
  elements: PropTypes.array.isRequired,
  menuBehaviour: PropTypes.func.isRequired,
  setElement: PropTypes.func,
  _setPage: PropTypes.func,
  title: PropTypes.string.isRequired,
  panel: PropTypes.bool,
  role: PropTypes.string,
}

Button.propTypes = {
  element: PropTypes.object.isRequired,
  setElement: PropTypes.func.isRequired,
}

export default onClickOutside(Sidebar);
