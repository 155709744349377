import React from 'react';
import PropTypes from "prop-types";

import styles from 'styles/components/button.module.scss';

const getStyle = (bg) => {
  switch (bg) {
    case 'success':
      return styles.__success;
    case 'danger':
      return styles.__danger
    case 'primary':
    default:
      return styles.__primary;

  }
}

export const Button = (props) => {

  const bgColor = getStyle(props.bgStyle);
  const loginButton = props.login ? styles.__login_button : '';

  const className = [styles.lgt_button, bgColor, loginButton].join(' ');
  return (
    <>
      <section className={styles.lgt_button__bound}>
        <button
          disabled={props.disabled}
          type={props.type || 'button'}
          onClick={props.click}
          className={className}
        >
          {props.label}
        </button>
      </section>
    </>
  )
}

Button.propTypes = {
  bgStyle: PropTypes.string,
  click: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  login: PropTypes.bool,
  type: PropTypes.string,
}

export default Button;
