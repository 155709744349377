import React from 'react';

import styles from 'styles/components/checkbox.module.scss';
import PropTypes from 'prop-types';

export const Checkbox = ({
  cbName,
  cbValue,
  checked,
  label,
  onChange,
}) => {

  return (
    <>
      <section className={styles.lgt_checkbox__bound}>
        <label className={styles.container}>
          <input
            type="checkbox"
            name={cbName}
            value={cbValue}
            checked={checked}
            onChange={onChange}
          />
          <span className={styles.checkmark}></span>
          {label}
        </label>
      </section>
    </>
  )
}

Checkbox.propTypes = {
  cbName: PropTypes.string,
  cbValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
}
