import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import * as ROUTES from 'constants/routes-constants';

// Scenes
import AdminPanel from './admin'
import Home from "./home";
import Login from './login';


import RecoverPassword from './recoverPassword'
import ChangePassword from './recoverPassword/ChangePassword'
import SharedUrl from './sharedUrl';
import NotFound from './generalNotFound'
import Map from './map';


export const Scenes = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={() => <Redirect to={ROUTES.STATISTICS} />} />
          <Route exact path={ROUTES.STATISTICS} component={Home} />
          <Route exact path={ROUTES.LGT_ADMIN} component={Login} />
          <Route path={ROUTES.ADMIN_PANEL} component={AdminPanel} />
          <Route exact path={ROUTES.RECOVER_PASSWORD} component={RecoverPassword} />
          <Route exact path={`${ROUTES.RECOVER_PASSWORD}/:token`} component={ChangePassword} />
          <Route exact path={`${ROUTES.SHARE}/:code`} component={SharedUrl} />
          <Route path={ROUTES.MAP} component={Map} />
          {/* Not Found route */}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </>
  );
};
