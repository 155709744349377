import React from 'react';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

// components
import { Loader } from 'components/Loader';
import NotFound from 'components/NotFound';

const SharedUrl = (props) => {

  return (
    props.error ? (
      <NotFound
        message={D.info.urlNotFound}
        {...props}
      />
    ) : (
      <Loader />
    )
  );
}

SharedUrl.propTypes = {
  error: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
}

export default SharedUrl;
