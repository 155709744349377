import React from 'react';
import { X } from 'react-feather';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';
import { stateCodes } from 'constants/state-codes';

// styles
import styles from 'styles/components/modal.module.scss';

// components
import { Button } from 'components/Button';

const StateLegends = (props) => (
  <section className={styles.modal__overlay}>
    <section className={styles.modal__card}>
      <button onClick={props.onClose} className={styles.modal__close}>
        <X size={32} color="red"/>
      </button>
      <h1>{props.title}</h1>
      <section className={styles.modal__body}>
        <ul>
          {Object.keys(stateCodes).map((key) => {
            if (key === stateCodes.FED.code) {
              return null;
            }

            const state = stateCodes[key];
            return (
              <li key={state.code}>
                {state.code}: {state.name}
              </li>
            )
          })}
        </ul>
      </section>
      <footer className={styles.modal__buttons}>
        <Button
          click={props.onClose}
          label={D.general.close}
          bgStyle="danger"
        />
      </footer>
    </section>
  </section>
);

StateLegends.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
}

export default StateLegends;
