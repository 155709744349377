import React, { Fragment } from "react";

// context
import { DatesProvider } from "providers/DatesProvider";
import { IndicatorProvider } from "providers/IndicatorProvider";
import { ItemProvider } from "providers/ItemProvider";
import { SearchProvider } from "providers/SearchProvider";

// components
import ItemContainer from "./components/ItemContainer";

// Google Analytics
import ReactGA from "react-ga";
ReactGA.initialize(process.env.REACT_APP_G_ANALYTICS);
ReactGA.pageview(window.location.pathname + window.location.search);

const HomeContainer = props => {
  return (
    <Fragment>
      <ItemProvider>
        <IndicatorProvider>
          <DatesProvider>
            <SearchProvider>
              <ItemContainer {...props} />
            </SearchProvider>
          </DatesProvider>
        </IndicatorProvider>
      </ItemProvider>
    </Fragment>
  );
};

export default HomeContainer;
