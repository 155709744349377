import React, { Fragment } from 'react';
import { Field,  FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { CSVLink } from "react-csv";

// constants
import { D } from 'constants/dictionary';

// helpers
import * as BREAKDOWN from 'constants/breakdown-constants';
import * as FREQUENCIES from 'constants/measurement-frequencies';
import * as Validate from 'helpers/validations';

// styles
import styles from 'styles/scenes/admin.module.scss';
import picture from "styles/components/imageUpload.module.scss";
import t from "styles/scenes/theme.module.scss";

// components
import { AlertModal } from 'scenes/admin/components/AlertModal';
import { Dropdown } from 'scenes/admin/components/Dropdown';
import { Input } from 'scenes/admin/components/TextInput';
import { TextArea } from 'scenes/admin/components/Textarea';
import { Loader } from 'components/Loader';
import Button from 'components/Button';
import CheckboxField from 'scenes/admin/components/CheckboxField';
import CheckboxGroup from 'scenes/admin/components/CheckboxGroup';
import FormErrorsMessage from 'scenes/admin/components/FormErrorsMessage';
import ServerErrors from 'scenes/admin/components/ServerErrors';
import Sources from './Sources';
import Tags from './Tags'
import { DropzoneInfo } from 'scenes/admin/components/DropzoneInfo'
import { Download, X } from 'react-feather';
import { ImageUploaderComp } from 'scenes/admin/components/ImageUploader';
import ImagePreview from './../../items/save/ImagePreview';

// Excel Icon
const EXCEL_ICO = require('./../../../../../assets/excel-file.png');


const SaveIndicator = ({
  displayTags,
  editMode,
  handleSubmit,
  hideChangesModal,
  itemsByHash,
  itemsById,
  loading,
  onAcceptChanges,
  onCancelChanges,
  onSubmit,
  serverErrors,
  showChangesModal,
  submitFailed,
  files,
  onChangeHandler,
  csvData,
  csvName,
  csvFile,
  updateExcel,
  cover,
  onDropCover,
  removeCover
}) => {


  return (
    <section className={`${styles.lgt_admin} __wrapper`}>
      {showChangesModal && (
        <AlertModal
          title={D.general.alert}
          close={hideChangesModal}
          onCancel={hideChangesModal}
          onContinue={onAcceptChanges}
        >
          <p>{D.general.cancelQ}</p>
          <p>{D.indicators.save.changeBody}</p>
        </AlertModal>
      )}
      {loading && <Loader />}
      <section className={`${styles.lgt_head} __row between_xs`}>
        <h1 className="__col_xs_12">{editMode ? D.indicators.save.edit : D.indicators.save.new}</h1>
        <h2 className="__col_xs_12">{D.indicators.save.title}</h2>
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="__row">
          <section className="__col_xs_6">
            <Field
              name="itemId"
              label={D.indicators.save.item}
              props={{
                advice: D.indicators.save.itemAdv
              }}
              component={Dropdown}
            >
              <option></option>
              {itemsById.map((id) => {
                const item = itemsByHash[id];
                return item.hasIndicators ? (
                  <option key={id} value={id}>{item.shortName}</option>
                ) : null;
              })}
            </Field>
          </section>
          <section className="__col_xs_10">
            <Field
              name="name"
              label={D.indicators.save.name}
              props={{
                advice: D.indicators.save.nameAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.maxLength255]}
            />
          </section>
          <section className="__col_xs_10">
            <Field
              name="shortName"
              label={D.indicators.save.shortName}
              props={{
                advice: D.indicators.save.shortNameAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.maxLength1024]}
            />
          </section>
        </section>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="introduction"
              label={D.indicators.save.introduction}
              props={{
                advice: D.indicators.save.introductionAdv
              }}
              component={TextArea}
              validate={[Validate.maxLength16000000]}
            />
          </section>
        </section>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="aboutIndicator"
              label={D.indicators.save.aboutIndicator}
              props={{
                advice: D.indicators.save.aboutIndicatorAdv
              }}
              component={TextArea}
              validate={[Validate.maxLength16000000]}
            />
          </section>
        </section>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="definition"
              label={D.indicators.save.definition}
              props={{
                advice: D.indicators.save.definitionAdv
              }}
              component={TextArea}
              validate={[Validate.maxLength16000000]}
            />
          </section>
        </section>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="learnMore"
              label={D.indicators.save.learnMore}
              props={{
                advice: D.indicators.save.learnMoreAdv
              }}
              component={TextArea}
              validate={[Validate.maxLength16000000]}
            />
          </section>
        </section>
        <section className="__col_xs_10">
          <small className="__col_xs_12">{D.indicators.save.formulaImage}</small>
          <section className="__col_xs_6">
            <ImageUploaderComp
              label={D.indicators.save.formulaImagePlaceholder}
              disabled={!!cover._id}
              onChange={onDropCover}
              advice={D.indicators.save.formulaImageHelper}
            />
            {!!cover._id && (
              <div className={picture.__wrapper}>
                <ImagePreview
                  image={cover}
                  onRemove={removeCover}
                  fullWidth
                />
              </div>
            )}
          </section>
        </section>
        <section className="__row">
          <Field
            name="frequencies"
            label={D.indicators.save.frequencies}
            component={CheckboxGroup}
            options={[
              { value: FREQUENCIES.ANNUAL_PARAM, label: FREQUENCIES.ANNUAL }
            ]}
          />
        </section>
        <section className="__row">
          <Field
            name="geographicBreakdown"
            label={D.indicators.save.breakdown}
            component={CheckboxGroup}
            options={[
              { value: BREAKDOWN.FEDERAL_PARAM, label: D.indicators.save.federal },
              { value: BREAKDOWN.STATE_PARAM, label: D.indicators.save.state },
              { value: BREAKDOWN.MUNICIPAL_PARAM, label: D.indicators.save.municipal },
            ]}
          />
        </section>
        <section className="__row">
          <p className="__col_xs_12">{D.indicators.save.recordsPercentageSection}</p>
          <section style={{position: 'relative'}} className="__col_xs_3">
            <Field
              name="recordsAsPercentage"
              label={D.indicators.save.recordsPercentage}
              component={CheckboxField}
              validate={[Validate.onlyOneSelected]}
            />
          </section>
          <section className="__col_xs_3">
            <Field
              name="recordsAsCoin"
              label={D.indicators.save.recordsCoin}
              component={CheckboxField}
            />
          </section>
        </section>
        <section className="__col_xs_6">
          <Field
            name="hasSpecialTags"
            label={D.indicators.save.hasSpecialTags}
            props={{
              advice: D.items.save.hasSpecialTagsAdv
            }}
            component={Dropdown}
          >
            <option value={true}>{D.general.yes}</option>
            <option value={false}>{D.general.no}</option>
          </Field>
        </section>
        {(displayTags === 'true' || displayTags === true) && (
          <section className={t.__fadein}>
            <section className="__row">
              <section className="__col_xs_10">
                <FieldArray
                  name="tags"
                  component={Tags}
                />
              </section>
            </section>
          </section>
        )}
        <section className="__row">
          <section className="__col_xs_10">
            <FieldArray
              name="sources"
              component={Sources}
            />
          </section>
        </section>
        <section className="__row">
          <section className="__col_xs_12">
            <small>{D.indicators.save.addInfo}</small>
          </section>
          <section className="__col_xs_7">
            {csvFile ?
              <Fragment>
                <section className={`__row ${styles.__csv_container}`}>
                  <section className="__col_xs_6 __row  start_xs">
                    <section className={`__col_xs_12 ${styles.__csv_row}`}>
                      <button type="button" onClick={updateExcel} className={styles.__csv_remove}><X color="#FFF"/></button>
                      <img src={EXCEL_ICO} width={120} loading="lazy" alt={`${D.indicators.save.requestUploaded}${csvName}`} />
                    </section>
                    <section className="__col_xs_12" >
                      <small>{D.indicators.save.CSVFile} <strong>{`${csvName}-${D.indicators.save.addInfo}.csv`}</strong></small>
                      <CSVLink className={styles.__csv_link} filename={`${csvName}-${D.indicators.save.addInfo}.csv`}  data={csvData}><Download size={19}/></CSVLink>
                    </section>
                  </section>
                </section>
              </Fragment>
              : <DropzoneInfo
                csvFile={csvFile}
                files={files}
                onChangeHandler={onChangeHandler}
              />}
          </section>
        </section>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="treatment"
              label={D.indicators.save.treatment}
              props={{
                advice: D.indicators.save.treatmentAdv
              }}
              component={TextArea}
              validate={[Validate.maxLength16000000]}
            />
          </section>
        </section>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="problems"
              label={D.indicators.save.problems}
              props={{
                advice: D.indicators.save.problemsAdv
              }}
              component={TextArea}
              validate={[Validate.maxLength16000000]}
            />
          </section>
        </section>
        {submitFailed && <FormErrorsMessage />}
        <ServerErrors errors={serverErrors} />
        <section className="__row">
          <section className="__col_xs_10 __row end_xs">
            <section className="__col_xs_2">
              <Button bgStyle="success" type="submit" label={D.general.continue} />
            </section>
            <section className="__col_xs_2">
              <Button
                click={onCancelChanges}
                bgStyle="danger"
                label={D.general.cancel}
              />
            </section>
          </section>
        </section>
      </form>
    </section>

  )
}

SaveIndicator.propTypes = {
  onChangeHandler: PropTypes.func.isRequired,
  fileLoaded: PropTypes.bool,
  files: PropTypes.any,
  editMode: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideChangesModal: PropTypes.func.isRequired,
  itemsByHash: PropTypes.object.isRequired,
  itemsById: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool.isRequired,
  onAcceptChanges: PropTypes.func.isRequired,
  onCancelChanges: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  showChangesModal: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  csvData: PropTypes.array,
  csvName: PropTypes.string,
  csvFile: PropTypes.bool,
  updateExcel: PropTypes.func,
  displayTags: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  cover: PropTypes.object.isRequired,
  onDropCover: PropTypes.func.isRequired,
  removeCover: PropTypes.func.isRequired,
}

export default SaveIndicator;
