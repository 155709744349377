import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

// helpers
import * as Validate from 'helpers/validations';

// Styles
import styles from 'styles/scenes/admin.module.scss';

// Components
import { AlertModal } from 'scenes/admin/components/AlertModal';
import { Input } from 'scenes/admin/components/TextInput';
import { Dropdown } from 'scenes/admin/components/Dropdown';
import { Loader } from 'components/Loader';
import Button from 'components/Button';
import FormErrorsMessage from 'scenes/admin/components/FormErrorsMessage';
import ServerErrors from 'scenes/admin/components/ServerErrors';

const SaveUser = ({
  editMode,
  handleSubmit,
  hideChangesModal,
  loadingOneUser,
  onAcceptChanges,
  onCancelChanges,
  onSubmit,
  savingUser,
  serverErrors,
  showChangesModal,
  submitFailed,
}) => {

  return (
    <section className={`${styles.lgt_admin} __wrapper`}>
      {showChangesModal && (
        <AlertModal
          title={D.general.alert}
          close={hideChangesModal}
          onCancel={hideChangesModal}
          onContinue={onAcceptChanges}
        >
          <p>{D.general.cancelQ}</p>
          <p>{D.users.save.changeBody}</p>
        </AlertModal>
      )}

      {(savingUser === 1 || loadingOneUser === 1) && <Loader />}
      <section className={`${styles.lgt_head} __row between_xs`}>
        <h1>{editMode ? D.users.save.edit : D.users.save.new}</h1>
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="email"
              label={D.users.save.email}
              props={{
                advice: D.users.save.emailAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.email, Validate.maxLength1024]}
            />
          </section>
          <section className="__col_xs_10">
            <Field
              name="name"
              label={D.users.save.name}
              props={{
                advice: D.users.save.nameAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.maxLength255]}
            />
          </section>
          <section className="__col_xs_10">
            <Field
              name="lastName"
              label={D.users.save.lastName}
              props={{
                advice: D.users.save.lastNameAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.maxLength64]}
            />
          </section>
          <section className="__col_xs_10">
            <Field
              name="organization"
              label={D.users.save.org}
              props={{
                advice: D.users.save.orgAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.maxLength64]}
            />
          </section>
          <section className="__col_xs_6">
            <Field
              name="role"
              props={{
                advice: D.users.save.roleAdv,
                value: {},
                options: [
                  { id: 0, label: D.users.save.superAdmin },
                  { id: 1, label: D.users.save.contentAdmin },
                ]
              }}
              validate={[Validate.required]}
              component={Dropdown}
            >
              <option value={"Admin"}>{D.users.save.contentAdmin}</option>
              <option value={"Super"}>{D.users.save.superAdmin}</option>
            </Field>
          </section>
        </section>
        {submitFailed && <FormErrorsMessage />}
        <ServerErrors errors={serverErrors} />
        <section className="__row">
          <section className="__col_xs_10 __row end_xs">
            <section className="__col_xs_2">
              <Button type="submit" label={D.general.save} bgStyle="success" />
            </section>
            <section className="__col_xs_2">
              <Button label={D.general.cancel} click={onCancelChanges} bgStyle="danger" />
            </section>
          </section>
        </section>
      </form>
    </section>
  )
}

SaveUser.propTypes = {
  editMode: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  hideChangesModal: PropTypes.func.isRequired,
  loadingOneUser: PropTypes.number,
  onAcceptChanges: PropTypes.func.isRequired,
  onCancelChanges: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  savingUser: PropTypes.number.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  showChangesModal: PropTypes.bool.isRequired,
  submitFailed:PropTypes.bool.isRequired
}

export default SaveUser;
