import { D } from 'constants/dictionary';

export const defaultTitle = (path) => {
  let section = '';
  if (path.includes("items")){
    section = D.admin.items;
  }
  else if(path.includes("users")){
    section = D.admin.users;
  }
  else{
    section = D.admin.indicators;
  }

  return section;
}
