import React from 'react';
import PropTypes from 'prop-types';

// components
import Button from 'components/Button';

// constants
import { D } from 'constants/dictionary';

// styles
import n from 'styles/scenes/notFound.module.scss';

const logo = require('assets/logo.png');

const NotFound = (props) => {

  const redirect = () => {
    props.history.push('/');
  }

  return (
    <div className={n.__container}>
      <img alt="logo" src={logo} className={n.__logo} />
      <p className={n.__text}>
        {D.general.notFound}
      </p>
      {props.message && (
        <p className={n.__text}>
          {props.message}
        </p>
      )}
      <Button
        label={D.general.goHome}
        click={redirect}
      />
    </div>
  );
}

NotFound.propTypes = {
  history: PropTypes.object,
  message: PropTypes.string,
}

export default NotFound;
