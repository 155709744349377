import XLSX from 'xlsx';

// Modified from https://www.npmjs.com/package/react-excel-renderer
/**
 * When the excel has been loaded, get data.
 *
 * @param {File} file
 * @param {number} sheet
 * @param {Function} callback Execute after data has been parsed.
 */
export const excelInformationRequests = (file, callback) => {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = function(e) {

      const data = e.target.result;
      const wb = XLSX.read(data, { type: rABS ? "binary" : "array" });
      
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      if (!ws) {
        reject('Not an excel file');
        return callback('Not an excel file');
      }
      
      const dataParse = JSON.stringify(XLSX.utils.sheet_to_json(ws, {header:1}));
      
      return callback(null, dataParse);
    }
    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
}


