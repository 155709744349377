export const MX_LAT = 24;
export const MX_LNG = -102;
export const MIN_ZOOM = 4;
export const MEDIUM_ZOOM = 4.75;
export const MAX_ZOOM = 5.5;

export const TOP_LEFT_CORNER = [36.97980872872457, -119.41078319149626];
export const BOTTOM_RIGHT_CORNER = [8.940322128384627, -85.37421834552161];

export const ATTRIBUTION = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>';
export const MAP_URL = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';

export const NO_DATA_COLOR = '#D4DADC';
