import React from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import MarkdownItIns from "markdown-it-ins";

// constants
import { D } from "constants/dictionary";

// helpers
import * as Validate from "helpers/validations";

// Styles
import picture from "styles/components/imageUpload.module.scss";
import styles from "styles/scenes/admin.module.scss";
import i from "styles/components/input.module.scss";
import s from "scenes/admin/components/styles.module.scss";
import t from "styles/scenes/theme.module.scss";

// Components
import { AlertModal } from "scenes/admin/components/AlertModal";
import { Dropdown } from "scenes/admin/components/Dropdown";
import { ImageUploaderComp } from "scenes/admin/components/ImageUploader";
import { Input } from "scenes/admin/components/TextInput";
import { Loader } from "components/Loader";
import Button from "components/Button";
import FormErrorsMessage from "scenes/admin/components/FormErrorsMessage";
import ImagePreview from "./ImagePreview";
import ServerErrors from "scenes/admin/components/ServerErrors";
import { TextArea } from 'scenes/admin/components/Textarea';

const SaveItem = ({
  content,
  contentExceeded,
  contentFilled,
  cover,
  displayOtherOptions,
  editMode,
  handleSubmit,
  hideAlertModal,
  hideChangesModal,
  loadingOneItem,
  maxContentLength,
  maxSliderLength,
  onAcceptChanges,
  onCancelChanges,
  onDropCover,
  onDropImages,
  onEditorChange,
  onSubmit,
  pictures,
  removeCover,
  removeImage,
  savingItem,
  serverErrors,
  setHasIndicators,
  showAlertModal,
  showChangesModal,
  sliderLength,
  submitFailed,
  submitSucceeded
}) => {
  const mdParser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true
  }).use(MarkdownItIns);
  return (
    <section className={`${styles.lgt_admin} __wrapper`}>
      {showChangesModal && (
        <AlertModal
          title={D.general.alert}
          close={hideChangesModal}
          onCancel={hideChangesModal}
          onContinue={onAcceptChanges}
        >
          <p>{D.general.cancelQ}</p>
          <p>{D.indicators.save.changeBody}</p>
        </AlertModal>
      )}
      {showAlertModal && (
        <AlertModal
          title={D.general.alert}
          content={D.items.save.warning}
          onContinue={hideAlertModal}
          onCancel={setHasIndicators}
          close={setHasIndicators}
        />
      )}
      {(savingItem === 1 || loadingOneItem === 1) && <Loader />}
      <section className={`${styles.lgt_head} __row between_xs`}>
        <h1>{editMode ? D.items.save.edit : D.items.save.new}</h1>
      </section>
      <form onSubmit={handleSubmit(onSubmit)}>
        <section className="__row">
          <section className="__col_xs_10">
            <Field
              name="name"
              label={D.items.save.name}
              props={{
                advice: D.items.save.nameAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.maxLength255]}
            />
          </section>
          <section className="__col_xs_10">
            <Field
              name="shortName"
              label={D.items.save.shortName}
              props={{
                advice: D.items.save.shortNameAdv
              }}
              component={Input}
              validate={[Validate.required, Validate.maxLength1024]}
            />
          </section>
          <section className="__col_xs_10">
            <Field
              name="description"
              label={D.items.save.desc}
              props={{
                advice: D.items.save.descAdv
              }}
              component={TextArea}
              validate={[Validate.maxLength1024]}
            />
          </section>
          <section className="__col_xs_6">
            <Field
              name="hasIndicators"
              label={D.items.save.hasInd}
              props={{
                advice: D.items.save.hasIndAdv
              }}
              validate={[Validate.required]}
              component={Dropdown}
            >
              <option value={true}>{D.general.yes}</option>
              <option value={false}>{D.general.no}</option>
            </Field>
          </section>
        </section>
        {displayOtherOptions && (
          <section className={t.__fadein}>
            <section className="__row">
              <section className="__col_xs_10">
                <Field
                  name="title"
                  label={D.items.save.titleField}
                  props={{
                    advice: D.items.save.titleFieldAdv
                  }}
                  component={Input}
                  validate={[Validate.required, Validate.maxLength255]}
                />
              </section>
            </section>
            <section className="__row">
              <section className="__col_xs_6">
                <ImageUploaderComp
                  label={D.items.save.cover}
                  disabled={!!cover._id}
                  onChange={onDropCover}
                  advice={D.items.save.coverAdv}
                />
                {!!cover._id && (
                  <div className={picture.__wrapper}>
                    <ImagePreview
                      image={cover}
                      onRemove={removeCover}
                      fullWidth
                    />
                  </div>
                )}
              </section>
            </section>
            <section className="__row">
              <section className="__col_xs_10">
                <section className={s.lgt_textarea__bound}>
                  <label className={s.lgt_textarea__label}>
                    {D.items.save.content}
                  </label>
                  <MdEditor
                    value={content}
                    renderHTML={text => mdParser.render(text)}
                    onChange={onEditorChange}
                    config={{
                      htmlClass: s.lgt_textarea
                    }}
                  />
                </section>
                {submitFailed || submitSucceeded ? (
                  !contentFilled ? (
                    <p className={i.lgt_input__error}>
                      {D.validations.required}
                    </p>
                  ) : (
                    contentExceeded && (
                      <p className={i.lgt_input__error}>
                        {D.items.save.contentLength1}
                        {maxContentLength}
                        {D.items.save.contentLength2}
                        {content.length}
                      </p>
                    )
                  )
                ) : null}
                <small className={i.lgt_input__advice}>
                  {D.items.save.contentAdv}
                </small>
              </section>
            </section>
            <section className="__row">
              <section className="__col_xs_6">
                <ImageUploaderComp
                  label={D.items.save.pictures}
                  disabled={!!(sliderLength >= maxSliderLength)}
                  onChange={onDropImages}
                  advice={D.items.save.picturesAdv}
                />
                {!!pictures.length && (
                  <div className={picture.__wrapper}>
                    {pictures.map(image =>
                      !image.removed ? (
                        <ImagePreview
                          key={image._id}
                          image={image}
                          onRemove={removeImage}
                        />
                      ) : null
                    )}
                  </div>
                )}
              </section>
            </section>
          </section>
        )}
        {(submitFailed ||
          (submitSucceeded &&
            displayOtherOptions &&
            (!contentFilled || contentExceeded))) && <FormErrorsMessage />}
        <ServerErrors errors={serverErrors} />
        <section className="__row">
          <section className="__col_xs_10 __row end_xs">
            <section className="__col_xs_2">
              <Button bgStyle="success" type="submit" label={D.general.save} />
            </section>
            <section className="__col_xs_2">
              <Button
                bgStyle="danger"
                label={D.general.cancel}
                click={onCancelChanges}
              />
            </section>
          </section>
        </section>
      </form>
    </section>
  );
};

SaveItem.propTypes = {
  content: PropTypes.string.isRequired,
  contentExceeded: PropTypes.bool.isRequired,
  contentFilled: PropTypes.bool.isRequired,
  cover: PropTypes.object.isRequired,
  displayOtherOptions: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hideChangesModal: PropTypes.func.isRequired,
  hideAlertModal: PropTypes.func.isRequired,
  loadingOneItem: PropTypes.number.isRequired,
  maxContentLength: PropTypes.number.isRequired,
  maxSliderLength: PropTypes.number.isRequired,
  onAcceptChanges: PropTypes.func.isRequired,
  onCancelChanges: PropTypes.func.isRequired,
  onDropCover: PropTypes.func.isRequired,
  onDropImages: PropTypes.func.isRequired,
  onEditorChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  pictures: PropTypes.array.isRequired,
  removeCover: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  savingItem: PropTypes.number.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  setHasIndicators: PropTypes.func.isRequired,
  showAlertModal: PropTypes.bool.isRequired,
  showChangesModal: PropTypes.bool.isRequired,
  sliderLength: PropTypes.number.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired
};

export default SaveItem;
