import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as queryString from 'query-string';

// context
import { DatesContext } from 'providers/DatesProvider';
import { IndicatorContext } from 'providers/IndicatorProvider';
import { ItemContext } from 'providers/ItemProvider';
import { SearchContext } from 'providers/SearchProvider';

// constants
import * as FREQUENCY_VALUES from 'constants/frequency-values';
import { D } from 'constants/dictionary';
import { NO_INDICATOR_SELECTED } from 'constants/general-constants';
import ErrorMessage from './../ErrorMessage';
import * as QUERY from 'constants/search-query-parameters';
// components
import { Dropdown } from 'components/Dropdown';
import { AlertTriangle } from 'react-feather';

// styles
import b from 'styles/components/button.module.scss';
import d from 'styles/components/dropdown.module.scss';
import s from 'styles/components/header.module.scss';
import t from 'styles/components/text.module.scss';

const DateFilter = ({ onChangeYear, yearsAvailable }) => {
  const { errorItem, item, setStrFetchItem } = useContext(ItemContext);

  const { error: errorIndicators, indicator, indicators, setIndicator, setStrFetchIndicators } = useContext(
    IndicatorContext
  );

  const { setMinMaxYears, setYear } = useContext(DatesContext);

  const { setFirstURL, search, setSearch } = useContext(SearchContext);

  const { loading: loadingItems } = useContext(ItemContext);

  const { error: indicatorsError, loading: loadingIndicators } = useContext(IndicatorContext);

  const { error: datesError, loading: loadingDates, setStrFetchDates, year } = useContext(DatesContext);

  const [loading, setLoading] = useState(loadingItems || loadingIndicators || loadingDates);
  const [error, setError] = useState(indicatorsError || datesError);

  const renderIndicatorsDropdown = width => {
    return (
      <section className={[`${s.__indicator} __col_xs_${width}`]}>
        <Dropdown
          name={D.a11.selectIndicator}
          useProps={{ id: '_id', label: 'shortName' }}
          data={indicators}
          value={indicator}
          disabled={!indicators.length}
          onChange={_setIndicator}
        />
      </section>
    );
  };

  /**
   * Search for the indicator in the array of indicators and set it.
   */
  const _setIndicator = e => {
    const _indicator = indicators.find(i => i._id === e.target.value && i._id !== NO_INDICATOR_SELECTED);

    if (_indicator) {
      setMinMaxYears('');
      setYear({});
      setIndicator(_indicator);
      const { [QUERY.ITEM_ID]: itemId } = queryString.parse(search);
      setSearch(
        queryString.stringify({
          [QUERY.ITEM_ID]: itemId,
          [QUERY.INDICATOR_ID]: _indicator._id
        })
      );
      // Clear the initial url so the new indicator does not load
      // the old year, month, etc.
      setFirstURL('');
    }
  };

  /** Only use one variable to display the error status */
  useEffect(() => {
    setError(indicatorsError || datesError);
  }, [indicatorsError, datesError]);

  /** Only use one variable to display the loading status */
  useEffect(() => {
    setLoading(loadingItems || loadingIndicators || loadingDates);
  }, [loadingItems, loadingIndicators, loadingDates]);

  return (
    <section className={[s.__filter].join(' ')}>
      {loading ? (
        <h2 className={[t.__black__light].join(' ')}>{D.general.loading}</h2>
      ) : error.length ? (
        error !== D.errors.noData && error !== D.info.noFrequencies ? (
          <button
            onClick={() => setStrFetchDates(Math.random().toString(36))}
            className={[b.__transparent, t.__gray, s.__retry_btn].join(' ')}
          >
            <small className={[t.__gray, s.__errorIndicator].join(' ')}>
              <AlertTriangle size={16} color='#eaeaea' />
              &nbsp;&nbsp;{error} {D.info.tryAgain}
            </small>
          </button>
        ) : (
          <section className='__row __col_xs_12 __col_sm_6  __col_md_5'>
            <section className='__col_xs_12'>
              <small className={[t.__gray, s.__errorIndicator].join(' ')}>
                <AlertTriangle size={16} color='#eaeaea' />
                &nbsp;&nbsp;{D.errors.noDataIndicator}
              </small>
            </section>
            {renderIndicatorsDropdown(12)}
          </section>
        )
      ) : (
        <>
          <section className='__wrapper'>
            <section className={['__row', s.__dropdowns].join(' ')}>
              {loading ? (
                <p className={t.__gray}>{D.general.loading}</p>
              ) : item.hasIndicators === false ? (
                errorItem ? (
                  <ErrorMessage error={errorItem} onClickAction={setStrFetchItem} />
                ) : (
                  <p className={t.__gray}>{item.title}</p>
                )
              ) : errorIndicators ? (
                <ErrorMessage error={errorIndicators} onClickAction={setStrFetchIndicators} />
              ) : (
                !!indicators.length && renderIndicatorsDropdown(8)
              )}
              <section className={`__col_xs_4 ${d.__animation}`}>
                    <Dropdown
                      name={D.a11.selectYear}
                  data={yearsAvailable}
                  value={year}
                  disabled={
                    !yearsAvailable.length ||
                    (!!yearsAvailable.length && yearsAvailable[0].label === FREQUENCY_VALUES.UNAVAILABLE)
                  }
                  onChange={onChangeYear}
                />
              </section>
            </section>
          </section>
        </>
      )}
    </section>
  );
};

DateFilter.propTypes = {
  frequencies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  monthsOrQuartersAvailable: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  numberOfFilters: PropTypes.number.isRequired,
  onChangeFrequency: PropTypes.func.isRequired,
  onChangeMonthOrQuarter: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  yearsAvailable: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired
};

export default DateFilter;
