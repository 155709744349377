import React from "react";
import s from "styles/components/table.module.scss";
import t from "styles/components/text.module.scss";
import PropTypes from "prop-types";

export const TableFooter = props => {
  return (
    <div onClick={props.onClick} className={s.__table_foot}>
      <small className={[t.__center, t.__white].join(" ")}>
        <strong>{props.label}</strong>
      </small>
    </div>
  );
};

TableFooter.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};
