import React from 'react';
import PropTypes from 'prop-types';

// styles
import t from "styles/components/text.module.scss";
import s from "styles/scenes/theme.module.scss";

const Title = ({
  icon,
  title,
}) => {

  return (
    <section className={['__col_xs_12', s.__title].join(" ")}>
      <div className={t.__black__light}>
        {icon}
      </div>
      <h1 className={t.__black}>
        {title}
      </h1>
    </section>
  );
}

Title.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
}

export default Title;
