import { getLegendValues, getLegendColors } from 'helpers/get-legend-array';
import { minColorRGB, maxColorRGB } from 'constants/general-constants';
import { stateCodes } from 'constants/state-codes';
/**
 * Generate the information that will be used for the map and charts
 * component.
 *
 * @param {Array} states
 * @param {Object} indicator
 * @param {Function} setMissingData
 * @param {Function} setLegendColors
 * @param {Function} setLegendValues
 * @param {Function} setTotalCountry
 * @param {Function} setStatesByHash
 * @param {Function} setStatesById
 */
export const generateStatesInfo = (
  states,
  indicator,
  setMissingData,
  setLegendColors,
  setLegendValues,
  setTotalCountry,
  setTotalState,
  setTotalESE,
  setStatesByHash,
  setStatesById,
  setMissingStates
) => {
  // If there are no states, clear the values
  if (!states.length) {

    // If there is an indicator but the info is missing
    if (indicator._id) {
      setMissingData(true);
    }
    setLegendColors([]);
    setLegendValues([]);
    setTotalCountry(undefined);
    setTotalState(undefined);
    setTotalESE(undefined);
    return;
  }

  let maxValue = 0;
  let foundCountry = false;
  let foundGeneralState = false;
  // eslint-disable-next-line
  let foundESE = false;
  let foundState = false;

  const statesIds = states.map(s => s.state.code);
  /**
   * Generate object with state codes as keys and their data
   * as values.
   *
   * Find the state with the biggest amount value and find the total
   * amount for the country.
   */
  const statesByHash = states.reduce((acumulator, s) => {
    if (s.state && s.state.type === 'state') {
      foundState = true;
      if (s.totalAmount > maxValue) {
        maxValue = s.totalAmount;
      }
    } else if (s.state && s.state.type === 'country') {
      foundCountry = true;
      setTotalCountry(s.totalAmount);
    } else if (s.state && s.state.type === 'generalState') {
      foundGeneralState = true;
      setTotalState(s.totalAmount)
    } else if (s.state && s.state.type === 'notSpecified') {
      foundESE = true;
      setTotalESE(s.totalAmount)
    }

    return {
      ...acumulator,
      [s.state.code]: s
    }
  }, {});

  //Create object with the states that does not have a value
  let missingStates = {}; 
  Object.keys(stateCodes).forEach(function(key){
    if(key === 'EST' || key === 'ESE' || key === 'FED'){
      return
    }  
    if(!statesByHash.hasOwnProperty(key)){
      missingStates[key] = {
        state:{
          code: stateCodes[key].code,
          name: stateCodes[key].name,
          type: 'state'
        }
      } 
    }
  });

  // If the current dataset is missing info for a state
  const missingData = foundCountry
    ? statesIds.length < 33 : statesIds.length < 32;

  // Set minValue with the totalAmount of the last state.
  let minValue = 0
  const lastState = states[states.length - 1];
  if (lastState && lastState.state && lastState.state.type === 'state') {
    minValue = lastState.totalAmount;
  }

  // Generate arrays for legend values and colors.

  let steps = 4;
  if(indicator.hasSpecialTags){
    steps = indicator.tags.length
  } else  if (minValue === maxValue && foundState) {
    maxValue = minValue + 1;
    steps = 2;
  }

  const legendValues = getLegendValues(minValue, maxValue, steps);
  const legendColors = getLegendColors(minColorRGB, maxColorRGB, legendValues.length - 1);

  
  // Set the new values
  if (!foundCountry) {
    setTotalCountry(undefined);
  }
  if (!foundGeneralState) {
    setTotalState(undefined);
  }
  setStatesById && setStatesById(statesIds);
  setStatesByHash(statesByHash);
  setLegendValues(legendValues);
  setLegendColors(legendColors);
  setMissingData(missingData);
  setMissingStates(missingStates);
}
