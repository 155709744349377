import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

// Create context
export const SearchContext = createContext();

// Create provider
export const SearchProvider = ({ children }) => {

  // Declare values of this provider
  const [firstURL, setFirstURL] = useState('');
  const [search, setSearch] = useState('');

  const value = {
    firstURL,
    setFirstURL,
    search,
    setSearch,
  }

  return (
    <SearchContext.Provider value={value}>
      { children }
    </SearchContext.Provider>
  );
}

SearchProvider.propTypes = {
  children: PropTypes.any
}
