import React, { useState, useEffect, useCallback, useContext } from 'react';
import * as queryString from 'query-string';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

// helpers
import { getFrequencyName } from 'helpers/get-frequency-name';

// context
import { DatesContext } from 'providers/DatesProvider';
import { IndicatorContext } from 'providers/IndicatorProvider';
import { SearchContext } from 'providers/SearchProvider';

// constants
import { API_URL } from 'constants/general-constants';
import { D } from 'constants/dictionary';
import * as FREQUENCY_NAMES from 'constants/measurement-frequencies';
import * as FREQUENCY_VALUES from 'constants/frequency-values';
import * as QUERY from 'constants/search-query-parameters';

// components
import DateFilter from './DateFilter';

const DateFilterContainer = ({
  history,
}) => {

  const [frequencies, setFrequencies] = useState([]);
  const [yearsAvailable, setYearsAvailable] = useState([]);
  const [monthsOrQuartersAvailable, setMonthsOrQuartersAvailable] = useState([]);
  const [numberOfFilters, setNumberOfFilters] = useState(2);
  const [triggerLoad, setTriggerLoad] = useState('');

  const {
    indicator,
  } = useContext(IndicatorContext);

  const {
    frequency,
    setMinMaxYears,
    setError,
    setFrequency,
    setIsMonthSelected,
    setLoading,
    setMonthOrQuarter,
    setYear,
    strFetchDates,
    year,
  } = useContext(DatesContext);

  const {
    firstURL,
    search,
    setSearch,
  } = useContext(SearchContext);

  /**
   * Clear the month or quarter selected when the third dropdown goes away.
   * Remove monthOrQuarter from the search paremeters.
   */
  const _hideMonthOrQuarterFilter = useCallback(() => {
    // eslint-disable-next-line no-unused-vars
    const { [QUERY.MONTH_QUARTER]: _delProp, ...newSearch } = queryString.parse(search);
    setSearch(queryString.stringify(newSearch));
    setMonthOrQuarter({});
    setNumberOfFilters(2);

    /**
     * Disable deps for search and setSearch, since this effect
     * should run only when the item changes.
     *
     * Remove the next line when adding functionality to this effect
     * and add it later.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMonthOrQuarter]);

  /**
   * Set frequency value when a new option from the dropdown is selected.
   */
  const _onChangeFrequency = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    setFrequency({
      id: parseInt(e.nativeEvent.target[index].value),
      label: e.nativeEvent.target[index].text
    });
  }

  /**
   * Set year value when a new option from the dropdown is selected.
   * Add the year to the search query.
   */
  const _onChangeYear = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const _year = parseInt(e.nativeEvent.target[index].value);
    setSearch(queryString.stringify({
      ...queryString.parse(search),
      [QUERY.YEAR]: _year,
    }));
    setYear({
      id: _year,
      label: e.nativeEvent.target[index].text
    });
  }

  /**
   * Set month or quarter when a new option from the dropdown is selected.
   * Add the month or quarter to the search query.
   */
  const _onChangeMonthOrQuarter = (e) => {
    const index = e.nativeEvent.target.selectedIndex;
    const _monthOrQuarter = parseInt(e.nativeEvent.target[index].value);
    setSearch(queryString.stringify({
      ...queryString.parse(search),
      [QUERY.MONTH_QUARTER]: _monthOrQuarter,
    }));
    setMonthOrQuarter({
      id: _monthOrQuarter,
      label: e.nativeEvent.target[index].text
    });
  }

  /**
   * If the value of year has changed, verify if the new value is ALL_YEARS
   * to hide the month or quarter dropdown.
   */
  useEffect(() => {
    if (year.label === FREQUENCY_VALUES.ALL_YEARS ) {
      if (frequency.label !== FREQUENCY_NAMES.ANNUAL) {
        setFrequency(frequencies[0]);
        _hideMonthOrQuarterFilter();
      }
    }
  }, [year, frequency, frequencies, setFrequency, _hideMonthOrQuarterFilter]);

  /**
   * If the option QUARTERLY or MONTHLY were selected, show the new dropdown
   * with the first option selected.
   */
  useEffect(() => {

    // Get the month or quarter from the url if present
    const {
      [QUERY.MONTH_QUARTER]: _moq,
    } = queryString.parse(firstURL);

    let _isMonthSelected;
    let _monthOrQuarter;
    if (frequency.label === FREQUENCY_NAMES.QUARTERLY) {
      setMonthsOrQuartersAvailable(FREQUENCY_VALUES.quarterlyValues);

      // Get the value as integer and set the quarter if the value
      // is valid.
      // Else, set the first possible value.
      const value = parseInt(_moq);
      if (value > 0 && value < 5) {
        _monthOrQuarter = value;
        // Substract one because for the quarter the options go
        // from 1 to 4
        setMonthOrQuarter(FREQUENCY_VALUES.quarterlyValues[value - 1]);
      } else {
        setMonthOrQuarter(FREQUENCY_VALUES.quarterlyValues[0]);
        _monthOrQuarter = FREQUENCY_VALUES.quarterlyValues[0].id;
      }

      _isMonthSelected = false;
      setIsMonthSelected(false);
      setNumberOfFilters(3);
    } else if (frequency.label === FREQUENCY_NAMES.MONTHLY) {
      setMonthsOrQuartersAvailable(FREQUENCY_VALUES.monthlyValues);

      // Get the value as integer and set the quarter if the value
      // is valid.
      // Else, set the first possible value.
      const value = parseInt(_moq);
      if (value >= 0 && value <= 11) {
        _monthOrQuarter = value;
        setMonthOrQuarter(FREQUENCY_VALUES.monthlyValues[value]);
      } else {
        setMonthOrQuarter(FREQUENCY_VALUES.monthlyValues[0]);
        _monthOrQuarter = FREQUENCY_VALUES.monthlyValues[0].id;
      }

      _isMonthSelected = true;
      setIsMonthSelected(true);
      setNumberOfFilters(3);
    } else {
      _hideMonthOrQuarterFilter();
    }

    // Set monthOrQuarter and isMonthSelected on the search query
    setSearch(queryString.stringify({
      ...queryString.parse(search),
      [QUERY.IS_MONTH]: _isMonthSelected,
      [QUERY.MONTH_QUARTER]: _monthOrQuarter
    }));

    /**
     * Disable deps for search and setSearch, since this effect
     * should run only when the item changes.
     *
     * Remove the next line when adding functionality to this effect
     * and add it later.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequency, setMonthOrQuarter, setIsMonthSelected, _hideMonthOrQuarterFilter, firstURL]);

  /**
   * Populate the frequencies array with the properties provided
   * by the indicator.
   */
  useEffect(() => {
    if (!indicator._id) {
      return;
    }

    let frequencies = [];
    let fCont = 0;
    let isFrequencySet = false;

    const {
      [QUERY.IS_MONTH]: _isMonthSelected,
      [QUERY.MONTH_QUARTER]: _monthOrQuarter
    } = queryString.parse(firstURL);

    for (const f in indicator.measurementFrequency) {
      if (indicator.measurementFrequency[f]) {
        const frequencyName = getFrequencyName(f);
        const _frequency = {id: fCont, label: frequencyName};
        frequencies.push(_frequency);
        fCont++;

        /**
         * If isMonthSelected is present in firstURL, check if this indicator has
         * that type of frequency. If true, add the property to the search query
         * and set that frequency.
         */
        if (_isMonthSelected === 'true' && frequencyName === FREQUENCY_NAMES.MONTHLY) {
          setSearch(queryString.stringify({
            ...queryString.parse(search),
            [QUERY.IS_MONTH]: _isMonthSelected,
            [QUERY.MONTH_QUARTER]: _monthOrQuarter
          }));
          setFrequency(_frequency);
          isFrequencySet = true;
        } else if (_isMonthSelected === 'false' && frequencyName === FREQUENCY_NAMES.QUARTERLY) {
          setSearch(queryString.stringify({
            ...queryString.parse(search),
            [QUERY.IS_MONTH]: _isMonthSelected,
            [QUERY.MONTH_QUARTER]: _monthOrQuarter
          }));
          setFrequency(_frequency);
          isFrequencySet = true;
        }
      }
    }

    setFrequencies(frequencies);
    /**
     * If the frequency was not set on the previous steps,
     * set the first one.
     */
    if (!isFrequencySet && frequencies.length) {
      setFrequency(frequencies[0]);
    }

    /**
     * Disable deps for search and setSearch, since this effect
     * should run only when the item changes.
     *
     * Remove the next line when adding functionality to this effect
     * and add it later.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicator, setFrequency, triggerLoad, firstURL]);

  /**
   * Make a request to get the oldest and newest dates saved in the database to
   * populate the years array (the years will be year those between the dates -
   * including those in the dates).
   *
   * If the selected indicator changes before the request has finished,
   * cancel it (that's the purpose of the return function).
   */
  useEffect(() => {
    if (!indicator._id) {
      return;
    }

    let atLeastOneFrequency = false;
    for (const f in indicator.measurementFrequency) {
      if (indicator.measurementFrequency[f]) {
        atLeastOneFrequency = true;
        break;
      }
    }

    // If there are no frequencies, don't display data
    if (!atLeastOneFrequency) {
      setError(D.info.noFrequencies);
      history.replace({ search });
      return;
    }

    setLoading(true);
    setError('');

    const source = axios.CancelToken.source();

    axios.get(`${API_URL}records/${indicator._id}/dates`, {
      cancelToken: source.token
    })
      .then((response) => {
        const { data } = response;
        if (data.success && data.data) {
          const oldestYear = moment(data.data.minDate).year();
          const newestYear = moment(data.data.maxDate).year();

          let period = oldestYear;
          if (oldestYear !== newestYear) {
            period = `${oldestYear} - ${newestYear}`;
          }
          setMinMaxYears(period);

          const yearsAvailable = [{ id: 0, label: FREQUENCY_VALUES.ALL_YEARS }];
          const _year = parseInt(queryString.parse(search)[QUERY.YEAR]);
          let yearToSet = undefined;
          let fromURL = false;
          for (let i = newestYear; i >= oldestYear; i--) {
            const yearToAdd = { id: i, label: i.toString() };

            // If it's the first element or it was the year present in the URL
            if (i === newestYear || _year === i) {
              yearToSet = { ...yearToAdd };
            }

            // Flag to know if the year comes from the URL
            if (_year === i || _year === 0) {
              fromURL = true;
            }

            yearsAvailable.push(yearToAdd);
          }

          // If year = 0 select all years
          if (_year === 0) {
            yearToSet = { id: 0, label: FREQUENCY_VALUES.ALL_YEARS };
          }

          // If a year was found, set it for the dropdown and add it to the URL
          if (yearToSet) {
            setYear(yearToSet);
            setSearch(queryString.stringify({
              ...queryString.parse(search),
              [QUERY.YEAR]: yearToSet.id,
            }));

            // If the year comes from the url, trigger a change in the effect to
            // fill the frequencies and set the month/quarter selected
            if (fromURL) {
              setTriggerLoad(Math.random().toString(36));
            }
          }

          setYearsAvailable(yearsAvailable);
        } else {
          setError(D.errors.noData);
          setYearsAvailable([{ id: 0, label: FREQUENCY_VALUES.UNAVAILABLE }]);
          _hideMonthOrQuarterFilter();
          history.replace({ search });
        }
        setLoading(false);
      })
      .catch((err) => {
        if (!err.response) {
          setError(D.errors.noConnection);
        } else {
          setError(D.errors.serverError);
        }
        setLoading(false);
      });

    return (() => {
      source.cancel(D.errors.requestCanceled);
    });

    /**
     * Disable deps for search and setSearch, since this effect
     * should run only when the indicator changes.
     *
     * Remove the next line when adding functionality to this effect
     * and add it later.
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indicator, setFrequency, setYear, _hideMonthOrQuarterFilter, strFetchDates, setError]);

  return (
    <DateFilter
      frequencies={frequencies}
      monthsOrQuartersAvailable={monthsOrQuartersAvailable}
      numberOfFilters={numberOfFilters}
      onChangeFrequency={_onChangeFrequency}
      onChangeMonthOrQuarter={_onChangeMonthOrQuarter}
      onChangeYear={_onChangeYear}
      yearsAvailable={yearsAvailable}
    />
  );
}

DateFilterContainer.propTypes = {
  history: PropTypes.object.isRequired,
}

export default DateFilterContainer;
