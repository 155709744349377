import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as queryString from 'query-string';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';

// constants
import { API_URL } from 'constants/general-constants';
import { D } from 'constants/dictionary';
import * as QUERY from 'constants/search-query-parameters';

// helpers
import { generateStatesInfo } from 'helpers/generate-states-info';

// components
import { Loader } from 'components/Loader';
import Mexico from 'components/Mexico';

const MapContainer = (props) => {

  const [error, setError] = useState('');
  const [indicator, setIndicator] = useState({});
  const [legendValues, setLegendValues] = useState([]);
  const [legendColors, setLegendColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [missingData, setMissingData] = useState(undefined);
  const [period, setPeriod] = useState('');
  const [statesByHash, setStatesByHash] = useState({});
  const [strForGeoJSON, setStrForGeoJSON] = useState('');
  const [totalCountry, setTotalCountry] = useState(undefined);
  const firstRender = useRef(undefined);

  const getError = useCallback((err) => {
    let error;

    if (!err.response) {
      error = D.errors.noConnection;
    } else if (err.response.status === 404) {
      error = D.indicators.notFound;
    } else {
      error = D.errors.serverError;
    }

    return error;
  }, []);

  const getIndicator = useCallback(async (id) => {
    let error = undefined;
    let data = undefined;

    try {
      const indicatorRes = await axios.get(`${API_URL}indicators/${id}`);
      if (indicatorRes.data && indicatorRes.data.data) {
        data = indicatorRes.data.data;
      } else {
        error = D.errors.mapIndicator;
      }
    } catch (err) {
      error = getError(err);
    }

    return Promise.resolve([error, data]);
  }, [getError]);

  const getDates = useCallback(async (id) => {
    let error = undefined;
    let data = undefined;

    try {
      const datesRes = await axios.get(`${API_URL}records/${id}/dates`);
      if (datesRes.data && datesRes.data.data) {
        data = datesRes.data.data;
      } else if (datesRes.data && datesRes.data.message === 'There are no records') {
        error = D.errors.noData;
      } else {
        error = D.errors.mapDates;
      }
    } catch (err) {
      error = getError(err);
    }

    return Promise.resolve([error, data]);
  }, [getError]);

  const getRecords = useCallback(async (query) => {
    let error = undefined;
    let data = undefined;

    try {
      const recordsRes = await axios.get(query);
      if (recordsRes.data && recordsRes.data.data) {
        data = recordsRes.data.data;
      } else if (recordsRes.data && recordsRes.data.message === 'There are no records') {
        error = D.errors.noData;
      } else {
        error = D.errors.mapRecords;
      }
    } catch (err) {
      error = getError(err);
    }

    return Promise.resolve([error, data]);
  }, [getError]);

  useEffect(() => {

    // Only apply this effect on first render
    if (!firstRender.current) {
      firstRender.current = true;
    } else {
      return;
    }

    // Get url params
    const {
      [QUERY.INDICATOR_ID]: indicatorId,
      [QUERY.YEAR]: year,
    } = queryString.parse(props.location.search);

    const makeRequest = async () => {
      setLoading(true);

      // Get the indicator data
      const [errIndicator, _indicator] = await getIndicator(indicatorId);
      if (errIndicator) {
        setError(errIndicator);
        setLoading(false);
        return;
      }

      let yearQuery = '';
      // Retrieve min and max dates if no period was specified or
      // all records are required (year = 0 means all records)
      if (year === undefined || year === '0') {
        const [errDates, dates] = await getDates(_indicator._id);
        if (errDates) {
          setError(errDates);
          setLoading(false);
          return;
        }
        const maxYear = moment(dates.maxDate).year();
        const minYear = moment(dates.minDate).year();

        // Search for the latest year records if no period was specified
        if (year === undefined) {
          yearQuery = `&year=${maxYear}`;
          setPeriod(maxYear);
        } else {
          setPeriod(`${minYear} - ${maxYear}`);
        }
      } else if (year !== '0') { // An exact year was specified in the url
        yearQuery = `&year=${year}`;
        setPeriod(year);
      }

      const operation = _indicator.recordsAsPercentage ? 'avg' : 'sum';
      const query = `${API_URL}records/${_indicator._id}?operation=${operation}${yearQuery}`;

      const [errRecords, records] = await getRecords(query);
      if (errRecords) {
        setError(errRecords);
        setLoading(false);
        return;
      }

      // Set the values that will be used by the map with
      // the records retrieved
      generateStatesInfo(
        records,
        _indicator,
        setMissingData,
        setLegendColors,
        setLegendValues,
        setTotalCountry,
        setStatesByHash,
      );

      setStrForGeoJSON(Math.random().toString('36'));
      setIndicator(_indicator);
      setLoading(false);
    }

    makeRequest();
  }, [props.location.search, getIndicator, getDates, getRecords])

  return (
    <>
    { loading && <Loader /> }
    <Mexico
      indicator={indicator}
      legendColors={legendColors}
      legendValues={legendValues}
      missingData={missingData}
      period={period}
      seeMore={false}
      statesByHash={statesByHash}
      strForGeoJSON={strForGeoJSON}
      totalCountry={totalCountry}
    />
    {error && (<p style={{ textAlign: 'center' }}>{error}</p>)}
    </>
  );
}

MapContainer.propTypes = {
  location: PropTypes.object.isRequired,
}

export default MapContainer;
