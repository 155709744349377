import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import { adminReducer } from './admin-reducer';
import { indicatorReducer } from './indicator-reducer';
import { itemReducer } from './item-reducer';
import { userReducer } from './user-reducer';
import { authReducer } from './auth-reducer'

const appReducer = combineReducers({
  adminReducer,
  form: formReducer, // Must be called form
  indicatorReducer,
  itemReducer,
  userReducer,
  authReducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
}

export default rootReducer;
