import React from 'react';
import PropTypes from 'prop-types';

// styles
import t from "styles/components/text.module.scss";
import s from "styles/scenes/theme.module.scss";

const Subtitle = ({
  icon,
  title,
  style
}) => {
  return (
    <>
      <section className="__row">
        <section style={style ? style : null} className="__col_xs_12">
          <h2 className={[s.__stitle, t.__black].join(" ")}>
            {icon}
            {title}
          </h2>
        </section>
      </section>
    </>
  );
}

Subtitle.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  style: PropTypes.any
}

export default Subtitle;
