// constants
import { D } from 'constants/dictionary';

/**
 * Function that takes an array with data of each state
 * and returns an object with data formatted to export as csv.
 *
 * @param {Array} statesById Array with ids of the states
 * @param {Array} statesByHash Array with data of each state
 * @param {Array} period String that contains the period used to
 *   retrieve the statistics.
 *
 * @returns {object} Object with rows and headers.
 *   Rows is an array of arrays where each row has the state name
 *   and the total amount.
 *   Headers is the array used for CSVLink.
 */
export const getRankingCSV = (statesById, statesByHash, period) => {
  const headers = [D.info.period, period];

  if (!statesById.length || !Object.keys(statesByHash).length) {
    return {
      data: [],
      headers,
    }
  }

  // Fill the rows with the state name and amount
  const rows = statesById.reduce((res, id) => {
    const stateData = statesByHash[id];
    if (stateData) {
      return [
        ...res,
        [stateData.state.name, stateData.totalAmount]
      ];
    } else {
      return res;
    }
  },[[D.source.source, D.source.oct]]);
  // Use to add CSV headers ['D.info.state, D.info.totalXls']

  return {
    data: rows,
    headers: headers
  }
}
