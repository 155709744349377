export const D = Object.freeze({
  admin: {
    admin: 'Administrador',
    contentAdmin: 'Administrador de Contenido',
    header: 'Panel de administración',
    indicators: 'Administrar Indicadores',
    items: 'Administrar Rubros',
    users: 'Administrar Usuarios'
  },
  source: {
    source: 'Fuente:',
    oct: 'Observatorio contra la Tortura'
  },
  errors: {
    loadInfo: 'Lo sentimos, ocurrió un error al cargar la información',
    loadRecords: 'Lo sentimos, ocurrió un error al cargar las estadísticas',
    mapDates: 'Lo sentimos, ocurrió un error relacionado con la fecha de los registros (es posible que no existan registros)',
    mapIndicator: 'Lo sentimos, ocurrió un error relacionado al indicador',
    mapRecords: 'Lo sentimos, ocurrió un error relacionado con los registros',
    noConnection: 'Por favor revisa tu conexión',
    noData: 'Sin datos',
    noDataItem: 'No existen datos, selecciona otro rubro.',
    noDataIndicator: 'No existen datos, selecciona otro indicador.',
    requestCanceled: 'Request canceled',
    serverError: 'Lo sentimos. Error de servidor.',
  },
  general: {
    actions: 'Acciones',
    alert: 'Alerta',
    cancel: 'Cancelar',
    cancelQ: '¿Deseas volver?',
    close: 'Cerrar',
    continue: 'Continuar',
    continueQ: '¿Deseas continuar?',
    delete: 'Eliminar',
    edit: 'Editar',
    goHome: 'Ir al inicio',
    lastUpdateDate: 'Fecha de la ultima modificación:',
    loading: 'Cargando...',
    move: 'Mover',
    no: 'No',
    notFound: 'Lo sentimos. No pudimos encontrar el contenido que estás buscando.',
    orientation: 'Para una mejor experiencia abre este sitio en tu computador o tablet en orientación landscape.',
    orientationAlt: 'Usa tu computadora para una mejor experiencia',
    remove: 'Remover',
    save: 'Guardar',
    skip: 'Omitir Este Paso',
    updatedAt: 'Actualizado',
    updatedBy: 'Actualizado Por',
    yes: 'Sí',
    noDataStates: 'Estados Sin Datos'
  },
  indicators: {
    create: 'CREAR INDICADOR',
    deleteBody: 'Las estadísticas relacionadas con este indicador serán eliminadas permanentemente.',
    deleteTitle: '¿Deseas borrar: ',
    elimDefaultError: 'Lo sentimos, ocurrió un error al eliminar el indicador',
    elimSuccess: 'Indicador eliminado exitosamente',
    item: 'Rubro',
    noData: 'No hay Indicadores',
    noItem: 'Sin rubro',
    notFound: 'Indicador no encontrado',
    prop: 'Indicador',
    selectItem: 'Selecciona un rubro',
    title: 'Indicadores',
    noFormulaImage: 'No hay Imagen de fórmula',
    formulaAlt: 'Fórmula para medición de datos',
    save: {
      aboutIndicator: 'Acerca del indicador',
      aboutIndicatorAdv: 'Ingresa la información de la sección "Acerca del indicador"',
      addInfo: 'Solicitud de información',
      addSource: 'Agregar Fuente',
      addTag: 'Agregar etiqueta',
      breakdown: 'Desglose Geográfico',
      calc: 'Método de Cálculo',
      calcAdv: 'Total de denuncias por el delito de tortura=𝑇𝑡Tt1 + 𝑇𝑡Tt2 + 𝑇𝑡Tt3...',
      createSuccess: 'Indicador creado exitosamente',
      changeBody: 'No se guardarán tus cambios.',
      CSVFile: 'Archivo:',
      definition: 'Definición del indicador',
      definitionAdv: 'Ingresa la definición del indicador',
      denominator: 'Denominador',
      denominatorAdv: 'Ingresa el denominador del método de cálculo',
      edit: 'Editar Indicador',
      federal: 'Federal',
      aboutItem: 'Acerca del Rubro',
      indicatorDesc: 'Descripción del indicador',
      frequencies: 'Frecuencia de Medición',
      hasSpecialTags: 'Indicador con etiquetas especiales',
      hasSpecialTagsAdv: 'Elige si el indicador tendrá etiquetas especiales',
      introduction: 'Introducción del indicador',
      introductionAdv: 'Ingresa la introducción acerca del indicador',
      item: 'Rubro',
      itemAdv: 'Elige el rubro en el que se mostrará el indicador',
      itemError: 'El rubro seleccionado no puede contener indicadores',
      itemNotFound: 'El rubro especificado no existe',
      learnMore: 'Nota sobre el indicador',
      learnMoreAdv: 'Ingresa las notas sobre el indicador',
      municipal: 'Municipal',
      name: 'Nombre',
      nameAdv: 'Ingresa el nombre completo del indicador',
      nameExists: 'El nombre completo del indicador ya existe',
      new: 'Nuevo Indicador',
      numerator: 'Numerador',
      numeratorAdv: 'Ingresa el numerador del método de cálculo',
      problems: 'Consideraciones del Indicador',
      problemsAdv: 'Problemas o puntos débiles del indicador',
      recordsCoin: 'Moneda',
      recordsPercentageSection: "Tipo de Dato",
      recordsPercentage: "Porcentaje",
      saveSuccess: 'Indicador actualizado exitosamente',
      shortName: 'Nombre corto',
      shortNameAdv: 'Ingresa el nombre del indicador que aparecerá en la barra lateral',
      shortNameExists: 'El nombre corto del indicador ya existe',
      source: 'Fuente',
      sourceNum: 'Valor Númerico',
      sourceDesc: 'Descripción del Valor Númerico',
      sourceAdv: 'Ingresa la Fuente y Formato de Datos',
      sourceAdvNum: 'Ingresa el valor númerico',
      sourceAdvDesc: 'Ingresa la descripción del valor númerico',
      state: 'Estatal',
      title: 'Paso 1: DATOS DE IDENTIFICACIÓN DEL INDICADOR',
      treatment: 'Tratamiento Especial',
      treatmentAdv: 'Tratamiento especial del Indicador',
      requestUploaded: 'solicitud -- ',
      requestRead: 'Consulta de solicitudes de información',
      requestDownload: 'Descargar documento',
      formulaImage: 'Imagen para representar Método de Cálculo (440px x 330px)',
      formulaImagePlaceholder: 'Selecciona una imagen de Método de Cálculo',
      formulaImageHelper: 'Elige una imagen del Método de Cálculo'
    },
    data: {
      addRow: 'Agregar fila',
      changeBody: 'No se guardarán las nuevas estadísticas.',
      creatingTable: 'Generando tabla...',
      current: 'Datos actuales',
      deleteOldErr: 'Las nuevas estadístcas se guardaron exitosamente pero ocurrió un error al eliminar las anteriores, intenta de nuevo por favor',
      deleteSuccess: 'Estadísticas del indicador eliminadas exitosamente',
      download: 'Descarga la Plantilla de Datos',
      dropzone: 'Datos del Indicador',
      dropzoneInfo: 'Datos de Información',
      duplicated: 'está repetido',
      error: 'Error',
      excel: 'Archivo de Excel',
      headerError: 'El encabezado debe de ser de la siguiente forma:',
      instruction: 'Localiza el archivo de excel con los datos del indicador',
      instructionInfo: 'Localiza el archivo de excel con los datos de información',
      invalid: 'Valor no válido en la celda',
      invalidMonthName: 'Debe ser el nombre del mes (enero, febrero, etc.)',
      invalidPlaceCode: 'Debe ser la clave del estado (AGS, BCA, etc.)',
      invalidYear: 'Año no válido',
      loadingRecords: 'Cargando estadísticas...',
      mustBeInteger: 'Debe ser un número entero',
      mustBeNumeric: 'Debe ser un valor numérico',
      noData: 'No hay valores para validar',
      page: 'Página',
      placesError: 'No se encontraron los siguientes lugares:',
      readError: 'Ocurrió un error al leer los datos',
      recordExists: 'Ya existe el registro de la fila',
      required: 'es requerido',
      reset: 'Reestablecer',
      saveDelete: 'Se eliminarán las estadísticas de este indicador.',
      saveSuccess: 'Estadísticas del indicador actualizadas exitosamente',
      saveUpdate: 'Al subir nuevos datos, se eliminarán las estadísticas anteriores de este indicador.',
      shouldBeLast: 'El campo con los valores debe ser el último',
      title: 'Paso 2: Estadísticas del indicador',
      validating: 'Validando...',
      valueTooBig: 'El valor es demasiado grande',
    }
  },
  info: {
    abreviations: 'Abreviaciones',
    altExcel: 'Descarga los datos en archivo de Excel',
    altPdf: 'Descarga los datos en archivo de PDF',
    download: 'Descarga de datos:',
    export: 'Exportar imagen',
    exportBreakdown: 'Exportar datos desglosados',
    exportPDF: 'Exportar PDF',
    exportRanking: 'Exportar Ranking',
    formula: 'Fórmula',
    index: 'INICIO',
    month: 'Mes',
    news: 'NOTICIAS',
    noData: 'Sin datos',
    noFrequencies: 'No existen frecuencias de medición',
    noTag: 'No existe información',
    number: 'No',
    period: 'Año',
    ranking: 'Ranking Nacional',
    seeAbreviations: 'Ver Abreviaciones',
    seeLess: 'Ver Menos',
    seeMore: 'Ver Más',
    selectItem: 'Selecciona algún rubro',
    sources: 'Fuentes',
    state: 'Estado',
    statistics: 'ESTADÍSTICAS',
    totalXls: 'Total',
    total: 'Nivel Federal: ',
    totalState: 'Nivel Estatal: ',
    totalESE: 'Entidad No Especificada: ',
    tryAgain: 'Intentar de nuevo.',
    urlNotFound: 'No encontramos resultados con el enlace compartido.',
    year: 'Año',
  },
  items: {
    create: 'CREAR RUBRO',
    deleteBody: 'Los indicadores que pertenecen a este rubro ya no se mostrarán en el portal de estadísticas.',
    deleteTitle: '¿Deseas borrar: ',
    elimDefaultError: 'Lo sentimos, ocurrió un error al eliminar el rubro',
    elimSuccess: 'Rubro eliminado exitosamente',
    noData: 'No hay rubros disponibles',
    note1: 'El nombre mostrado es el corto.',
    note2: 'En los rubros que aparece \'No\' significa que son del tipo que no contienen indicadores y en su lugar se muestra un título, contenido e imágenes.',
    notFound: 'Rubro no encontrado',
    reorderSuccess: 'Cambios guardados exitosamente',
    reorderError: 'Lo sentimos, ocurrió un error al guardar los cambios',
    title: 'Rubros',
    selectedItem: 'Rubro:',
    save: {
      createSuccess: 'Rubro creado exitosamente',
      content: 'Contenido',
      contentAdv: 'Escribe aquí el contenido del rubro',
      contentLength1: 'El contenido no puede exceder los ',
      contentLength2: ' caracteres: Actualmente hay ',
      cover: 'Selecciona una imagen de portada',
      coverAdv: 'Elige una imagen de portada (Max. 1 imagen)',
      desc: 'Descripción',
      descAdv: 'Ingresa una breve descripción del Rubro',
      edit: 'Editar Rubro',
      hasInd: 'Rubro con Indicadores',
      hasIndAdv: 'Elige si el rubro tendrá indicadores',
      name: 'Nombre',
      nameAdv: 'Ingresa el nombre completo del rubro',
      nameExists: 'El nombre completo del rubro ya existe',
      new: 'Nuevo Rubro',
      pictures: 'Selecciona tus imágenes',
      picturesAdv: 'Elige una imagen para el carrusel (Max. 3 imágenes)',
      picturesError: 'Ocurrió un error al guardar las imágenes del rubro',
      saveSuccess: 'Rubro actualizado exitosamente',
      shortName: 'Nombre Corto',
      shortNameAdv: 'Ingresa el nombre del rubro que aparecerá en la barra lateral',
      shortNameExists: 'El nombre corto del rubro ya existe',
      sizeError: ' excede el tamaño límite de 5 MB',
      titleField: 'Título',
      titleFieldAdv: 'Ingresa el título del rubro',
      typeError: ' no es una extensión válida',
      warning: 'Los indicadores que pertenecen a este rubro ya no se mostrarán en el portal de estadísticas.',
    }
  },
  login: {
    addEmail: 'Ingresa tu correo electrónico',
    backLogin: 'Regresar a Login',
    change: 'Cambiar Contraseña',
    changeDefaultPasword: 'Change default password',
    email: 'Correo electrónico',
    emailNotRegistered: 'email not registered',
    emailSent: 'Correo electrónico enviado',
    incorrectPassword: 'Incorrect password',
    login: 'Iniciar Sesión',
    message: 'Ingresa tu correo electrónico y contraseña',
    newPassword: 'Ingresa tu nueva contraseña',
    password: 'Contraseña',
    recover: 'Recuperar Contraseña',
    saveNewPassword: 'Reestablecer Contraseña',
    saveError: 'No se pudo reestablecer su contraseña',
    saveSuccess: 'Contraseña reestablecida correctamente',
    sendEmail: 'Enviar Correo',
    welcome: 'Bienvenido',
    wrongCredentials: 'El correo electrónico o contraseña es inválido',
  },
  users: {
    create: 'CREAR USUARIO',
    deleteTitle: '¿Deseas borrar a: ',
    elimSuccess: 'Usuario eliminado exitosamente',
    elimDefaultError: 'Lo sentimos, ocurrió un error al eliminar el usuario',
    noConnection: 'Sin conexión',
    notFound: 'Usuario no encontrado',
    noUsers: 'No hay usuarios disponibles',
    title: 'Usuarios',
    save: {
      changeBody: 'No se guardarán tus cambios.',
      createSuccess: 'Usuario creado exitosamente',
      contentAdmin: 'Administrador de Contenido',
      edit: 'Editar Usuario',
      email: 'Correo electrónico',
      emailAdv: 'Ingresa el correo electrónico del usuario',
      emailExists: 'El correo electrónico ya existe',
      lastAccess: 'Último Acceso',
      lastName: 'Apellidos',
      lastNameAdv: 'Ingresa los apellidos del usuario',
      name: 'Nombre',
      nameAdv: 'Ingresa el nombre del usuario',
      new: 'Nuevo Usuario',
      org: 'Organización',
      orgAdv: 'Organización a la que pertenece el usuario',
      role: 'Rol',
      roleAdv: 'Selecciona el rol que tendra el usuario',
      saveSuccess: 'Usuario actualizado exitosamente',
      superAdmin: 'Super Administrador',
    },
  },
  validations: {
    defaultError: 'Existen errores en el formulario',
    email: 'Correo electrónico inválido',
    maxLength1: 'El campo no puede tener más de ',
    maxLength2: ' caracteres',
    onlyOne: 'Selecciona solo un tipo de dato',
    required: 'Campo requerido',
    number: 'Campo debe ser númerico'
  },
  help: {
    close: 'Cerrar',
    altImages: 'Ayuda rápida para visor de estadísticas',
    cookieName: 'help',
    helpTooltip: 'Ayuda Rápida',
    cookieExpire: 'expires=',
    cookiePath: ';path=/',
    cookieEqual: '=',
    cookiecomma: ';'
  },
  a11: {
    selectIndicator: 'Selecciona un indicador',
    selectYear: 'Selecciona un año'
  }
});
