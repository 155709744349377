import React, { Component } from 'react';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';

//Components
import AdminPanel from './AdminPanel';
import AlertTemplate from './components/Alert';
import { Orientation } from 'components/Orientation';

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
}

class AdminContainer extends Component {

  render() {
    return (
      <>
        <Orientation />
        <AlertProvider
          template={AlertTemplate}
          containerStyle={{ zIndex: 10000 }}
          {...options}
        >
          <AdminPanel {...this.props} />
        </AlertProvider>
      </>
    )
  }
}

export default AdminContainer;
