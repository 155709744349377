import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Map as MapGraph, GeoJSON, withLeaflet } from 'react-leaflet';
import { ChevronsDown } from "react-feather";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Control from 'react-leaflet-control';
import L from 'leaflet';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

// helpers
import { getFormattedValue } from 'helpers/get-formatted-value';
import { getMapZoom } from 'helpers/get-map-zoom';
import { rgbToHex } from 'helpers/convert-colors';
import { getSpecialTags } from 'helpers/get-special-tags';

/** Constants */
import { D } from 'constants/dictionary';
import { minColorRGB, STR_GEOJSON_NO_DATA_PREFIX } from 'constants/general-constants';
import { geojsonMexico } from 'constants/geojson-mexico';
import * as MAP_CONSTANTS from 'constants/map-constants';

/** Components */
import PrintControlDefault from 'components/PrintControl';
import StatePopup from './StatePopUp';

/** Styles */
import s from "styles/scenes/theme.module.scss";
import 'styles/components/map.scss';

const PrintControl = withLeaflet(PrintControlDefault);

const POSITION = [MAP_CONSTANTS.MX_LAT, MAP_CONSTANTS.MX_LNG];
const INITIAL_ZOOM = getMapZoom();

const MAX_BOUNDS= [
  MAP_CONSTANTS.TOP_LEFT_CORNER,
  MAP_CONSTANTS.BOTTOM_RIGHT_CORNER
];

const mapStyle = {
  width: "100%",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.15)"
}

/** Functional component */
const Mexico = ({
  indicator,
  legendValues,
  legendColors,
  missingData,
  seeMore = true,
  period,
  statesByHash,
  strForGeoJSON,
  totalCountry,
  totalState,
  totalESE
}) => {
  const mapRef = useRef(null);
  const geoJSONRef = useRef(null);
  const [hideClasses, setHideClasses] = useState([]);
  let totalCountryTag = '';
  let totalStateTag = '';
  let totalESETag = '';

  if(indicator.hasSpecialTags && indicator.tags){
    let tagMap = getSpecialTags(indicator.tags)

    totalCountryTag = tagMap.get(totalCountry) || D.info.noTag
    totalStateTag = tagMap.get(totalState) || D.info.noTag
    totalESETag = tagMap.get(totalESE) || D.info.noTag
  }
  useEffect(() => {
    const classes = [
      'leaflet-control-zoom-in',
      'leaflet-control-zoom-out',
      'leaflet-control-easyPrint-button-export CurrentSize',
    ];

    if (seeMore) {
      classes.push(s.__seemore)
    }

    setHideClasses(classes);
  }, [seeMore]);

  /**
   * Style each state on the map.
   *
   * Find the total amount for each state and search in the legendValues which
   * index should be used to get the correct color.
   */
  const style = useCallback((feature) => {
    const code = feature.properties.codigo;
    const stateData = statesByHash[code];

    let opacity = 1;
    let fillColor = MAP_CONSTANTS.NO_DATA_COLOR;
    if (stateData && legendValues.length && legendColors.length) {
      const total = stateData.totalAmount;
      if(indicator.hasSpecialTags){
        for (let i = 0; i < indicator.tags.length; i++) {
          if (legendValues[i] <= total && legendValues[i + 1] >= total) {
            const hexColor = rgbToHex(legendColors[i]);
            fillColor = hexColor;
            opacity = 0.7;
            break;
          }
        }
      } else{
        for (let i = 0; i < legendValues.length - 1; i++) {
          if (legendValues[i] <= total && legendValues[i + 1] >= total) {
            const hexColor = rgbToHex(legendColors[i]);
            fillColor = hexColor;
            opacity = 0.7;
            break;
          }
        }
      }
    }

    return {
      fillColor: fillColor,
      weight: 0.3,
      opacity: opacity,
      color: '#000',
      fillOpacity: 0.7
    };
  }, [statesByHash, legendColors, legendValues, indicator]);

  /**
   * Add css rule to initialize values for path so the transition
   * can be triggered when style callback runs.
   *
   * Without the timeout the transition won't be
   * able to run.
   * 50 ms after the css rule is added to fill
   * the color of the path attribute, remove it to
   * run the transition in map.scss
   */
  useEffect(() => {
    // If the content was rendered but there is no data
    // ignore the animation. This helps to not display
    // the effect several times when setting new data
    if (strForGeoJSON.includes(STR_GEOJSON_NO_DATA_PREFIX)) {
      return;
    }

    const sheet = document.styleSheets[0];
    if (sheet.cssRules[0].selectorText !== "path") {
      const sheet = document.styleSheets[0];
      const color = rgbToHex(minColorRGB.join(','));
      sheet.insertRule(
        `path { fill: ${color}; stroke: ${color}; }`
        , 0);
    }

    setTimeout(() => {
      if (sheet.cssRules[0].selectorText === "path") {
        sheet.deleteRule(0)
      }
    }, 350);
  }, [strForGeoJSON]);

  /**
   * Add event listeners on each feature.
   *
   * Find the state data and add a popup.
   *
   * If the feature has no data, display message in popup.
   */
  const onEachFeature = useCallback((feature, layer) => {
    layer.on({
      mouseover: onMouseOver,
      mouseout: onMouseOut,
    });

    const popup = document.createElement('div');
    const stateData = statesByHash[feature.properties.codigo];

    let name = '';
    let total = 0;
    let error = false;
    let res = {}
    if (stateData) {
      name = stateData.state.name;
      if(indicator.hasSpecialTags){
        res = indicator.tags.find(tag => {
          return parseInt(tag.index) === stateData.totalAmount
        })
        total = res ? res.name : D.info.noTag
      }
      else{
        total = stateData.totalAmount;
      }
    } else {
      // if no data available, display the state name in GeoJSON
      name = feature.properties.estado;
      error = true;
    }
    layer.bindPopup(() => {
      ReactDOM.render(
        <StatePopup 
          asCoin={indicator.recordsAsCoin} 
          asPercentage={indicator.recordsAsPercentage} 
          hasSpecialTags={indicator.hasSpecialTags} 
          name={name} 
          total={total} 
          error={error} />,
        popup
      );
      return popup.innerHTML;
    });
  }, [statesByHash, indicator.recordsAsCoin, indicator.recordsAsPercentage, indicator.hasSpecialTags, indicator.tags]);

  /**
   * Display popup.
   */
  const onMouseOver = (e) => {
    const layer = e.target;
    layer.openPopup();

    layer.setStyle({
      weight: 1,
      fillOpacity: 1
    });
  };

  const onMouseOut = (e) => {
    geoJSONRef.current.leafletElement.resetStyle(e.target);
  };

  /** Render Map control */
  useEffect(() => {

    if (!indicator._id || missingData === undefined) {
      return;
    }

    const currentRef = mapRef.current;

    var legend = L.control({ position: "bottomleft" });
    legend.onAdd = function () {
      var div = L.DomUtil.create("div", "info legend");
      div.innerHTML = `<p class="webkit-box-orient">${indicator.shortName}</p>`
      
     
      if (indicator.hasSpecialTags && indicator.tags.length) {
        let sortedTags = indicator.tags.sort((a, b) => (a.index > b.index) ? 1 : -1)
        for (let i = indicator.tags.length - 1; i >= 0; i--) {
          div.innerHTML += '<i style="background:' +
          `rgb(${legendColors[i]})` +
            '"></i><span style="word-break: break-all">' +
            sortedTags[i].name +
            "</span>" +
            "<br>";
        }
      } else {
        for (let i = legendValues.length - 2; i >= 0; i--) {
          div.innerHTML +=
            '<i style="background:' +
            `rgb(${legendColors[i]})` +
            '"></i><span>' +
            getFormattedValue(
              legendValues[i],
              2,
              indicator.recordsAsPercentage,
              indicator.recordsAsCoin
            ) +
            (i === legendValues.length - 1
              ? "+"
              : " &ndash; " +
                getFormattedValue(
                  legendValues[i + 1],
                  2,
                  indicator.recordsAsPercentage,
                  indicator.recordsAsCoin
                )) +
            "</span>" +
            "<br>";
        }
      }

      // Add legend at the bottom when there is missing info on the map
      if (missingData) {
        div.innerHTML += `<i style="background: ${MAP_CONSTANTS.NO_DATA_COLOR}"></i> ${D.info.noData}`;
      }

      return div;
    };

    legend.addTo(currentRef.leafletElement);

    /** If the legend data is generated dynamically the control should be removed before the new effect */
    return () => {
      currentRef.leafletElement.removeControl(legend);
    };
  }, [legendValues, legendColors, indicator, missingData]);

  return (
    <>
    <MapGraph
      ref={mapRef}
      style={mapStyle}
      center={POSITION}
      zoom={INITIAL_ZOOM}
      zoomSnap={0}
      zoomDelta={0.25}
      maxBounds={MAX_BOUNDS}
      animate
      scrollWheelZoom={false}
    >
      {seeMore && (
        <Control position="bottomright">
          <AnchorLink className={s.__seemore} offset="125" href="#summary">
            <small>{D.info.seeMore}</small>
            <ChevronsDown color="#eaeaea" size={18} />
          </AnchorLink>
        </Control>
      )}
      <GeoJSON
        key={strForGeoJSON}
        ref={geoJSONRef}
        data={geojsonMexico}
        style={style}
        onEachFeature={onEachFeature}
      />
      {totalCountry !== undefined &&
        <Control position="topright">
          <div className="info total">
            {D.info.total} <span style={{ fontWeight: 600 }}>{indicator.hasSpecialTags ? totalCountryTag : getFormattedValue(totalCountry, 2, indicator.recordsAsPercentage, indicator.recordsAsCoin)}</span>
          </div>
        </Control>
      }
      {totalState !== undefined &&
        <Control position="topright">
          <div className="info total--state">
            {D.info.totalState} <span style={{ fontWeight: 600 }}>{indicator.hasSpecialTags ? totalStateTag : getFormattedValue(totalState, 2, indicator.recordsAsPercentage, indicator.recordsAsCoin)}</span>
          </div>
        </Control>
      }
      {totalESE !== undefined &&
        <Control position="topright">
          <div className="info total--state">
            {D.info.totalESE} <span style={{ fontWeight: 600 }}>{indicator.hasSpecialTags ? totalESETag : getFormattedValue(totalESE, 2, indicator.recordsAsPercentage, indicator.recordsAsCoin)}</span>
          </div>
        </Control>
      }
      {
        indicator._id && (
          <>
          <PrintControl
            key={indicator._id}
            filename={indicator._id ? indicator.shortName : 'map'}
            position="topleft"
            title={D.info.export}
            hideControlContainer={false}
            hideClasses={hideClasses}
            exportOnly />
          {period && (
            <Control position="topright">
              <div className="info total">
                {D.info.period}: <span style={{ fontWeight: 600 }}>{period}</span>
              </div>
            </Control>
          )}
          </>
        )
      }
    </MapGraph>
      </>
  );
};

Mexico.propTypes = {
  indicator: PropTypes.object.isRequired,
  legendValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  legendColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  missingData: PropTypes.any,
  period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  seeMore: PropTypes.bool,
  statesByHash: PropTypes.object.isRequired,
  strForGeoJSON: PropTypes.string.isRequired,
  totalCountry: PropTypes.any,
  totalState: PropTypes.any,
  totalESE: PropTypes.any
}

export default Mexico;
