import XLSX from 'xlsx';

// Modified from https://www.npmjs.com/package/react-excel-renderer
/**
 * When the excel has been loaded, get data.
 *
 * @param {File} file
 * @param {number} sheet
 * @param {Function} callback Execute after data has been parsed.
 */
export const excelRenderer = (file, sheet, asPercentage, callback) => {
  return new Promise(function(resolve, reject) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = function(e) {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      /* Get worksheet */
      const wsname = wb.SheetNames[sheet];
      const ws = wb.Sheets[wsname];

      if (!ws) {
        reject('Not an excel file');
        return callback('Not an excel file');
      }


      // Get the range, object with number of columns and rows
      const range = XLSX.utils.decode_range(ws['!ref']);

      const numberOfCols = range.e.c;

      // Get columns
      const excelCols = make_cols(numberOfCols);
      const data = { cols: excelCols };

      // Array that will keep all the data as an array of arrays
      const rows = [];

      // Array that will have the values of each row
      let row;
      for(let R = range.s.r; R <= range.e.r; ++R) {
        row = [];
        for(let C = range.s.c; C <= numberOfCols; ++C) {
          // construct reference for cell
          const cellref = XLSX.utils.encode_cell({c:C, r:R});

          // If the cell has no value, set undefined, so it can be edited
          if(!ws[cellref]) {
            row[C] = { value: undefined };
            continue;
          }

          // Add the cell value to the array
          const cell = ws[cellref];
          let value = cell.v;

          // Check if the value should be a percentage
          if (C === numberOfCols && asPercentage && R > range.s.r) {
            const parsedValue = parseFloat(cell.v);
            if (!isNaN(parsedValue)) {
              value = parsedValue * 100;
            }
          }
          row[C] = { value };
        }

        // Add the array to the row
        rows[R] = row;
      }

      // Add rows to response object
      data.rows = rows;

      return callback(null, data);
    };

    if (file && rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
}

/**
 * Generate the array that will work as the excel
 * headers: (A, B, C, ...)
 *
 * @param {object} numberOfCols The number of columns detected.
 */
function make_cols(numberOfCols) {
  const headers = [],
    C = numberOfCols + 1;
  for (let i = 0; i < C; ++i) {
    headers[i] = { name: XLSX.utils.encode_col(i), key: i };
  }
  return headers;
}
