import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import { createUserCancelToken, getUserCancelToken, saveUserCancelToken } from 'api/user-api'

// actions
import { displayAlert } from 'actions/admin-actions';
import * as UserActions from 'actions/user-actions';

// constants
import { D } from 'constants/dictionary';
import { USERS } from 'constants/routes-constants';
import * as ALERT_TYPES from 'constants/alert-types';

import SaveUser from './SaveUser'


class SaveUserContainer extends Component {
  constructor(props) {
    super(props);
    const { match: { path, params: { id } } } = props;
    this.state = {
      editMode: path.includes('edit'),
      selectedId: id,
      serverErrors: [],
      initialState: undefined,
      showChangesModal: false,
    }
  }

  componentWillUnmount() {
    createUserCancelToken && createUserCancelToken.cancel(D.errors.requestCanceled);
    saveUserCancelToken && saveUserCancelToken.cancel(D.errors.requestCanceled);
    getUserCancelToken && getUserCancelToken.cancel(D.errors.requestCanceled);
  }

  componentDidMount() {
    if (this.props.savingUser || this.props.loadingOneUser) {
      this.props.setSavingUser({
        savingUser: -1,
        loadingOneUser: -1
      });
    }

    if (this.state.editMode) {
      const { selectedId } = this.state;
      if (selectedId) {
        this.props.getUser(selectedId);
      } else {
        this.displayAlertAndGoBack(
          D.users.notFound,
          3000,
          ALERT_TYPES.WARNING,
        );
      }
    } else {
      this.setState({ initialState: JSON.stringify(this.props.formValues) });
    }
  }

  didUpdateOnSave = (prevProps) => {
    const userSaved = (prevProps.savingUser === 1) && (this.props.savingUser === 0);

    if (!userSaved) {
      return;
    }

    const { errorSavingUser } = this.props;
    const serverErrors = [];
    if (!errorSavingUser) {
      const { editMode } = this.state;
      this.displayAlertAndGoBack(
        editMode ? D.users.save.saveSuccess : D.users.save.createSuccess,
        3000,
        ALERT_TYPES.SUCCESS,
      );
    } else {
      const { response } = errorSavingUser;
      if (response) {
        switch (response.status) {
          case 404:
            this.displayAlertAndGoBack(
              D.users.notFound,
              5000,
              ALERT_TYPES.WARNING,
            );
            break;
          case 409:
            serverErrors.push(D.users.save.emailExists)
            break;
          default:
            serverErrors.push(D.errors.serverError);
        }
      } else {
        serverErrors.push(D.errors.noConnection);
      }
      this.setState({ serverErrors });
    }
  }

  displayAlertAndGoBack = (message, timeout, type) => {
    this.props.displayAlert({
      message,
      timeout,
      type
    });
    this.props.history.replace(USERS);
  }

  didUpdateOnLoad = (prevProps) => {
    const userLoaded = (prevProps.loadingOneUser === 1) && (this.props.loadingOneUser === 0);

    if (!userLoaded) {
      return;
    }

    const { errorLoadingOneUser } = this.props;
    if (!errorLoadingOneUser) {
      const user = this.props.selectedUser;
      const initObject = {
        email: user.email,
        name: user.name,
        lastName: user.lastName,
        organization: user.organization,
        role: user.role,
      };

      this.props.initialize(initObject);
      this.setState({ initialState: JSON.stringify(initObject) });
    }
    else {
      let message = D.errors.loadInfo;
      const { response } = errorLoadingOneUser;
      if (response) {
        if (response.status === 404) {
          message = D.users.notFound;
        }
      }
      this.displayAlertAndGoBack(
        message,
        5000,
        ALERT_TYPES.WARNING
      );
    }
  }

  componentDidUpdate(prevProps) {
    this.didUpdateOnSave(prevProps);
    this.didUpdateOnLoad(prevProps);
  }

  onSubmit = (values) => {
    const { editMode, selectedId } = this.state;
    const { formValues, history } = this.props;

    if (this.state.initialState === formValues) {
      history.replace(USERS);
      return;
    }
    let user = {
      ...values
    }

    this.setState({ serverErrors: [] });
    if (selectedId && editMode) {
      this.props.saveUser(selectedId, user);
    } else {
      this.props.createUser(user);
    }
  };

  onCancelChanges = () => {
    const { formValues, history } = this.props;
    // there were no changes on the form
    if (this.state.initialState === formValues) {
      history.replace(USERS);
    } else {
      this.setState({ showChangesModal: true })
    }
  };

  hideChangesModal = () => this.setState({ showChangesModal: false });

  onAcceptChanges = () => this.props.history.replace(USERS);

  render() {
    const {
      editMode,
      serverErrors,
      showChangesModal
    } = this.state;

    const {
      loadingOneUser,
      savingUser,
      submitFailed,
      submitSucceeded,
      handleSubmit
    } = this.props;
    return (
      <SaveUser
        editMode={editMode}
        handleSubmit={handleSubmit}
        hideChangesModal={this.hideChangesModal}
        loadingOneUser={loadingOneUser}
        onAcceptChanges={this.onAcceptChanges}
        onCancelChanges={this.onCancelChanges}
        onCancelClick={() => this.props.history.replace(USERS)}
        onSubmit={this.onSubmit}
        savingUser={savingUser}
        serverErrors={serverErrors}
        showChangesModal={showChangesModal}
        submitFailed={submitFailed}
        submitSucceeded={submitSucceeded}
      />
    )
  }
}

const form = reduxForm({
  form: 'saveUser',
  initialValues: {
    role: D.users.save.contentAdmin
  }
});

SaveUserContainer.propTypes = {
  createUser: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  errorLoadingOneUser: PropTypes.any,
  errorSavingUser: PropTypes.any,
  formValues: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired,
  loadingOneUser: PropTypes.number,
  match: PropTypes.object.isRequired,
  saveUser: PropTypes.func.isRequired,
  savingUser: PropTypes.number.isRequired,
  selectedUser: PropTypes.object,
  setSavingUser: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
}
const mapStateToProps = (state) => {
  const selector = formValueSelector('saveUser');
  return {
    formValues: JSON.stringify(selector(state,
      'email',
      'name',
      'lastName',
      'organization',
      'role',
    )),
    errorSavingUser: state.userReducer.errorSavingUser,
    loadingOneUser: state.userReducer.loadingOneUser,
    savingUser: state.userReducer.savingUser,
    selectedUser: state.userReducer.selectedUser,
    errorLoadingOneUser: state.userReducer.errorLoadingOneUser
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createUser: (user) => dispatch(UserActions.addUser(user)),
    displayAlert: (alert) => dispatch(displayAlert(alert)),
    getUser: (id) => dispatch(UserActions.getUser(id)),
    saveUser: (id, user) => dispatch(UserActions.saveUser(id, user)),
    setSavingUser: (status) => dispatch(UserActions.clearLoadingUser(status)),
  }
}

export default compose(
  form,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SaveUserContainer);
