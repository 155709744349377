import * as AdminActions from 'constants/admin-constants';

/**
 * Action dispatched to change the value of the string used
 * to trigger an alert.
 *
 * @param {object} alert
 * 
 * Alert should have the next parameters:
 * id: {string}
 * message: {string}
 * timeout: {number} in miliseconds
 * type: {string}
 */
export const displayAlert = (alert) => {
  return {
    type: AdminActions.DISPLAY_ALERT,
    payload: {
      id: Math.random().toString(36),
      ...alert
    }
  }
}