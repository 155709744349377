import React from 'react';

// components
import NotFound from 'components/NotFound';

const GeneralNotFound = (props) => {

  return (
    <NotFound {...props} />
  );
}

export default GeneralNotFound;
