/**
 * Return array with length = steps + 1 (because the min value is added).
 *
 * Validate thar there are no duplicated numbers, just in case the steps number is too big.
 *
 * @param {number} min Min value in range.
 * @param {number} max Maxi value in range.
 * @param {number} steps Number of items between range + 1
 *
 * @returns {Array} Return array with intervals.
 * @example getLegendValues(10, 20, 2) => [10, 15, 20]
 */
export const getLegendValues = (min, max, steps) => {
  if (min > max) {
    return [];
  }

  let round = false;

  if (max - min > 500) {
    round = true;
  }

  const result = [min];
  for (let i = 1; i <= steps; i++) {
    if (i === steps) {
      result.push(max);
      break;
    }

    let n = min + i * (max - min) / steps;
    if (round) {
      n = Math.round(n);
    }
    if (!result.includes(n)) {
      result.push(n);
    }
  }

  return result;
}

/**
 * Function to get an array with rgb values betwen minColor and maxColor
 * given the variable steps.
 *
 * @param {Array} minColor Array with rgb values
 * @param {Array} maxColor Array with rgb values
 * @param {number} steps The number of values in the returned array.
 *
 * @returns {Array} Return array with string rgb values between minColor
 * and maxColor (including them).
 * @example getLegendColors([0, 255, 0], [255, 0, 0], 3) => ["0,255,0", "128,128,0", "255,0,0"]
 */
export const getLegendColors = (minColor, maxColor, steps) => {
  if (steps < 3) {
    return [minColor.join(','), maxColor.join(',')];
  }

  const t = 1 / (steps - 1);

  const result = [];
  for (let i = 0; i < steps; i++) {
    result.push(interpolateColor(minColor, maxColor, t * i));
  }

  return result;
}

/**
 * Function to return rgb value betwen min and max colors, given t.
 *
 * From https://www.alanzucconi.com/2016/01/06/colour-interpolation/
 * c = a + (b - a) * t
 * Where:
 * a: min color
 * b: max color
 * t: value between 0 and 1
 *
 * @param {Array} minColorArray Array with rgb values
 * @param {Array} maxColorArray Array with rgb values
 * @param {number} t Value between 0 and 1
 *
 * @returns {string} RGB value between min and max colors
 */
const interpolateColor = (minColorArray, maxColorArray, t) => {
  var result = [];
  for (let i = 0; i < 3; i++) {
    result.push(Math.round(minColorArray[i] + (maxColorArray[i] - minColorArray[i]) * t));
  }

  return result.join(',');
}
