import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// styles
import 'styles/components/slider.scss';

const Slider = ({
  images
}) => {

  return (
    <Carousel
      swipeable
      emulateTouch
      showIndicators={false}
      showStatus={false}
      infiniteLoop
    >
      {
        images.map((i, index) => {
          return (
            <div key={index}>
              <img src={i} alt="slider" />
            </div>
          );
        })
      }
    </Carousel>
  );
}

Slider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Slider;
