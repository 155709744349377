import React from "react";
import PropTypes from "prop-types";

// styles
import i from "styles/components/indicator-info.module.scss";
import styles from "styles/components/text.module.scss";

const SmallContent = ({ text, italic }) => {
  return (
    <>
      <section className={["__row", i.__content].join(" ")}>
        <section className="__col_xs_12">
          {italic ? (
            <p className={styles.__black}>
              <em>{text}</em>
            </p>
          ) : (
            <p className={styles.__black}>{text}</p>
          )}
        </section>
      </section>
    </>
  );
};

SmallContent.propTypes = {
  text: PropTypes.string.isRequired,
  italic: PropTypes.bool
};

export default SmallContent;
