import React from 'react'
import { PropTypes } from "prop-types";

import i from 'styles/components/input.module.scss';
import styles from './styles.module.scss';

export const TextArea = ({
  advice,
  input,
  label,
  meta: { error, submitFailed }
}) => {
  return (
    <>
    <section className={styles.lgt_textarea__bound}>
      <label className={styles.lgt_textarea__label} htmlFor={input.name}>{label}</label>
      <textarea
        {...input}
        name={input.name}
        className={styles.lgt_textarea}
      />
    </section>
    {submitFailed && error && <p className={i.lgt_input__error}>{error}</p>}
    {advice === "" ? null : <small className={styles.lgt_textarea__advice}>{advice}</small> }
    </>
  )
}



TextArea.propTypes = {
  advice: PropTypes.string,
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool,
  }),
}
