import React from "react";
import { PropTypes } from "prop-types";

import s from "styles/scenes/global.module.scss";

export const Canvas = props => {
  return (
    <section
      className={
        !props.isStatistics
          ? s.__canvas
          : [s["__canvas"], s["__canvas--nostats"]].join(" ")
      }
    >
      {props.children}
    </section>
  );
};

Canvas.propTypes = {
  children: PropTypes.any.isRequired,
  isStatistics: PropTypes.bool
};
