/**
 * Function to convert an rgb string values to hexadecimal.
 * 
 * @param {string} rgbString String of form 'value,value,value';
 * 
 * @returns Hexadecimal string from the rgbString provided.
 * @example rgbToHex('255,0,0') => '#ff0000'
 */
export const rgbToHex = (rgbString) => {
  const rgbArray = rgbString.split(',');
  return rgbArray.reduce((result, v) => {
    let hexValue = Number(v).toString(16);
    if (hexValue.length < 2) {
      hexValue = '0' + hexValue;
    }
    return result += hexValue;
  }, '#');
}

export const hexToRGB = (hex) => {
  const newHex = hex.replace('#', '');
  const values = newHex.match(new RegExp(`.{${newHex.length / 3}}`, 'g'));

  return values.reduce((rgb, v) => {
    return [...rgb, `0x${v}` | 0];
  }, []).join(', ');
}