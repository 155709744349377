import React from 'react'
import { PropTypes } from "prop-types";
import { Plus } from 'react-feather';
import { NavLink } from 'react-router-dom';

// Styles
import styles from './styles.module.scss'

export const FAB = (props) => {
  return (
    <section className={styles.fab__bound}>
      <small className={styles.fab__label}>{props.label}</small>
      <NavLink to={props.goTo} className={styles.fab__button}>
        <Plus size={24} color="#eaeaea"/>
      </NavLink>
    </section>
  )
}



FAB.propTypes = {
  goTo: PropTypes.string,
  label: PropTypes.string,
}
