export const SUCCESS_BG_COLOR = '#D4EDDA';
export const SUCCESS_COLOR = '#155724';

export const INFO_BG_COLOR = '#D1ECF1';
export const INFO_COLOR = '#0C5460';

export const DANGER_BG_COLOR = '#F8D7DA';
export const DANGER_COLOR = '#721C24';

export const WARNING_BG_COLOR = '#F7C227';
export const WARNING_COLOR = '#000';
