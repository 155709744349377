import * as ItemActions from 'constants/item-constants';

const initialState = {
  // get items
  errorLoadingItems: undefined,
  itemsById: [],
  itemsByHash: {},
  loadingItems: false,

  // save/edit item
  errorSavingItem: undefined,
  savingItem: 0,

  // get one item
  loadingOneItem: 0,
  errorLoadingOneItem: undefined,
  selectedItem: {},
}

export const itemReducer = (state = initialState, action) => {
  switch (action.type) {

    case ItemActions.GET_ITEMS_BEGIN:
      return {
        ...state,
        errorLoadingItems: undefined,
        loadingItems: true,
      }

    case ItemActions.GET_ITEMS_SUCCESS:
      return {
        ...state,
        loadingItems: false,
        itemsById: action.payload.map((item) => item._id),
        itemsByHash: action.payload.reduce((obj, item) => {
          return { ...obj, [item._id]: item };
        }, {}),
      }

    case ItemActions.GET_ITEMS_ERROR:
      return {
        ...state,
        errorLoadingItems: action.payload,
        loadingItems: false,
      }

    case ItemActions.REORDER_ITEMS:
      return {
        ...state,
        itemsById: action.payload.itemsById,
        itemsByHash: action.payload.itemsPositions.reduce((newItemsByHash, item) => {
          return {
            ...newItemsByHash,
            [item._id]: {
              ...state.itemsByHash[item._id],
              position: item.position
            }
          }
        }, {})
      }

    case ItemActions.SAVE_ITEM_BEGIN:
      return {
        ...state,
        savingItem: 1,
        errorSavingItem: undefined,
      }

    case ItemActions.CREATE_ITEM_SUCCESS: {
      const item = action.payload;
      return {
        ...state,
        savingItem: 0,
        itemsById: [...state.itemsById, item._id],
        itemsByHash: {
          ...state.itemsByHash,
          [item._id]: item
        }
      }
    }

    case ItemActions.SAVE_ITEM_ERROR:
      return {
        ...state,
        savingItem: 0,
        errorSavingItem: action.payload
      }

    case ItemActions.CLEAR_LOADING_ITEM: {
      const { payload } = action;
      return {
        ...state,
        savingItem: payload.savingItem,
        loadingOneItem: payload.loadingOneItem,
      }
    }

    case ItemActions.FIND_ITEM_BEGIN:
      return {
        ...state,
        selectedItem: {},
        loadingOneItem: 1,
        errorLoadingOneItem: undefined,
      }

    case ItemActions.FIND_ITEM_SUCCESS:
      return {
        ...state,
        selectedItem: action.payload,
        loadingOneItem: 0,
      }

    case ItemActions.FIND_ITEM_ERROR:
      return {
        ...state,
        loadingOneItem: 0,
        errorLoadingOneItem: action.payload,
      }

    case ItemActions.SAVE_ITEM_SUCCESS: {
      const item = action.payload;
      return {
        ...state,
        savingItem: 0,
        itemsByHash: {
          ...state.itemsByHash,
          [item._id]: item
        }
      }
    }

    case ItemActions.DELETE_ITEM_SUCCESS: {
      // eslint-disable-next-line no-unused-vars
      const { [action.payload]: _deleted, ...newItemsByHash } = state.itemsByHash;
      return {
        ...state,
        itemsById: state.itemsById.filter((id) => id !== action.payload),
        itemsByHash: newItemsByHash,
      }
    }

    default:
      return state;
  }
}
