import React from 'react';
import PropTypes from 'prop-types';

// constants
import { D } from 'constants/dictionary';

// helpers
import { getFormattedValue } from 'helpers/get-formatted-value';

const StatePopup = (props) => {
  return (
    <div className="popup-title">
      {`${props.name}: ${props.error ? D.errors.noData : (props.hasSpecialTags ? props.total : getFormattedValue(props.total, 2, props.asPercentage, props.asCoin))}`}
    </div>
  );
}

StatePopup.propTypes = {
  asCoin: PropTypes.bool.isRequired,
  asPercentage: PropTypes.bool.isRequired,
  hasSpecialTags: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  total: PropTypes.any.isRequired,
  error: PropTypes.bool.isRequired
}

export default StatePopup;
