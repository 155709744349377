import React, { Component } from 'react';
import { connect } from 'react-redux'
import { compose } from 'redux';
import { formValueSelector, Field, reduxForm } from 'redux-form'
import { Link } from 'react-router-dom'
import { login, clearState } from '../../actions/user-actions'
import * as Validate from '../../helpers/validations'
import PropTypes from 'prop-types'
import { loginCancelToken } from 'api/user-api'
// constants
import { D } from 'constants/dictionary';
import { RECOVER_PASSWORD, ADMIN_PANEL } from 'constants/routes-constants';


//Components
import { Loader } from 'components/Loader'
import Button from 'components/Button'
import LoginError from './components/LoginError'
import Textfield from './components/Textfield'

//Styles
import login_styles from 'styles/scenes/login/login.module.scss'
import Logo from 'assets/logo.png';


class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPasswordVisible: false,
      errorLabel: '',
    }
  }
  viewPassword = () =>
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })

  componentWillUnmount() {
    loginCancelToken && loginCancelToken.cancel(D.errors.requestCanceled);
  }

  componentDidMount() {
    if (this.props.user._id) {
      this.props.history.push(ADMIN_PANEL);
    }
    this.props.clearState();
  }

  componentDidUpdate(prevProps) {
    const isFinished = prevProps.loading && !this.props.loading;
    if (!isFinished) {
      return
    }

    const { loginError, errorMessage, token } = this.props;
    if (!loginError) {
      this.props.history.push(ADMIN_PANEL)
    } else if (errorMessage === D.login.changeDefaultPasword) {
      this.props.history.push({
        pathname: `/recover-password/${token}`,
        state: { defaultPass: true, mail: this.props.email },
      })
    } else {
      switch (errorMessage) {
        case D.login.incorrectPassword:
        case D.login.emailNotRegistered:
          this.setState({ errorLabel: D.login.wrongCredentials });
          break;
        case D.errors.noConnection:
          this.setState({ errorLabel: D.errors.noConnection });
          break;
        default:
          break;
      }
    }


  }
  onSubmit = (values) => {
    let user = {
      ...values
    }

    this.props.login(user)
  }



  render() {
    const { isPasswordVisible } = this.state
    const passwordType = isPasswordVisible ? 'text' : 'password'
    const { handleSubmit } = this.props;

    return (
      <main className={login_styles.__wrapper}>
        {this.props.loading && <Loader />}
        <section className="__wrapper">
          <section className="__row center_xs middle_xs">
            <section className="__col_xs_12 center-xs">
              <figure className={login_styles.__logo}>
                <img src={Logo} alt='logo' />
              </figure>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_12 center-xs">
              <h1>
                {D.login.welcome}
              </h1>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_12 center-xs">
              <p>
                {D.login.message}
              </p>
            </section>
          </section>
          <section className="__row center_xs">
            <section className="__col_xs_8 center-xs ">
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Field
                  name="email"
                  props={{
                    withIcon: true,
                    icon: 'mail',
                    input_type: 'text',
                    input_placeholder: D.login.email,
                  }}
                  component={Textfield}
                  validate={[Validate.required, Validate.email]} />


                <Field
                  name="password"
                  props={{
                    withIcon: true,
                    icon: 'lock',
                    viewPassword: true,
                    input_type: passwordType,
                    input_placeholder: D.login.password,
                    viewPassword_press: this.viewPassword,
                    viewPassword_icon: this.state.isPasswordVisible ? 'eyeoff' : 'eye'
                  }}
                  component={Textfield}
                  validate={[Validate.required]} />

                <section className={login_styles.__right}>
                  <Link to={{ pathname: RECOVER_PASSWORD, state: { email: this.props.email } }}>{D.login.recover}</Link>
                </section>
                <Button type='submit' login label={D.login.login} />
              </form>
              {this.props.loginError && this.state.errorLabel &&
                (
                  <LoginError label={this.state.errorLabel} />
                )}
            </section>
          </section>
        </section>

        <div className={login_styles.__footer}>
          <span className={login_styles.__footer__text}>LGT 2019 &copy;</span>
        </div>
      </main>
    )
  }
}

const form = reduxForm({
  form: 'login'
});

Login.propTypes = {
  history: PropTypes.object,
  email: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  errorStatus: PropTypes.number.isRequired,
  errorMessage: PropTypes.string,
  login: PropTypes.func.isRequired,
  loginError: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  token: PropTypes.string,
  clearState: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('login');
  return {
    email: selector(state, 'email'),
    user: state.authReducer.user,
    loginError: state.authReducer.loginError,
    errorStatus: state.authReducer.errorStatus,
    errorMessage: state.authReducer.errorMessage,
    token: state.authReducer.token,
    loading: state.authReducer.loading
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    login: (data) => dispatch(login(data)),
    clearState: () => dispatch(clearState())
  }
}

export default compose(
  form,
  connect(
    mapStateToProps,
    mapDispatchToProps)
)(Login)
