import React from 'react';
import { PlusCircle, X } from 'react-feather';
import PropTypes from 'prop-types';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';

// components
import Button from 'components/Button';
import Pages from './Pages';
import ServerErrors from 'scenes/admin/components/ServerErrors';

// constants
import { D } from 'constants/dictionary';

// styles
import 'styles/components/excel.scss';

const ExcelTable = ({
  cols,
  excelErrors,
  loading,
  onAddColumn,
  onAddRow,
  onCellsChanged,
  onDeleteColumn,
  onDeleteRow,
  pageAmount,
  pages,
  pageSelected,
  rows,
  selectedRows,
  setRows,
  validating,
}) => {

  const addColumnBtn = (index) => (
    <button
      onClick={() => onAddColumn(index)}
      className="__success __add_col_btn"
    >
      <PlusCircle color="#FFF" />
    </button>
  )

  const removeColumnBtn = (index) => (
    <button
      onClick={() => onDeleteColumn(index)}
      className="__danger __delete_col_btn"
    >
      <X color="#FFF" />
    </button>
  )

  const renderCols = () => (
    <>
      {cols.map((c, i) => (
        <th className="__header __cols" key={c.key}>
          {removeColumnBtn(i)}
          <span>{c.name}</span>
          {addColumnBtn(i)}
        </th>
      ))}
    </>
  )

  const renderTable = (p) => {

    return (
      <div>
        <table className="__excel">
          <thead>
            <tr>
              <th className="__header">
                {addColumnBtn(-1)}
              </th>
              {renderCols()}
            </tr>
          </thead>
          <tbody>
            {p.children}
          </tbody>
        </table>
      </div>
    );
  }

  const renderRow = (p) => {
    const canDelete = p.row !== 0 || pageSelected !== 0;
    const correctIndex = pageSelected * pageAmount + p.row;
    return (
      <tr>
        <td
          style={{ width: '80px' }}
          onClick={() => onDeleteRow(correctIndex, pageSelected)}
          className={['__header', `${canDelete ? '__row_index' : ''}`].join(' ')}
        >
          {canDelete && <button className="__danger __delete_row_btn"><X /></button>}
          <span>{correctIndex + 1}</span>
        </td>
        {p.children}
      </tr>
    );
  }

  return (
    <section className="__excel_wrapper __wrapper">
      {!!rows.length && !!cols.length && (
        <>
          {excelErrors && <ServerErrors cols={12} errors={excelErrors} />}
          {pages.length > 1 && (
            <Pages
              pages={pages}
              pageSelected={pageSelected}
              setRows={setRows}
            />
          )}
          <ReactDataSheet
            data={selectedRows}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={onCellsChanged}
            sheetRenderer={renderTable}
            rowRenderer={renderRow}
          />
          {pages.length > 1 && (
            <Pages
              pages={pages}
              pageSelected={pageSelected}
              setRows={setRows}
            />
          )}
          <section className="__row">
            <Button
              disabled={validating || loading}
              click={onAddRow}
              label={D.indicators.data.addRow}
            />
          </section>
        </>
      )}
    </section>
  );
}

ExcelTable.propTypes = {
  cols: PropTypes.array.isRequired,
  excelErrors: PropTypes.arrayOf(PropTypes.string).isRequired,
  loading: PropTypes.bool,
  onAddColumn: PropTypes.func.isRequired,
  onAddRow: PropTypes.func.isRequired,
  onCellsChanged: PropTypes.func.isRequired,
  onDeleteColumn: PropTypes.func.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  pageAmount: PropTypes.number.isRequired,
  pages: PropTypes.arrayOf(PropTypes.number).isRequired,
  pageSelected: PropTypes.number.isRequired,
  rows: PropTypes.array.isRequired,
  selectedRows: PropTypes.array.isRequired,
  serverErrors: PropTypes.arrayOf(PropTypes.string),
  setRows: PropTypes.func.isRequired,
  validating: PropTypes.bool,
}

export default ExcelTable;
