import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, change } from 'redux-form';
import PropTypes from 'prop-types';

// api
import * as Tokens from 'api/item-api';

// actions
import { displayAlert } from 'actions/admin-actions';
import * as ItemActions from 'actions/item-actions';

// constants
import { D } from 'constants/dictionary';
import { ITEMS } from 'constants/routes-constants';
import * as ALERT_TYPES from 'constants/alert-types';

// Components
import SaveItem from './SaveItem';

const MAX_CONTENT_LENGTH = 100000;
const MAX_SLIDER_LENGTH = 3;
const EMPTY_CONTENT = '<p><br></p>';
const DROPDOWN_TRUE = "true";
const DROPDOWN_FALSE = "false";

class SaveItemContainer extends Component {

  constructor(props) {
    super(props);

    const { match: { path, params: { id } } } = props;
    this.state = {
      content: '',
      cover: {},
      editMode: path.includes('edit'),
      pictures: [],
      coverRemoved: false,
      selectedId: id,
      sliderLength: 0,
      serverErrors: [],
      indicators: [],
      showAlertModal: false,
      showChangesModal: false,
      initialState: undefined,
    }
  }

  componentDidMount() {
    /**
     * If the reducer has data from a previous action that couldn't
     * properly finish.
     */
    if (this.props.savingItem || this.props.loadingOneItem) {
      this.props.setSavingItem({
        savingItem: -1,
        loadingOneItem: -1
      });
    }

    /**
     * If editing, load the data of the item and populate
     * the fields.
     */
    if (this.state.editMode) {
      const { selectedId } = this.state;
      // Search the item
      if (selectedId) {
        this.props.findItem(selectedId);
      } else {
        this.displayAlertAndGoBack(
          D.items.notFound,
          3000,
          ALERT_TYPES.WARNING,
        );
      }
    } else {
      const { content, cover, pictures } = this.state;
      const { formValues } = this.props;
      const initialState = this.getCurrentState(formValues, content, cover, pictures);
      this.setState({ initialState });
    }
  }

  /**
   * When the props change, check if an item was being saved.
   */
  didUpdateOnSave = (prevProps) => {
    const itemSaved = (prevProps.savingItem === 1) && (this.props.savingItem === 0);

    if (!itemSaved) {
      return;
    }

    const { errorSavingItem } = this.props;
    const serverErrors = [];
    if (!errorSavingItem) {
      const { editMode } = this.state;
      this.displayAlertAndGoBack(
        editMode ? D.items.save.saveSuccess : D.items.save.createSuccess,
        3000,
        ALERT_TYPES.SUCCESS,
      );
    } else {
      const { response } = errorSavingItem;
      if (response) {
        switch (response.status) {
          case 404:
            this.displayAlertAndGoBack(
              D.items.notFound,
              5000,
              ALERT_TYPES.WARNING,
            );
            break;
          case 409:
            response.data.errors.forEach((e) => {
              if (e.name) {
                serverErrors.push(D.items.save.nameExists);
              }

              if (e.shortName) {
                serverErrors.push(D.items.save.shortNameExists);
              }
            })
            break;
          default:
            serverErrors.push(D.errors.serverError);
        }
      } else {
        if (errorSavingItem.message.includes('images')) {
          this.displayAlertAndGoBack(
            D.items.save.picturesError,
            7000,
            ALERT_TYPES.WARNING
          );
        } else {
          serverErrors.push(D.errors.noConnection);
        }
      }

      this.setState({ serverErrors });
    }
  }

  displayAlertAndGoBack = (message, timeout, type) => {
    this.props.displayAlert({
      message,
      timeout,
      type
    });
    this.props.history.replace(ITEMS);
  }

  /**
   * If props change, check if the item was being loaded.
   */
  didUpdateOnLoad = (prevProps) => {
    const itemLoaded = (prevProps.loadingOneItem === 1) && (this.props.loadingOneItem === 0);

    if (!itemLoaded) {
      return;
    }

    const { errorLoadingOneItem } = this.props;
    if (!errorLoadingOneItem) {
      const item = this.props.selectedItem;

      const formObject = {
        name: item.name,
        shortName: item.shortName,
        description: item.description,
        hasIndicators: "" + item.hasIndicators,
        title: item.title
      }

      this.props.initialize(formObject);

      let newState = {
        indicators: item.indicators,
      }

      let content = '';
      let cover = {};
      let pictures = [];

      if (!item.hasIndicators) {
        pictures = item.sliderImages || [];
        cover = item.coverImage || {};
        content = item.content || '';

        newState = {
          ...newState,
          cover,
          pictures,
          content,
          sliderLength: pictures.length,
        };
      }

      newState = {
        ...newState,
        initialState: this.getCurrentState(formObject, content, cover, pictures)
      }

      this.setState(newState);
    } else {
      let message = D.errors.loadInfo;
      const { response } = errorLoadingOneItem;
      if (response) {
        if (response.status === 404) {
          message = D.items.notFound;
        }
      }
      this.displayAlertAndGoBack(
        message,
        5000,
        ALERT_TYPES.WARNING
      );
    }
  }

  /**
   * If the props change, check if the hasIndicators option was
   * changed.
   *
   * If the item has indicators and the option selected is No,
   * display alert to confirm the action.
   */
  didUpdateHasIndicators = (prevProps) => {
    if (prevProps.displayOtherOptions !== this.props.displayOtherOptions
      && this.state.editMode && this.props.selectedItem.indicators.length
      && this.props.displayOtherOptions) {
      this.setState({ showAlertModal: true });
    }
  }

  componentDidUpdate(prevProps) {
    this.didUpdateOnSave(prevProps);
    this.didUpdateOnLoad(prevProps);
    this.didUpdateHasIndicators(prevProps);
  }

  componentWillUnmount() {
    const { requestCanceled: rc } = D.errors;
    // cancel pending requests
    Tokens.createItemCT && Tokens.createItemCT.cancel(rc);
    Tokens.findItemCT && Tokens.findItemCT.cancel(rc);
    Tokens.saveItemCT && Tokens.saveItemCT.cancel(rc);
  }

  /**
   * Call action to change the value of the form.
   */
  setHasIndicators = () => {
    this.props.setHasIndicatorsValue(DROPDOWN_TRUE);
    this.hideAlertModal();
  }

  hideAlertModal = () => {
    this.setState({ showAlertModal: false });
  }

  /**
   * When the cover image is selected on the window file.
   */
  onDropCover = (pictureFiles, pictureDataURLs) => {
    if (pictureDataURLs.length) {
      this.setState({
        cover: {
          _id: Math.random().toString(36),
          data: pictureDataURLs[pictureDataURLs.length - 1],
        },
      });
    }
  }

  /**
   * When a new slider image is selected, save it and get the
   * sliderLength.
   */
  onDropImages = (pictureFiles, pictureDataURLs) => {
    if (pictureDataURLs.length) {
      const newPictures = [
        ...this.state.pictures,
        {
          _id: Math.random().toString(36),
          data: pictureDataURLs[pictureDataURLs.length - 1],
        }
      ];
      const sliderLength = this.getSliderLength(newPictures);
      this.setState({
        pictures: newPictures,
        sliderLength,
      });
    }
  }

  /**
   * Only count images that don't have the
   * removed property.
   */
  getSliderLength = (pictures) => {
    return pictures.filter(p => !p.removed).length;
  }

  /**
   * If the cover image is removed, verify if it's the
   * one stored in the api (has url property) and set coverRemoved.
   */
  removeCover = () => {
    const { cover } = this.state;
    let coverRemoved = false;

    if (cover.url) {
      coverRemoved = true;
    }

    this.setState({
      cover: {},
      coverRemoved
    });
  }

  /**
   * If a slider image is removed, verify if it's
   * stored in the api (has url property) and add the
   * 'removed' property.
   *
   * Calculate sliderLength
   */
  removeImage = (_id) => {
    const newPictures = this.state.pictures.reduce((pics, p) => {
      let obj = { ...p };
      // If it's the image selected
      if (p._id === _id) {
        // If is one already in the API
        if (p.url) {
          // Must be deleted
          obj = {
            ...obj,
            removed: true
          }
        } else {
          return pics;
        }
      }

      return [
        ...pics,
        obj
      ]
    }, []);

    this.setState({
      pictures: newPictures,
      sliderLength: this.getSliderLength(newPictures),
    });
  }

  /** Return a string with the current values of the form, content, cover and pictures */
  getCurrentState = (formValues, content, cover, pictures) => {
    if (content === EMPTY_CONTENT) {
      content = '';
    }
    return JSON.stringify({
      form: formValues,
      content,
      cover,
      pictures
    });
  }

  /**
   * When the form is successfully submitted, validate the content and images (if item has no indicators).
   *
   * If all is valid, save the item.
   */
  onSubmit = (values) => {
    const { content, cover, pictures, editMode, coverRemoved, selectedId, sliderLength, showAlertModal } = this.state;
    const { history } = this.props;

    // If the form has not changed, don't call endpoint to save
    if (!this.hasContentChanged()) {
      history.replace(ITEMS);
      return;
    }

    if (showAlertModal) {
      this.setState({ showAlertModal: false });
    }

    if (this.props.displayOtherOptions && (!this.isContentFilled(content) || content.length > MAX_CONTENT_LENGTH
      || sliderLength > MAX_SLIDER_LENGTH)) {
      return false;
    }

    const hasIndicators = values.hasIndicators === DROPDOWN_TRUE;
    let item = {
      ...values,
      hasIndicators,
    };

    if (!hasIndicators) {
      item = {
        ...item,
        cover: cover,
        content: content,
        images: pictures,
      }
    }

    if (editMode) {
      item = {
        ...item,
        coverRemoved
      }
    }

    this.setState({ serverErrors: [] });
    if (selectedId && editMode) {
      this.props.saveItem(selectedId, item);
    } else {
      this.props.createItem(item);
    }
  }

  /**
   * Set the new content.
   */
  onEditorChange = (content) => {
    this.setState({ content: content.text });
  }

  /**
   * If content is completely empty or it's <p><br></p>,
   * meaning that it was removed.
   */
  isContentFilled = (content) => {
    return !!(content.length && content !== EMPTY_CONTENT);
  }

  // Verify if content has changed
  hasContentChanged = () => {
    const { initialState, content, cover, pictures } = this.state;
    const { formValues } = this.props;

    const currentContent = this.getCurrentState(formValues, content, cover, pictures);

    return initialState !== currentContent;
  }

  onCancelChanges = () => {
    const { history } = this.props;
    // there were no changes on the form
    if (!this.hasContentChanged()) {
      history.replace(ITEMS);
    } else {
      this.setState({ showChangesModal: true })
    }
  };

  hideChangesModal = () => this.setState({ showChangesModal: false });

  onAcceptChanges = () => this.props.history.replace(ITEMS);
  render() {
    const {
      content,
      cover,
      editMode,
      indicators,
      pictures,
      serverErrors,
      showAlertModal,
      showChangesModal,
      sliderLength,
    } = this.state;
    const contentFilled = this.isContentFilled(content);
    const contentExceeded = content.length > MAX_CONTENT_LENGTH;
    const {
      displayOtherOptions,
      handleSubmit,
      loadingOneItem,
      savingItem,
      submitFailed,
      submitSucceeded
    } = this.props;

    return (
      <SaveItem
        content={content}
        contentFilled={contentFilled}
        contentExceeded={contentExceeded}
        cover={cover}
        displayAlertModal={() => this.setState({ showAlertModal: true })}
        displayOtherOptions={displayOtherOptions}
        editMode={editMode}
        handleSubmit={handleSubmit}
        hideAlertModal={this.hideAlertModal}
        hideChangesModal={this.hideChangesModal}
        indicators={indicators}
        loadingOneItem={loadingOneItem}
        maxContentLength={MAX_CONTENT_LENGTH}
        maxSliderLength={MAX_SLIDER_LENGTH}
        onAcceptChanges={this.onAcceptChanges}
        onCancelChanges={this.onCancelChanges}
        onDropCover={this.onDropCover}
        onDropImages={this.onDropImages}
        onEditorChange={this.onEditorChange}
        onSubmit={this.onSubmit}
        pictures={pictures}
        removeCover={this.removeCover}
        removeImage={this.removeImage}
        savingItem={savingItem}
        serverErrors={serverErrors}
        setHasIndicators={this.setHasIndicators}
        showAlertModal={showAlertModal}
        showChangesModal={showChangesModal}
        sliderLength={sliderLength}
        submitFailed={submitFailed}
        submitSucceeded={submitSucceeded}
      />
    )
  }
}

SaveItemContainer.propTypes = {
  createItem: PropTypes.func.isRequired,
  displayAlert: PropTypes.func.isRequired,
  displayOtherOptions: PropTypes.bool.isRequired,
  errorLoadingOneItem: PropTypes.any,
  errorSavingItem: PropTypes.any,
  findItem: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired,
  loadingOneItem: PropTypes.number.isRequired,
  match: PropTypes.object.isRequired,
  saveItem: PropTypes.func.isRequired,
  savingItem: PropTypes.number.isRequired,
  selectedItem: PropTypes.object.isRequired,
  setHasIndicatorsValue: PropTypes.func.isRequired,
  setSavingItem: PropTypes.func.isRequired,
  submitFailed: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
}

/**
 * Create a new form.
 */
const form = reduxForm({
  form: 'saveItem',
  initialValues: {
    hasIndicators: DROPDOWN_TRUE
  }
});

/**
 * Get data from the store for this component.
 */
const mapStateToProps = (state) => {
  const selector = formValueSelector('saveItem');
  return {
    displayOtherOptions: selector(state, 'hasIndicators') === DROPDOWN_FALSE,
    errorLoadingOneItem: state.itemReducer.errorLoadingOneItem,
    errorSavingItem: state.itemReducer.errorSavingItem,
    formValues: selector(state,
      'name',
      'shortName',
      'description',
      'hasIndicators',
      'title',
    ),
    loadingOneItem: state.itemReducer.loadingOneItem,
    savingItem: state.itemReducer.savingItem,
    selectedItem: state.itemReducer.selectedItem,
  }
}

/**
 * Actions to be dispatched to the store.
 */
const mapDispatchToProps = (dispatch) => {
  return {
    createItem: (item) => dispatch(ItemActions.createItem(item)),
    displayAlert: (alert) => dispatch(displayAlert(alert)),
    findItem: (id) => dispatch(ItemActions.findItem(id)),
    saveItem: (id, item) => dispatch(ItemActions.saveItem(id, item)),
    setHasIndicatorsValue: (value) => dispatch(change('saveItem', 'hasIndicators', value)),
    setSavingItem: (status) => dispatch(ItemActions.clearLoadingItem(status)),
  }
}

export default compose(
  form,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SaveItemContainer);
