export const GET_ITEMS_BEGIN = 'GET_ITEMS_BEGIN';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEMS_ERROR = 'GET_ITEMS_ERROR';
export const REORDER_ITEMS = 'REORDER_ITEMS';
export const SAVE_ITEM_BEGIN = 'SAVE_ITEM_BEGIN';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const SAVE_ITEM_ERROR = 'SAVE_ITEM_ERROR';
export const CLEAR_LOADING_ITEM = 'CLEAR_LOADING_ITEM';
export const FIND_ITEM_BEGIN = 'FIND_ITEM_BEGIN';
export const FIND_ITEM_SUCCESS = 'FIND_ITEM_SUCCESS';
export const FIND_ITEM_ERROR = 'FIND_ITEM_ERROR';
export const SAVE_ITEM_SUCCESS = 'SAVE_ITEM_SUCCESS';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
