import React from 'react';
import PropTypes from 'prop-types';

// styles
import styles from 'styles/scenes/admin.module.scss';

const ServerErrors = ({
  errors,
  cols,
}) => {

  return (
    <>
    {errors.length ? (
      <section className="__row">
        <section className={`__col_xs_${cols || 10} __row`}>
          <section className={styles.__form_errors}>
            <ul>
              {errors.map((err, index) => <li key={index}>{err}</li>)}
            </ul>
          </section>
        </section>
      </section>
    ) : null}
    </>
  );
}

ServerErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  cols: PropTypes.number,
}

export default ServerErrors;
