import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

// context
import { DatesContext } from 'providers/DatesProvider';
import { IndicatorContext } from 'providers/IndicatorProvider';
import { ItemContext } from 'providers/ItemProvider';

// components
import { Canvas } from "components/Canvas";
import { Loader } from "components/Loader";
import Mexico from "components/Mexico";
import NoIndicatorSummary from "./NoIndicatorSummary";
import Share from "components/Share";
import Summary from "./Summary";

// styles
import "styles/grid.scss";

const IndicatorInfo = ({
  getRanking,
  getStatesBreakdown,
  legendColors,
  legendValues,
  loadingData,
  missingData,
  missingStates,
  statesByHash,
  statesById,
  strForGeoJSON,
  totalCountry,
  totalState,
  totalESE
}) => {
  const {
    item,
    loading: loadingItems,
    loadingItem,
  } = useContext(ItemContext);
  const {
    indicator,
    loading: loadingIndicators,
  } = useContext(IndicatorContext);
  const { loading: loadingDates } = useContext(DatesContext);

  const [loading, setLoading] = useState(loadingData || loadingItems
    || loadingIndicators || loadingDates || loadingItem);

  useEffect(() => {
    setLoading(loadingData || loadingItems || loadingIndicators || loadingDates || loadingItem);
  }, [loadingData, loadingItems, loadingIndicators, loadingDates, loadingItem]);

  return (
    <Canvas isStatistics={item.hasIndicators === false}>
      {loading && <Loader />}
      <Share
        key={Math.random().toString(36)} // Make the component rerender
        text={indicator.shortName || item.title || ''}
        strForGeoJSON={strForGeoJSON} />
      {
        // check that the value is actually false
        // and not undefined
        item.hasIndicators === false ? (
          <NoIndicatorSummary />
        ) : (
          <>
            <Mexico
                indicator={indicator}
                legendColors={legendColors}
                legendValues={legendValues}
                missingData={missingData}
                statesByHash={statesByHash}
                strForGeoJSON={strForGeoJSON}
                totalCountry={totalCountry}
                totalState={totalState}
                totalESE={totalESE}
            />
            <Summary
                getRanking={getRanking}
                getStatesBreakdown={getStatesBreakdown}
                legendColors={legendColors}
                legendValues={legendValues}
                missingStates={missingStates}
                statesByHash={statesByHash}
                statesById={statesById}
                totalState={totalState}
                totalCountry={totalCountry}
                totalESE={totalESE}
            />
          </>
        )
      }
    </Canvas>
  );
};

IndicatorInfo.propTypes = {
  getRanking: PropTypes.func.isRequired,
  getStatesBreakdown: PropTypes.func.isRequired,
  legendValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  legendColors: PropTypes.arrayOf(PropTypes.string).isRequired,
  loadingData: PropTypes.bool.isRequired,
  missingData: PropTypes.any,
  missingStates: PropTypes.object,
  statesById: PropTypes.arrayOf(PropTypes.string).isRequired,
  statesByHash: PropTypes.object.isRequired,
  strForGeoJSON: PropTypes.string.isRequired,
  totalCountry: PropTypes.any,
  totalState: PropTypes.any,
  totalESE: PropTypes.any
};

export default IndicatorInfo;
