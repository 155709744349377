import * as Frequencies from 'constants/measurement-frequencies';

export const getFrequencyName = (propName) => {
  switch (propName) {
    case 'annual':
      return Frequencies.ANNUAL;
    case 'quarterly':
      return Frequencies.QUARTERLY;
    case 'monthly':
      return Frequencies.MONTHLY;
    default:
      return '';
  }
}
