import * as AuthActions from 'constants/user-constants'
import { D } from 'constants/dictionary'

const initialState = {
  loading: false,
  errorMessage: "",
  errorStatus: 0,
  loginError: false,
  loginSuccess: false,
  user: {},
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {

    case AuthActions.LOGIN_REQUEST:
      return {
        ...state,
        token: "",
        errorMessage: "",
        loading: true,
        loginError: false,
        loginSuccess: false,
      };
    case AuthActions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.data.data.user,
        loginSuccess: true,
      };
    case AuthActions.LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: action.error ? action.error.data.error.message : D.errors.noConnection,
        token: action.error ? action.error.data.error.token : '',
        errorStatus: action.error ? action.error.status : 503,
        loginError: true,
        loading: false
      };
    case AuthActions.SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload
      }
    //Recover Password Actions
    case AuthActions.RECOVER_PASS_REQUEST:
      return {
        ...state,
        errorMessage: "",
        errorStatus: 0,
        loading: true,
        mailError: false,
      };
    case AuthActions.RECOVER_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        mailSuccess: true
      };
    case AuthActions.RECOVER_PASS_FAILURE:
      return {
        ...state,
        errorMessage: action.error.data.data,
        errorStatus: action.error.status,
        mailError: true,
        loading: false
      };

    //Change Password Actions
    case AuthActions.CHANGE_PASS_REQUEST:
      return {
        ...state,
        errorMessage: "",
        errorStatus: 0,
        loading: true,
      };
    case AuthActions.CHANGE_PASS_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordSuccess: true
      };
    case AuthActions.CHANGE_PASS_FAILURE:
      return {
        ...state,
        errorMessage: action.error.data.error,
        errorStatus: action.error.status,
        passwordError: true,
        loading: false
      };

    case AuthActions.CLEAR_USER_STATE:
      return {
        ...state,
        loading: false,
        mailError: false,
        mailSuccess: false,
        passwordSuccess: false,
        errorStatus: 0
      }
    default:
      return state
  }
}
